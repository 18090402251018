import React, {useState, useRef} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Addimage(props) {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    } 
    const [categories, setItems] = useState([]);
    
   const [cat, setBanner] = useState({})

    const handlechange = e => {
        const {name, value} = e.target
        setBanner({
            ...cat,[name]:value
        })
        
    }

    const imghandlechange = e => {
        setBanner({
            ...cat,blogimage:e.target.files[0]
        })
    }
   
    const addbanner = () => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var ctname = cat.title.toLowerCase();
        var imageid = ctname.replace(/ /g, '-') + "-" + val;

        const formdata = new FormData();
        formdata.append('blogimage', cat.blogimage, cat.blogimage.name)
        formdata.append('imageid', imageid)
        formdata.append('title', cat.title)
        formdata.append('blogimageurl', cat.blogimageurl)
        formdata.append('status', cat.status)
        formdata.append('createdBy', email)
        
        if(cat.blogimage){
            //console.log(cat)
            API.post("/blogimage/postdata", formdata)
            .then(res => {
                alert(res.data.message);
                navigate("/blogimage")
            })
        }else{
            alert("Please upload banner")
        }
   }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Blog Image</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Title:</label>
                                <input className="form-control" type="text" name='title' id='title' value={cat.title} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner Image:</label>
                                <input className="form-control" type='file' id='blogimage' name='blogimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='status' id='status' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="btn btn-primary" onClick={ addbanner} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Addimage