import React, {useState, useEffect, useRef} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Editblogcategory(props) {

    const {blogcatid} = useParams()
    
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [cat, setCat] = useState({})
    
    const [date, setDate] = useState(new Date());

    useEffect( () =>{
        fetchBlogData()
    },[])

    const fetchBlogData = async () => {
        
      const res =  await API.get(`/blog/blogcategory/${blogcatid}`)
      setCat(res.data.category)
  }

    const handlechange = e => {
      const {name, value} = e.target
      setCat({
          ...cat,[name]:value
      })
    }

    const editCat = async() => {

      const {blogcatid,catname} = cat

      if(catname == "" || catname == undefined){
          alert("Please enter category name")
          return false
      }
      if(catname){
        var caturl = catname.replace(/\s+/g, '-').toLowerCase();
        cat.blogcatid = blogcatid;
        cat.updatedBy = email
        cat.caturl = caturl;
        API.post("/blog/editblogcategory", cat).then( result => {
          alert(result.data.message);
          navigate("/blogcategory")
        })
      }
       
    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Category</h4>
                        </div>
                        <div className='card-body'>
                            <form name='addblogcategory' id='addblogcategory' method='post'>
                                <div className="mb-3">
                                    <label className="form-label">Category Name:</label>
                                    <input className="form-control" type="text" name='catname' id='catname' value={cat.catname} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Title(Seo):</label>
                                    <input className="form-control" type="text" name='mtitle' id='mtitle' value={cat.mtitle} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Keywords(Seo):</label>
                                    <textarea className="form-control" cols={40} rows={2} name='mkeywords' value={cat.mkeywords} id='mkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Description(Seo):</label>
                                    <textarea className="form-control" cols={40} rows={10} name='mdescr' id='mdescr' onChange={ handlechange } value={cat.mdescr} ></textarea>
                                </div>
                                <div className="btn btn-primary" onClick={ editCat} >Submit</div>                         
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </React.Fragment>
  )
}

export default Editblogcategory