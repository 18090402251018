import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Editcontent(props) {
    const {contentid} = useParams()
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }

    const [content, setContent] = useState({})
    const editorRef = useRef(null);
   
    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        const res =  await API.get(`/content/content/${contentid}`)
        //console.log(res.data.caregories)
        setContent(res.data.content)
        
    }

    const handlechange = e => {
        const {name, value} = e.target
        setContent({
            ...content,[name]:value
        })
    }

    const editContent = async() => {

        if(content.pagename == "" || content.pagename == undefined){
            alert("Please select page")
            return false
        }

        if (editorRef.current) {
            content.description = editorRef.current.getContent();
        }

        if(content.pagename){
            
            content.contentid = contentid;
            content.updatedBy = user;
            console.log(content)
            
            API.post("/content/editcontent", content).then( result => {
                alert(result.data.message);
                navigate("/content")
            })
                
        }else{
            alert("Please select page")
        }
    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Page Content</h4>
                        </div>
                        <div className='card-body'>
                            <form name='addcontent' id='addcontent' method='post'>
                                <div className="mb-3">
                                    <label className="form-label">Page Name:</label>
                                    <select className="form-control" name="pagename" id="pagename" onChange={ handlechange } value={content.pagename}>
                                        <option value="">--Select Pagename--</option>
                                        <option value="home">Home Page</option>
                                        <option value="listing">Listing Page</option>
                                        <option value="details">Details Page</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Position:</label>
                                    <select className="form-control" name="position" id="position" onChange={ handlechange } value={content.position}>
                                        <option value="">--Select Position--</option>
                                        <option value="top">Top</option>
                                        <option value="bottom">Bottom</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Short Description:</label>
                                    <textarea className="form-control" cols={40} rows={2} name='short_description' id='short_description' onChange={ handlechange } value={content.short_description} ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Description:</label>
                                    <Editor
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={content.description}
                                        name="description"
                                        init={{
                                            height: 500,
                                            menubar: true,
                                            plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help hr',
                                            'searchreplace visualblocks wordcount code fullscreen',
                                            'insertdatetime media table paste wordcount template',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                            ],
                                            toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                        
                                    />
                                </div>
                                <div className="btn btn-primary" onClick={ editContent} >Submit</div>                         
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </React.Fragment>
  )
}

export default Editcontent