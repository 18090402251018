import React, { useState, useEffect } from "react";
import API from "../../../API";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AppConfig} from "../../../Utils/GamesConfig";
import {
  faPlus,
  faEdit,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faTrash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

function CategoriesNew(props) {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [categories, setCategories] = useState();

    console.log("categories", categories)
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await API.get("/api/v3/listcategory")
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const active = async (id) => {
    const res = await API.get(`/api/v3/active/${id}`);
    alert(res.data.message);
    window.location.reload();
  };
  const inactive = async (id) => {
    const res = await API.get(`/api/v3/inactive/${id}`);
    alert(res.data.message);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container-fluid mt-4">
        <div className="table-responsive">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Categories List</h4>
              <div className="flex-shrink-0">
                <a
                  href="/add-category"
                  className="btn btn-soft-secondary btn-sm"
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Category
                </a>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      <th width="10%">#</th>
                      <th width="10%">Category Name</th>
                      <th width="10%">Image</th>
                      <th width="10%">Description</th>
                      <th width="10%" className="text-right">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories &&
                      categories.data &&
                      categories.data.cates &&
                      categories.data.cates.map((data, index) => (
                        <tr key={index}>
                          <td width="10%">{index + 1}</td>
                          <td width="10%">{data.category_name}</td>
                          <td width="10%">{data.description}</td>
                          <td width="10%"><img src={`${AppConfig.GAME_IMAGE_NEW}/${data.image}`} alt={data.category_name} style={{width: 80, height: 80}} /></td>

                          <td width="10%" style={{ textAlign: "right" }}>
                            <Link
                              to={`/edit-newcategory/${data._id}`}
                              className="btn btn-soft-secondary btn-sm"
                              title="Edit"
                              style={{ marginRight: 10 }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                            {data.isActive === 1 || data.isActive === "1" ? (
                              <button
                                className="delete btn btn-soft-danger btn-sm"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want inactive this category?"
                                    )
                                  ) {
                                    inactive(data._id);
                                  }
                                }}
                                title="Inactive"
                                data-toggle="tooltip"
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                className="delete btn btn-soft-danger btn-sm"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want active this category?"
                                    )
                                  ) {
                                    //active(data._id, "active");
                                    active(data._id);
                                  }
                                }}
                                title="Active"
                                data-toggle="tooltip"
                              >
                                Inactive
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CategoriesNew;
