import React, { useState,useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API'
import {AppConfig} from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function Blog(props){

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    //const user = props.Users;  
    const [blogs, setBlog] = useState([])

    useEffect(() => {
        fetchBlogs()
    },[])

    const fetchBlogs = async () => {
        const res =  await API.get("/blog/bloglist").then((resp) => { 
            console.log(resp.data.blog)
            setBlog(resp.data.blog)
        }).catch((err) => { 
            console.log("Error Found") 
        });
        
    }

    const deleteblog = async (bid,status) => {
        
        const qry = {"bid": bid, "status":status} 
        const res = await API.post("/blog/deleteblog", qry).then((resp) => { 
            const msg = "Blog has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchBlogs()
        }).catch((err) => { 
            console.log("Error Found") 
        });
        
    }

    return(
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Blog List</h4>
                    <div className="flex-shrink-0">
                        <a href='/addblog' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add Blog</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <div className="table-wrapper">                            
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="15%">Category</th>
                                        <th width="10%">Blog Title</th>
                                        <th width="10%">Short Description</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {blogs.map((row,index) => (
                                        <tr>
                                            <td width="5%">{index+1}</td>
                                            <td width="10%">{row.category}</td>
                                            <td width="15%">{row.blogtitle}</td>
                                            <td width="15%" dangerouslySetInnerHTML={ { __html: row.short_description}}></td>
                                            <td width="10%" className='text-right'>
                                            <Link to={`/copy-blog/${row.blogid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon="fa-solid fa-copy" /></Link>
                                            <Link to={`/edit-blog/${row.blogid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                            {row.blogstatus === 'draft' ? (
                                                <>Draft</>
                                            ):(
                                                <>
                                                    {row.blogstatus === 'active' ? (
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deleteblog(row.blogid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                    ):(
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deleteblog(row.blogid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                    )}
                                                </>
                                            )}
                                            
                                                
                                            </td>
                                        </tr>
                                    ))}  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </React.Fragment>
    )
}

export default Blog