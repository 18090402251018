import React, {useState, useRef} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';

function Addreels(props) {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    } 
    const [categories, setItems] = useState([]);
    
   const [cat, setReels] = useState({})

    const handlechange = e => {
        const {name, value} = e.target
        setReels({
            ...cat,[name]:value
        })
        
    }

    const addreels = () => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var ctname = cat.title.toLowerCase();
        var reelsid = ctname.replace(/ /g, '-') + "-" + val;

        const qry = {'embed_code': cat.embed_code,'reelsid': reelsid,'title': cat.title,'status': cat.status,'createdBy': email}
        if(cat.embed_code){
            //console.log(cat)
            API.post("/reels/postdata", qry)
            .then(res => {
                alert(res.data.message);
                navigate("/reels")
            })
        }else{
            alert("Please enter embed code")
        }
   }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Reel</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Title:</label>
                                <input className="form-control" type="text" name='title' id='title' value={cat.title} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Embed Code:</label>
                                <input className="form-control" type='text' id='embed_code' name='embed_code' onChange={handlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='status' id='status' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="btn btn-primary" onClick={ addreels} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Addreels