//Config file for static links 

const AppConfig = {
    DOMAIN_NAME: 'https://nd-cms.aivdigital.com/',
    GAME_IMAGE_NEW: 'https://img.aivdigital.com',
    GAME_IMAGE_NAME: 'https://cs.aivdigital.com/uploads/games/images/',
    BANNER_IMAGE_NAME: 'https://cs.aivdigital.com/uploads/games/banners/',
    CATEGORY_IMAGE_NAME: 'https://cs.aivdigital.com/uploads/games/categoryimages/',
    BLOG_IMAGE: 'https://cs.aivdigital.com/games/uploads/blog/'
}

export {
    AppConfig
}
