import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import Loader from "../Helper/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment/moment";
import { ADMIN_REPORT_API_URL, BASIC_TOKEN } from "../../constant";
import VerifiedIcon from "../../verified.png";

const UserDetail = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  const userid = window.location.pathname.split("/").pop();

  const [show, setShow] = useState(false);
  const [showContest, setShowContest] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [gameId, setGameId] = useState();
  const [contestId, setContestId] = useState();

  console.log("contestId", contestId);

  if (user === "" || user === null) {
    window.location.href = "/login";
    ///navigate("/login")
  }

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handlechange = (e) => {
    if (e.target.name === "gameid") {
      setGameId(e.target.value);
    } else {
      setContestId(e.target.value);
    }
  };

  // //Fetch user profile
  const fetchUserProfile = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    await axios
      .get(`${ADMIN_REPORT_API_URL}/users/details/${userid}`, {
        headers: auth_code,
      })
      .then((response) => {
        //console.log("response", response)
        setUserDetails(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const depositBlock = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-deposit`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const depositActivate = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-deposit`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const withdrawBlock = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-withdrawal`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const withdrawActivate = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-withdrawal`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const kycBlock = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-kyc`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const kycActivate = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-kyc`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const allGameBlock = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-all-game`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const allGameActivate = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-all-game`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const blockSingleGame = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
      gameId: gameId,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-one-game`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    setShow(false);
  };

  const unblockSingleGame = async (gameid) => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
      gameId: gameid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-one-game`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const blockSingleContest = async () => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
      contestId: contestId,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/block-contest`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    setShowContest(false);
  };

  const unblockSingleContest = async (contestid) => {
    var auth_code = {
      Authorization: BASIC_TOKEN,
    };
    var data = {
      userId: userid,
      contestId: contestid,
    };
    await axios
      .post(`${ADMIN_REPORT_API_URL}/users/activate-contest`, data, {
        headers: {
          "Content-Type": "application/json",
          auth_code,
        },
      })
      .then((response) => {
        alert(response.data.message);
        if (typeof window !== undefined) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container-fluid pt-4 cFormLabel">
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                {userDetails && userDetails.code === 2000 ? (
                  <div className="d-flex flex-column align-items-center text-center">
                    {userDetails.data &&
                    userDetails.data.userData &&
                    userDetails.data.userData.profileData ? (
                      <>
                        {userDetails.data.userData.profileData
                          .profilePicture ? (
                          <div style={{ position: "relative" }}>
                            <img
                              src={
                                userDetails.data.fileBaseUrl +
                                userDetails.data.userData.profileData
                                  .profilePicture
                              }
                              alt="User"
                              className="rounded-circle p-1 bg-primary"
                              width="90"
                              height={90}
                            />
                          </div>
                        ) : (
                          <img
                            src="assets/images/avatars/avatar-2.png"
                            alt="User"
                            className="rounded-circle p-1 bg-primary"
                            width="90"
                            height={90}
                          />
                        )}
                        <div className="mt-3">
                          <h4 style={{ lineHeight: "18px" }}>
                            {userDetails.data.userData.profileData.name} <br />{" "}
                            {parseInt(
                              userDetails.data.userData.profileData.isActive
                            ) === 1 ? (
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "orange",
                                  fontWeight: 400,
                                }}
                              >
                                Active
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          {userDetails.data.userData.profileData.mobile ? (
                            <p className="text-secondary mb-1">
                              <b>Mobile:</b> <br />
                              {
                                userDetails.data.userData.profileData.mobile
                              }{" "}
                              {parseInt(
                                userDetails.data.userData.profileData
                                  .is_mobile_verified
                              ) === 1 ? (
                                <span>
                                  <img src={VerifiedIcon} alt="" width={18} />
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            ""
                          )}

                          <p className="text-secondary mb-1">
                            <b>User Id:</b> <br />{" "}
                            {userDetails.data.userData.profileData.userId
                              ? userDetails.data.userData.profileData.userId
                              : "-"}{" "}
                          </p>

                          <p className="text-secondary mb-1">
                            <b>Poker Id:</b> <br />{" "}
                            {userDetails.data.userData.profileData
                              .poket52PokerGuid
                              ? userDetails.data.userData.profileData
                                  .poket52PokerGuid
                              : "-"}{" "}
                          </p>
                          <p className="text-muted font-size-sm">
                            <b>Email:</b> <br />
                            {userDetails.data.userData.profileData.email
                              ? userDetails.data.userData.profileData.email
                              : "-"}
                          </p>
                          <p className="text-muted font-size-sm">
                            <b>DOB:</b> <br />
                            {userDetails.data.userData.profileData.dob
                              ? userDetails.data.userData.profileData.dob
                              : "-"}
                          </p>
                          <p className="text-muted font-size-sm">
                            <b>Gender:</b> <br />{" "}
                            {userDetails.data.userData.profileData.gender
                              ? userDetails.data.userData.profileData.gender
                              : "-"}
                          </p>
                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Registration Date:</b> <br />{" "}
                            {userDetails.data.userData.profileData
                              .registrationDate
                              ? userDetails.data.userData.profileData
                                  .registrationDate
                              : "-"}
                          </p>

                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>State Name:</b> <br />{" "}
                            {userDetails.data.userData.profileData.stateName
                              ? userDetails.data.userData.profileData.stateName
                              : "-"}
                          </p>

                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>City Name:</b> <br />{" "}
                            {userDetails.data.userData.profileData.cityName
                              ? userDetails.data.userData.profileData.cityName
                              : "-"}
                          </p>

                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Source:</b> <br />{" "}
                            {userDetails.data.userData.profileData.source
                              ? userDetails.data.userData.profileData.source
                              : "-"}
                          </p>

                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Medium:</b> <br />{" "}
                            {userDetails.data.userData.profileData.medium
                              ? userDetails.data.userData.profileData.medium
                              : "-"}
                          </p>

                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Campaign:</b> <br />{" "}
                            {userDetails.data.userData.profileData.campaign
                              ? userDetails.data.userData.profileData.campaign
                              : "-"}
                          </p>
                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Site Id:</b> <br />{" "}
                            {userDetails.data.userData.profileData.siteId
                              ? userDetails.data.userData.profileData.siteId
                              : "-"}
                          </p>
                          <p
                            className="text-muted font-size-sm"
                            style={{ marginBottom: 5 }}
                          >
                            <b>Click Id:</b> <br />{" "}
                            {userDetails.data.userData.profileData.clickId
                              ? userDetails.data.userData.profileData.clickId
                              : "-"}{" "}
                          </p>
                          {/* <button className="btn btn-primary">Follow</button>
                            <button className="btn btn-outline-primary">Message</button> */}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div>
                    <p>{userDetails && userDetails.message}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>Deposit Cash</h6>
                      {userDetails &&
                      userDetails.data &&
                      userDetails.data.userData ? (
                        <>
                          {userDetails.data.userData.depositBlocked ===
                          false ? (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want block this user deposit cash?"
                                  )
                                ) {
                                  depositBlock();
                                }
                              }}
                              className="btn btn-warning"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want Unblock this user deposit cash?"
                                  )
                                ) {
                                  depositActivate();
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>Withdraw Cash</h6>
                      {userDetails &&
                      userDetails.data &&
                      userDetails.data.userData ? (
                        <>
                          {userDetails.data.userData.withdrawalBlocked ===
                          false ? (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want block this user withdrawal cash?"
                                  )
                                ) {
                                  withdrawBlock();
                                }
                              }}
                              className="btn btn-warning"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want Unblock this user withdrawal cash?"
                                  )
                                ) {
                                  withdrawActivate();
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>KYC</h6>
                      {userDetails &&
                      userDetails.data &&
                      userDetails.data.userData ? (
                        <>
                          {userDetails.data.userData.kycBlocked === false ? (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want block this user KYC?"
                                  )
                                ) {
                                  kycBlock();
                                }
                              }}
                              className="btn btn-warning"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want Unblock this user KYC?"
                                  )
                                ) {
                                  kycActivate();
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h6>All Game</h6>
                      {userDetails &&
                      userDetails.data &&
                      userDetails.data.userData ? (
                        <>
                          {userDetails.data.userData.allGamesBlocked ===
                          false ? (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want block this user all games?"
                                  )
                                ) {
                                  allGameBlock();
                                }
                              }}
                              className="btn btn-warning"
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want Unblock this user all games?"
                                  )
                                ) {
                                  allGameActivate();
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <h6>List of Blocked Games</h6>
                    <button
                      onClick={() => setShow(true)}
                      className="btn btn-warning"
                    >
                      Block Game
                    </button>
                  </div>
                  {show ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ maxWidth: "80%" }}
                        className="form-control"
                        name="gameid"
                        id="gameid"
                        placeholder="Enter game id"
                        onChange={handlechange}
                      />
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want block this game?"
                            )
                          ) {
                            blockSingleGame();
                          }
                        }}
                        className="btn btn-warning"
                      >
                        Block
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {userDetails &&
                  userDetails.data &&
                  userDetails.data.userData ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 5,
                          marginTop: 10,
                        }}
                      >
                        <div className="gameid">
                          <b>Game Id:</b>
                        </div>
                        <div className="blockdt">
                          <b>Blocked Date:</b>
                        </div>
                        <div className="blockdt">
                          <b>Status:</b>
                        </div>
                      </div>
                      {userDetails.data.userData.blockedGameList.map(
                        (rowdata, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="gameid">{rowdata.gameId}</div>
                            <div className="blockdt">{rowdata.blockedDate}</div>
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want unblock this game?"
                                  )
                                ) {
                                  unblockSingleGame(rowdata.gameId);
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <h6>List of Blocked Contests</h6>
                    <button
                      onClick={() => setShowContest(true)}
                      className="btn btn-warning"
                    >
                      Block Contest
                    </button>
                  </div>
                  {showContest ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 5,
                        marginTop: 10,
                      }}
                    >
                      <input
                        style={{ maxWidth: "80%" }}
                        className="form-control"
                        name="contestid"
                        id="contestid"
                        placeholder="Enter contest id"
                        onChange={handlechange}
                      />
                      <button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want block this contest?"
                            )
                          ) {
                            blockSingleContest();
                          }
                        }}
                        className="btn btn-warning"
                      >
                        Block
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {userDetails &&
                  userDetails.data &&
                  userDetails.data.userData ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 5,
                          marginTop: 10,
                        }}
                      >
                        <div className="gameid">
                          <b>Contest Id:</b>
                        </div>
                        <div className="blockdt">
                          <b>Contest Date:</b>
                        </div>
                        <div className="blockdt">
                          <b>Status:</b>
                        </div>
                      </div>
                      {userDetails.data.userData.blockedContestList.map(
                        (rowdata, i) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="gameid">{rowdata.contestId}</div>
                            <div className="blockdt">{rowdata.blockedDate}</div>
                            <button
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want unblock this contest?"
                                  )
                                ) {
                                  unblockSingleContest(rowdata.contestId);
                                }
                              }}
                              className="btn btn-danger"
                            >
                              Unblock
                            </button>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* 
                  <div className="card">
                    <div className="card-body">
                      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h6>KYC</h6>
                        <button className='btn btn-warning'>Block</button>
                        <button className='btn btn-danger'>Blocked</button>
                      </div>
                    </div>
                  </div> */}
          </div>
          <div className="col-lg-8">
            <h4>User Game Play History</h4>
            {userDetails && userDetails.code === 2000 ? (
              <>
                {userDetails.data &&
                userDetails.data.userData &&
                userDetails.data.userData.freeGameData ? (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Free Game Data</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Initiated</div>
                                  <div className="property_">Acknowledge</div>
                                  <div className="property_">Completed</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_value">
                                    {userDetails.data.userData.freeGameData
                                      .initiatedCount
                                      ? userDetails.data.userData.freeGameData
                                          .initiatedCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.freeGameData
                                      .acknowledgeCount
                                      ? userDetails.data.userData.freeGameData
                                          .acknowledgeCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.freeGameData
                                      .completedCount
                                      ? userDetails.data.userData.freeGameData
                                          .completedCount
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Earn Game Data</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Initiated</div>
                                  <div className="property_">Acknowledge</div>
                                  <div className="property_">Completed</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_value">
                                    {userDetails.data.userData.earnGameData
                                      .initiatedCount
                                      ? userDetails.data.userData.earnGameData
                                          .initiatedCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.earnGameData
                                      .acknowledgeCount
                                      ? userDetails.data.userData.earnGameData
                                          .acknowledgeCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.earnGameData
                                      .completedCount
                                      ? userDetails.data.userData.earnGameData
                                          .completedCount
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Paid Game Data</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Initiated</div>
                                  <div className="property_">Acknowledge</div>
                                  <div className="property_">Completed</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_value">
                                    {userDetails.data.userData.paidGameData
                                      .initiatedCount
                                      ? userDetails.data.userData.paidGameData
                                          .initiatedCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.paidGameData
                                      .acknowledgeCount
                                      ? userDetails.data.userData.paidGameData
                                          .acknowledgeCount
                                      : "-"}
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.paidGameData
                                      .completedCount
                                      ? userDetails.data.userData.paidGameData
                                          .completedCount
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {userDetails && userDetails.code === 2000 ? (
              <>
                {userDetails.data &&
                userDetails.data.userData &&
                userDetails.data.userData.tournamentData &&
                userDetails.data.userData.fantasyData ? (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Tournment Data</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Unique Tournament Joining Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .uniqueTournamentJoiningCount
                                      ? userDetails.data.userData.tournamentData
                                          .uniqueTournamentJoiningCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Total Tournament Joining Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .totalTournamentJoiningCount
                                      ? userDetails.data.userData.tournamentData
                                          .totalTournamentJoiningCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Game Play Initiated Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentGamePlayInitiatedCount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentGamePlayInitiatedCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Game Acknowledge Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentGameAcknowledgeCount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentGameAcknowledgeCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Game Completed Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentGameCompletedCount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentGameCompletedCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Won Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentWonCount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentWonCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Won Amount
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentWonAmount
                                      ? parseFloat(
                                          userDetails.data.userData
                                            .tournamentData.tournamentWonAmount
                                        ).toFixed(2)
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Refund Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentRefundCount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentRefundCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Tournament Refund Amount
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.tournamentData
                                      .tournamentRefundAmount
                                      ? userDetails.data.userData.tournamentData
                                          .tournamentRefundAmount
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Fantasy Data</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Visit Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyVisitCount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyVisitCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Joining Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyJoiningCount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyJoiningCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Winning Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyWinningCount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyWinningCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Winning Amount
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyWinningAmount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyWinningAmount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Refund Count
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyRefundCount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyRefundCount
                                      : "-"}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Fantasy Refund Amount
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.fantasyData
                                      .fantasyRefundAmount
                                      ? userDetails.data.userData.fantasyData
                                          .fantasyRefundAmount
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="property_">
                                Earn And Paid Won Count
                              </div>
                              <div className="property_value">
                                {userDetails.data.userData.earnAndPaidWonCount
                                  ? userDetails.data.userData
                                      .earnAndPaidWonCount
                                  : "-"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="property_">
                                Earn And Paid Won Amount
                              </div>
                              <div className="property_value">
                                {userDetails.data.userData.earnAndPaidWonAmount
                                  ? userDetails.data.userData
                                      .earnAndPaidWonAmount
                                  : "-"}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="property_">
                                Poker Initiated Count
                              </div>
                              <div className="property_value">
                                {userDetails.data.userData.pokerInitiatedCount
                                  ? userDetails.data.userData
                                      .pokerInitiatedCount
                                  : "-"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            <h4>Bank Details</h4>
            {userDetails && userDetails.code === 2000 ? (
              <>
                {userDetails.data &&
                userDetails.data.userData &&
                userDetails.data.userData.upiData ? (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>UPI Details</h6>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">
                                    Beneficiary Id
                                  </div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.benId
                                      ? userDetails.data.userData.upiData.benId
                                      : "-"}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Name</div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.name
                                      ? userDetails.data.userData.upiData.name
                                      : "-"}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Email</div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.email
                                      ? userDetails.data.userData.upiData.email
                                      : "-"}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">Phone Number</div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.phone
                                      ? userDetails.data.userData.upiData.phone
                                      : "-"}
                                  </div>
                                </div>
                                <div>
                                  <div className="property_">Address</div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.address
                                      ? userDetails.data.userData.upiData
                                          .address
                                      : "-"}{" "}
                                    <br />
                                    {userDetails.data.userData.upiData.city
                                      ? userDetails.data.userData.upiData.city
                                      : ""}{" "}
                                    <br />
                                    {userDetails.data.userData.upiData.pincode
                                      ? userDetails.data.userData.upiData
                                          .pincode
                                      : ""}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="property_">VPA</div>
                                  <div className="property_value">
                                    {userDetails.data.userData.upiData.vpa
                                      ? userDetails.data.userData.upiData.vpa
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <h6>Bank Details</h6>
                              {userDetails.data.userData.bankData ? (
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">
                                      Beneficiary Id
                                    </div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData.benId
                                        ? userDetails.data.userData.bankData
                                            .benId
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">Bank Name</div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData
                                        .bankName
                                        ? userDetails.data.userData.bankData
                                            .bankName
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">
                                      Account Number
                                    </div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData
                                        .accountNumber
                                        ? userDetails.data.userData.bankData
                                            .accountNumber
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">IFSC</div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData
                                        .ifscCode
                                        ? userDetails.data.userData.bankData
                                            .ifscCode
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">Name</div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData.name
                                        ? userDetails.data.userData.bankData
                                            .name
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">Email</div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData.email
                                        ? userDetails.data.userData.bankData
                                            .email
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="property_">
                                      Phone Number
                                    </div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData.phone
                                        ? userDetails.data.userData.bankData
                                            .phone
                                        : "-"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="property_">Address</div>
                                    <div className="property_value">
                                      {userDetails.data.userData.bankData
                                        .address
                                        ? userDetails.data.userData.bankData
                                            .address
                                        : "-"}{" "}
                                      <br />
                                      {userDetails.data.userData.bankData.city
                                        ? userDetails.data.userData.bankData
                                            .city
                                        : ""}{" "}
                                      <br />
                                      {userDetails.data.userData.bankData
                                        .pincode
                                        ? userDetails.data.userData.bankData
                                            .pincode
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h4>KYC Details</h4>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div>
                                  <h6>Address</h6>
                                  {userDetails.data.userData.kycData ? (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasSelfie
                                          ) === 1 ? (
                                            <img
                                              src={
                                                userDetails.data.fileBaseUrl +
                                                userDetails.data.userData
                                                  .kycData.selfie
                                              }
                                              alt="user profile"
                                              style={{
                                                width: 60,
                                                height: 60,
                                                borderRadius: "50%",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Selfie Match Percent
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasSelfie
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .selfieMatchPercentWithAddress
                                            ? parseFloat(
                                                userDetails.data.userData
                                                  .kycData
                                                  .selfieMatchPercentWithAddress
                                              ).toFixed(2)
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Selfie Verified With Address
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasSelfie
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .selfieVerifiedWithAddress
                                            ? userDetails.data.userData.kycData
                                                .selfieVerifiedWithAddress
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 ? (
                                            <img
                                              src={
                                                userDetails.data.fileBaseUrl +
                                                userDetails.data.userData
                                                  .kycData.addressDocFrontImage
                                              }
                                              alt="user profile"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 ? (
                                            <img
                                              src={
                                                userDetails.data.fileBaseUrl +
                                                userDetails.data.userData
                                                  .kycData.addressDocBackImage
                                              }
                                              alt="user profile"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">Name</div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocName
                                            ? userDetails.data.userData.kycData
                                                .addressDocName
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">DOB</div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocDateOfBirth
                                            ? userDetails.data.userData.kycData
                                                .addressDocDateOfBirth
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Parent Name
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocParentName
                                            ? userDetails.data.userData.kycData
                                                .addressDocParentName
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Doc. Number
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocumentNumber
                                            ? userDetails.data.userData.kycData
                                                .addressDocumentNumber
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Doc. Type
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocumentType
                                            ? userDetails.data.userData.kycData
                                                .addressDocumentType
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Address{" "}
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocumentAddress
                                            ? userDetails.data.userData.kycData
                                                .addressDocumentAddress
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Doc. Issued on{" "}
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocIssuedOn
                                            ? userDetails.data.userData.kycData
                                                .addressDocIssuedOn
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">Status</div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressDocGovtCheckStatus
                                            ? userDetails.data.userData.kycData
                                                .addressDocGovtCheckStatus
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          KYC Attempt
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasAddressProof
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .addressCheckCount
                                            ? userDetails.data.userData.kycData
                                                .addressCheckCount
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div>
                                  <h6>Pan</h6>
                                  {userDetails.data.userData.kycData ? (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 ? (
                                            <img
                                              src={
                                                userDetails.data.fileBaseUrl +
                                                userDetails.data.userData
                                                  .kycData.panImage
                                              }
                                              alt="user profile"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Pan Number
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panNumber
                                            ? userDetails.data.userData.kycData
                                                .panNumber
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Name on Pan
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panName
                                            ? userDetails.data.userData.kycData
                                                .panName
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">DOB</div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panDateOfBirth
                                            ? userDetails.data.userData.kycData
                                                .panDateOfBirth
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Parent Name
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panParentName
                                            ? userDetails.data.userData.kycData
                                                .panParentName
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Pan Issued on
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panIssuedOn
                                            ? userDetails.data.userData.kycData
                                                .panIssuedOn
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Pan Type
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panDocumentType
                                            ? userDetails.data.userData.kycData
                                                .panDocumentType
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">
                                          Pan Verified
                                        </div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panVerifiedWithAddress
                                            ? userDetails.data.userData.kycData
                                                .panVerifiedWithAddress
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_">Status</div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          userDetails.data.userData.kycData
                                            .panGovtCheckStatus
                                            ? userDetails.data.userData.kycData
                                                .panGovtCheckStatus
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="property_"></div>
                                        <div className="property_value">
                                          {parseInt(
                                            userDetails.data.userData.kycData
                                              .hasPan
                                          ) === 1 &&
                                          parseInt(
                                            userDetails.data.userData.kycData
                                              .isActive
                                          ) === 1
                                            ? "Active"
                                            : parseInt(
                                                userDetails.data.userData
                                                  .kycData.hasPan
                                              ) === 1 &&
                                              parseInt(
                                                userDetails.data.userData
                                                  .kycData.isActive
                                              ) === 0
                                            ? "Inactive"
                                            : parseInt(
                                                userDetails.data.userData
                                                  .kycData.hasPan
                                              ) === 1 &&
                                              parseInt(
                                                userDetails.data.userData
                                                  .kycData.isActive
                                              ) === 2
                                            ? "KYC Rejected"
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserDetail;
