import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import API from "../../../API";

const AddTag = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [tag, setTag] = useState({
    tag_name: "",
    layout: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTag((prevState) => ({ ...tag, [name]: value }));
  };
  console.log(tag);

  // const isUrlValid = (userInput) => {
  //   var res = userInput.match(
  //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  //   );
  //   if (res == null) return false;
  //   else return true;
  // };

  const addTags = () => {
    if (
      (tag && tag.tag_name !== "") ||
      tag.tag_name !== null ||
      tag.tag_name !== undefined
    ) {
      API.post("/api/v3/addtag", tag)
        .then((response) => {
          alert(response.data.message);
          setTimeout(() => {
            navigate("/manage-tags");
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    if (tag.tag_name === "" || tag.tag_name === undefined) {
      alert("Please enter tag name.");
      return false;
    }
    if (tag.layout === "" || tag.layout === undefined) {
      alert("Please enter layout type.");
      return false;
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Add New Tag</h4>
              </div>
              <div className="card-body">
                <form name="addcontest" id="addcontest" method="post">
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Tag Name:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="tag_name"
                        id="tag_name"
                        placeholder="Tag Name"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Layout:</label>
                      <select
                        className="form-control"
                        name="layout"
                        id="layout"
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   handleMultiPlayer(e)
                        //   handlechange(e);
                        // //  handleflag(e);
                        // }}
                      >
                        <option value="">Select</option>
                        <option value="square_scrollable">
                          Square Scrollable
                        </option>
                        <option value="verticle_scrollable">
                          Verticle Scrollable
                        </option>
                        <option value="fixed">Fixed</option>
                        <option value="circle_fixed">Circle Fixed</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        id="meta_title"
                        placeholder="Meta Title"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Description:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_description"
                        id="meta_description"
                        placeholder="Meta Description"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Keywords:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="keywords"
                        id="keywords"
                        placeholder="Meta Keywords"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={addTags}>
                        Submit
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddTag;
