import React,{useEffect, useState} from 'react'
import Navbar from '../Navbar';
import API from '../../API'
import { Link } from "react-router-dom" 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Reviews(props) {

    let navigate = useNavigate()
    const user = props.Users;
    console.log(props)
    const [result, setContent] = useState([]);

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        const res =  await API.get("/review/reviewlist")
        //console.log(res.data.caregories)
        setContent(res.data.reviews)
    }

    const deletereview= async (reviewid) => {
        const res = await API.get(`/review/deletereview/${reviewid}`)
        alert(res.data.message);
        fetchData()
    }
    
    const activereview = async(reviewid) => {
        const res = await API.get(`/review/activatereview/${reviewid}`)
        alert(res.data.message);
        fetchData()
    }
    const inactivereview = async(reviewid) => {
        const res = await API.get(`/review/inactivatereview/${reviewid}`)
        alert(res.data.message);
        fetchData()
    }    

  return (
    <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
                <div className="table-responsive">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Content Details</h4>
                            <div className="flex-shrink-0">
                                <a href='/addreview' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                            </div>
                        </div>
                        <div className='card-body p-0'>
                            <div className="table-responsive">
                                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                    <thead className='text-muted table-light'>
                                        <tr>
                                            <th width="10%">#</th>
                                            <th width="10%">Name</th>
                                            <th width="10%">Game</th>
                                            <th width="15%">Message</th>
                                            <th>Status</th>
                                            <th width="10%" className='text-right'>Action</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {result.map((row,index) => (
                                            <tr>
                                                <td width="10%">{index+1}</td>
                                                <td width="10%">{row.name}</td>
                                                <td width="10%">{row.game}</td>
                                                <td width="15%">{row.message}</td>
                                                <td>
                                                    {row.flag == '1' ? "Active" : "InActive"}
                                                </td>
                                                <td width="10%" className='text-right'>

                                                    {row.flag === '1' ? (
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this review?')){inactivereview(row.reviewid,'inactive')};}} title="Inactive" data-toggle="tooltip">Active</a>
                                                    ):(
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this review?')){activereview(row.reviewid,'active')};}} title="Active" data-toggle="tooltip">Inactive</a>  
                                                    )}

                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Delete the item?')){deletereview(row.reviewid)};}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>
                                                    
                                                    {/* <a className="delete btn btn-soft-danger btn-sm" onClick={() => activereview(row.reviewid)} title="Delete" data-toggle="tooltip">Activate</a>

                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Delete the item?')){deletereview(row.reviewid)};}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a> */}
                                                </td>
                                            </tr>
                                            
                                        ))}
                                        <tr><td>&nbsp;</td></tr>    
                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
  )
}

export default Reviews