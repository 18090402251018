import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import API from "../../../API";

const AddCategory = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  // For User who has update or create the category
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [category, setCategory] = useState({updated_by: username});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevState) => ({ ...category, [name]: value }));
  };
  const handleImageChange = (e) => {
    setCategory({ ...category, category_image: e.target.files[0] });
  };

  const funcAdd = () => {
    if (category.category_name === "" || category.category_name === undefined) {
      alert("Please enter category name.");
      return false;
    }
    if (category.category_image === "" || category.category_image === undefined) {
      alert("Please select category image.");
      return false;
    }    
    const formdata = new FormData();
      
    if(category.category_image){
        formdata.append('category_image', category.category_image, category.category_image.name)
    }
    formdata.append('category_name', category.category_name)
    formdata.append('description', category.description ? category.description : "")
    formdata.append('meta_title', category.meta_title ? category.meta_title : "")
    formdata.append('meta_description', category.meta_description ? category.meta_description : "")
    formdata.append('keywords', category.keywords ? category.keywords : "")
    formdata.append('updated_by', username ? username : email)
  
    if ((category.category_name !== "") || category.category_name !== null || category.category_name !== undefined) {
        API.post("/api/v3/addcategory", formdata)
        .then((response) => {
          alert(response.data.message);
          setTimeout(() => {
            navigate("/manage-categories");
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Add New Category
                </h4>
              </div>
              <div className="card-body">
                {/* <form name="addcontest" id="addcontest" method="post" enctype="multipart/form-data"> */}
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Category Name:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="category_name"
                        id="category_name"
                        placeholder="Category Name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Image:</label>
                      <input
                        className="form-control"
                        type="file"
                        name="category_image"
                        id="category_image"
                        placeholder="Category Image"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Description:</label>
                      <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        placeholder="Description"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        id="meta_title"
                        placeholder="Meta Title"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Description:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_description"
                        id="meta_description"
                        placeholder="Meta Description"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Keywords:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="keywords"
                        id="keywords"
                        placeholder="Meta Keywords"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={funcAdd}>
                        Submit
                      </div>
                    </div>
                  </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCategory;
