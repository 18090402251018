import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";
import Navbar from '../Navbar';
import axios from "axios";
import API from "../../API";

const AddFaq = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  
  if(user === "" || user === null){
      navigate("/login")
  }

  const [faqs, setFaqs] = useState({
      faq_title: "",
      description: ""
  })

  const descriptionRef = useRef(null)

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFaqs((prevState) => ({...faqs, [name]:value}))
    }

    const addFaqFunc = () => {
        if(descriptionRef.current){
            faqs.description = descriptionRef.current.getContent();
        }
        if(faqs.faq_title !== "" || faqs.faq_title !== undefined || faqs.faq_title !== "" || faqs.description !== undefined || faqs.description !=="" || faqs.description !== undefined){
          API.post('/api/v3/addfaq', faqs)
          .then(response => {            
            alert(response.data.message);
            setTimeout(() => {
              navigate("/manage-faqs");
            }, 1000)            
          } )
          .catch(error => {              
              console.error('There was an error!', error);
          });  
        }
        if (faqs.faq_title === "" || faqs.faq_title === undefined) {
            alert("Please enter faq title");
            return false;
        }
        if (faqs.description === "" || faqs.description === undefined) {
            alert("Please enter faq description");
            return false;
        }
    };

    return(
        <React.Fragment>
        <Navbar user={user} />
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Add New Faq</h4>
                </div>
                <div className="card-body">
                  <form name="addFaq" id="addFaq" method="post">
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Title:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="faq_title"
                          id="faq_title"
                          placeholder="Question"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 col-md-12">
                            <label className="form-label">Description:</label>
                            <Editor
                                onInit={(evt, editor) => descriptionRef.current = editor}
                                initialValue={faqs.description}
                                name="description"
                                init={{
                                    height: 350,
                                    plugins:
                                    "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                    toolbar:
                                    "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                    hieght: 350,
                                    toolbar_sticky: true,
                                    icons: "thin",
                                    skin: "material-classic",
                                    //forced_root_block : false
                                }} 
                                onChange={ handleChange }
                            />
                        </div>                                              
                      <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={addFaqFunc}>Submit</div>
                    </div>                                           
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
}

export default AddFaq;