import React,{useState,useEffect} from 'react'
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";

function Winners(props) {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }

    const [winnerList, setWinnerList] = useState();

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        await API.get("/api/getwinner")
        .then(response => {
            setWinnerList(response.data.winners)
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container-fluid mt-4'>
            <div className="table-responsive">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Winners List</h4>
                        <div className="flex-shrink-0">
                            <a href='/addwinner' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New Winner</a>
                        </div>                    
                    </div>
                    <div className='card-body p-0'>
                        <div className="table-responsive">
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="10%">#</th>
                                        <th width="10%">Name</th>
                                        <th width="10%">Profile Image</th>
                                        <th width="10%">Amount</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>      
                                <tbody>
                                {winnerList && winnerList.map((winner,index) => (
                                        <tr key={index}>
                                            <td width="10%">{index+1}</td>
                                            <td width="10%">{winner.winner_name}</td>
                                            <td width="10%"><img src={winner.winner_image} alt={winner.winner_name} style={{width: 45, height: 45}} /></td>
                                            <td width="10%">{winner.winning_amount}</td>
                                        </tr>
                                ))}
                                        <tr><td>&nbsp;</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Winners