import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import API from "../../../API";
import { useParams } from "react-router-dom";
import {AppConfig} from "../../../Utils/GamesConfig"
const EditNewCategory = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [category, setCategory] = useState();
  const [categoryImage, setCategoryImage] = useState();


  useEffect(() => {
    fetchCategoryById();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({...category,[name]: value,});
  };
  const handleImageChange = (e) => {
    setCategoryImage({ ...categoryImage, category_image: e.target.files[0] });
  };

  const { id } = useParams();
  console.log("id", id)

  const fetchCategoryById = async () => {
    await API.get("/api/v3/single-category/" + id)
      .then((response) => {
        console.log("response", response)
        setCategory(response.data.resData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const funcEdit = () => {
    if (category.category_name === "" || category.category_name === undefined) {
      alert("Please enter category name");
      return false;
    }    
    const formdata = new FormData();
      
    if(categoryImage && categoryImage.category_image !== undefined) {
        formdata.append('category_image', categoryImage.category_image, categoryImage.category_image.name)
    } else {
      formdata.append('category_image', category.image)
    }


    formdata.append('_id', category._id)
    formdata.append('category_name', category.category_name)
    formdata.append('description', category.description ? category.description : "")
    formdata.append('meta_title', category.meta_title ? category.meta_title : "")
    formdata.append('meta_description', category.meta_description ? category.meta_description : "")
    formdata.append('keywords', category.keywords ? category.keywords : "")
    formdata.append('updated_by', username ? username : email)    



    if ((category.category_name !== "") || category.category_name !== null || category.category_name !== undefined) {
      API.post("/api/v3/editcategory", formdata)
        .then((response) => {
          console.log(response)
          alert(response.data.message);
          setTimeout(() => {
            navigate("/manage-categories");
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Edit Category</h4>
              </div>
              <div className="card-body">
                {/* <form name="addcontest" id="addcontest" method="post"> */}
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Category Name:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="category_name"
                        id="category_name"
                        placeholder="Category Name"
                        value={category && category.category_name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Image:</label>
                      <img src={`${AppConfig.CATEGORY_IMAGE_NAME}${category && category.image}`} alt={category && category.category_name} style={{width: 36, height: 36, marginBottom: 10}} />
                      <input
                        className="form-control"
                        type="file"
                        name="category_image"
                        id="category_image"
                        placeholder="Category Image"
                        onChange={handleImageChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Description:</label>
                      <textarea
                        className="form-control"
                        name="description"
                        id="description"
                        placeholder="Description"
                        onChange={handleChange}
                        value={category && category.description}
                      ></textarea>
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        id="meta_title"
                        placeholder="Meta Title"
                        onChange={handleChange}
                        value={category && category.meta_title}
                      />
                    </div>                    
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Description:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_description"
                        id="meta_description"
                        placeholder="Meta Description"
                        onChange={handleChange}
                        value={category && category.meta_description}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Keywords:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="keywords"
                        id="keywords"
                        placeholder="Meta Keywords"
                        onChange={handleChange}
                        value={category && category.keywords}
                      />
                    </div>

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={funcEdit}>
                        Submit
                      </div>
                    </div>
                  </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditNewCategory;
