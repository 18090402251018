import React,{useEffect, useState} from 'react'
import Navbar from '../Navbar';
import API from '../../API'
import { Link } from "react-router-dom" 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Content(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [result, setContent] = useState([]);

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        const res =  await API.get("/content/contentlist")
        //console.log(res.data.caregories)
        setContent(res.data.content)
    }

    const deletecontent= async (contentid,status) => {

        const qry = {"contentid": contentid, "status":status} 
        const res = await API.post("/content/deletecontent", qry).then((resp) => { 
            const msg = "Content has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
        
    }

    return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
                <div className="table-responsive">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Content Details</h4>
                            <div className="flex-shrink-0">
                                <a href='/addcontent' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                            </div>
                        </div>
                        <div className='card-body p-0'>
                            <div className="table-responsive">
                                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                    <thead className='text-muted table-light'>
                                        <tr>
                                            <th width="10%">#</th>
                                            <th width="10%">Page Name</th>
                                            <th width="10%">Position</th>
                                            <th width="10%">Short Description</th>
                                            <th width="15%">Description</th>
                                            <th width="10%" className='text-right'>Action</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {result.map((row,index) => (
                                            <tr>
                                                <td width="10%">{index+1}</td>
                                                <td width="10%">{row.pagename}</td>
                                                <td width="10%">{row.position}</td>
                                                <td width="10%">{row.short_description}</td>
                                                <td width="15%" dangerouslySetInnerHTML={ { __html: row.description}}></td>
                                                <td width="10%" className='text-right'>
                                                    <Link to={`/edit-content/${row.contentid}`} className="edit btn btn-soft-secondary btn-sm" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                                    {row.contentstatus === 'active' ? (
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deletecontent(row.contentid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                    ):(
                                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deletecontent(row.contentid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                    )}
                                                   
                                                </td>
                                            </tr>
                                            
                                        ))}
                                        <tr><td>&nbsp;</td></tr>    
                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Content