import React, { useState, useEffect } from "react";
import API from "../../../API";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faTrash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

function Tags(props) {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [tags, setTags] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await API.get("/api/v3/alltags")
      .then((response) => {
        setTags(response);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const activeTag = async (tagid) => {
    const res = await API.get(`/api/v3/activetag/${tagid}`);
    alert(res.data.message);
    window.location.reload();
  };
  const inactiveTag = async (tagid) => {
    const res = await API.get(`/api/v3/inactivetag/${tagid}`);
    alert(res.data.message);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container-fluid mt-4">
        <div className="table-responsive">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Tags List</h4>
              <div className="flex-shrink-0">
                <a href="/addtag" className="btn btn-soft-secondary btn-sm">
                  <FontAwesomeIcon icon={faPlus} /> Add New Tag
                </a>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      <th width="10%">#</th>
                      <th width="10%">Tag Name</th>
                      <th width="10%">Layout Type</th>
                      <th width="10%">Sequence</th>
                      <th width="10%">Re-arrange</th>
                      <th width="10%" className="text-right">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags &&
                      tags.data &&
                      tags.data.tags &&
                      tags.data.tags.map((data, index) => (
                        <tr key={index}>
                          <td width="10%">{index + 1}</td>
                          <td width="10%">{data.tag_name}</td>
                          <td width="10%">{data.layout}</td>
                          <td width="10%">{data.sequence}</td>
                          <td width="10%">
                            <button
                              className="btn btn-soft-secondary btn-sm"
                              title="UP"
                              style={{ marginRight: 10 }}
                            >
                              <FontAwesomeIcon icon={faLongArrowAltUp} />
                            </button>
                            <button
                              className="btn btn-soft-secondary btn-sm"
                              title="DOWN"
                            >
                              <FontAwesomeIcon icon={faLongArrowAltDown} />
                            </button>
                          </td>
                          <td width="10%" style={{ textAlign: "right" }}>
                            <Link to={`/games-tag/${data._id}`} className="edit btn btn-soft-secondary btn-sm" title="Games" data-toggle="tooltip" style={{marginRight:"5px"}}>Games</Link>                            
                            <Link
                              to={`/edittag/${data._id}`}
                              className="btn btn-soft-secondary btn-sm"
                              title="Edit"
                              style={{ marginRight: 10 }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                            {data.isActive === 1 || data.isActive === "1" ? (
                              <button
                                className="delete btn btn-soft-danger btn-sm"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want inactive this Tag?"
                                    )
                                  ) {
                                    inactiveTag(data._id, "inactive");
                                  }
                                }}
                                title="Inactive"
                                data-toggle="tooltip"
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                className="delete btn btn-soft-danger btn-sm"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want active this Tag?"
                                    )
                                  ) {
                                    activeTag(data._id, "active");
                                  }
                                }}
                                title="Active"
                                data-toggle="tooltip"
                              >
                                Inactive
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Tags;
