import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Navbar(props) {
  const user = props;
  let navigate = useNavigate();

  const deletesession = async () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  // console.log(user)
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Dashboard <span className="sr-only">(current)</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/categories">
              Categories
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="/vendors">
              Vendors
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/games">
              Games
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/banners">
              Banners
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Game Manager
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div>
                <a className="dropdown-item" href="/manage-tags">
                  Tags
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/manage-categories">
                  Categories
                </a>
              </div>
              {/* <div>
                <a className="dropdown-item" href="/games">
                  Games
                </a>
              </div> */}
              <div>
                <a className="dropdown-item" href="/vendors">
                  Vendors
                </a>
              </div>
              {/* <div>
                <a className="dropdown-item" href="/contest">
                  Contests
                </a>
              </div> */}
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Settings
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div>
                <a className="dropdown-item" href="/manage-faqs">
                  Faq's
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/manage-pages">
                  Pages
                </a>
              </div>
            </div>
          </li>          
          {/*<li className="nav-item">
                        <a className="nav-link" href="/ticket">Tickets</a>
                    </li>*/}
          {/* <li className="nav-item">
            <a className="nav-link" href="/contest">
              Contest
            </a>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Page Management
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div>
                <a className="dropdown-item" href="/managecategories">
                  Home Page
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/managwlistcategories">
                  Listing Page
                </a>
              </div>
            </div>
          </li> */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Blog
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div>
                <a className="dropdown-item" href="/blogcategory">
                  Blog Categories
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/blogs">
                  Blogs
                </a>
              </div>
            </div>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/content">
              Page Content
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" href="/reviews">
              Review's
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/helpdesk">
              Helpdesk's
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Reports
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div>
                <a className="dropdown-item" href="/users">
                  Users
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/registration-postback">
                  Registration Post Back
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/game-play">
                  Game Play
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/transactions">
                  Transactions
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="/transactions-postback">
                  Transactions Post Back
                </a>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="/blogimage">
              Blog Image
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/reels">
              Reels
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="/winners">
              Winners
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="/json">
              JSON Scheduler
            </a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link" onClick={deletesession}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
