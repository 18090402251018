import React,{useState, useEffect, useRef} from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { AppConfig } from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Blogimage(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    
    const [result, setBanners] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/blogimage/blogimagelist")
        //console.log(res.data.caregories)
        setBanners(res.data.banners)
    }

    const deletebanner = async (bid) => {

        const res = await API.get(`/blogimage/deleteblogimage/${bid}`)
        alert(res.data.message)
        fetchData()
    }

    const copyurl = async(url) => {
        console.log(url)
        var copyText = url //document.getElementById("myInput");
        console.log(copyText)
        // Select the text field
        copyText[0].select();
        copyText.setSelectionRange(0, 99999); // For mobile devices
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
         <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Blog Image Details</h4>
                    <div className="flex-shrink-0">
                        <a href='/addimage' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Banner</th>
                                    <th>Url</th>
                                    <th>Status</th>
                                    <th className='text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {result.map((row,index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{row.title}</td>
                                    <td><img src={`${AppConfig.BLOG_IMAGE}${row.blogimage}`} width="60px;" height="40px;" /></td>
                                    <td>
                                        <a href={`${AppConfig.BLOG_IMAGE}${row.blogimage}`} target="_blank">{`${AppConfig.BLOG_IMAGE}${row.blogimage}`}</a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<CopyToClipboard text={`${AppConfig.BLOG_IMAGE}${row.blogimage}`}><button><img style={{width:"10px"}} id="imgCap" src="https://img.icons8.com/material-outlined/512/copy.png 2x" /></button></CopyToClipboard>
                                        
                                    </td>
                                    <td>{row.status}</td>
                                    <td className='text-right'>
                                        <Link to={`/edit-image/${row.imageid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Delete the item?')){deletebanner(row.imageid)};}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>
                                    </td>
                                </tr>
                            ))}   
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Blogimage