import React,{useState, useEffect, useRef} from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { AppConfig } from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';

function Reels(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    
    const [result, setReels] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/reels/reelslist")
        setReels(res.data.reels)
    }

    const deletereel = async (rid,status) => {

        const qry = {"reelsid": rid, "status":status} 
        const res = await API.post("/reels/deletereels", qry).then((resp) => { 
            const msg = "Reel has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
         <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Reels Details</h4>
                    <div className="flex-shrink-0">
                        <a href='/addreel' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Embed Code</th>
                                    <th>Url</th>
                                    <th>Status</th>
                                    <th className='text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {result.map((row,index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{row.title}</td>
                                    <td>{row.embed_code}</td>
                                    <td><a href={row.embed_url} target="_blank">{row.embed_url}</a></td>
                                    <td>{row.status}</td>
                                    <td className='text-right'>
                                        <Link to={`/edit-reel/${row.reelsid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                        {row.status === 'active' ? (
                                            <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you inactive this blog?')){deletereel(row.reelsid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                        ):(
                                            <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you active this blog?')){deletereel(row.reelsid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                        )}
                                    </td>
                                </tr>
                            ))}   
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Reels