import React,{useState,useEffect,useRef} from 'react'
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from 'react-native-uuid'
import { AppConfig } from '../../Utils/GamesConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Addreview(props) {

    let navigate = useNavigate();
    const user = props.Users;
    //console.log(user.email)
    const [game, setGames] = useState([]);
    const [cat, setReviews] = useState({});

    useEffect(() => {
        getgames()
    },[])

    const getgames = async (e) => {
        const rwd = await API.get("/games/gameslist")
        console.log(rwd.data)
        setGames(rwd.data.games)
    }

    const handlechange = e => {
        const {name, value} = e.target
        setReviews({
            ...cat,[name]:value
        })
    }

    const addreview = async() => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var reviewid = new Date().valueOf() + val;
        
        if(cat.gameid == "" || cat.gameid == undefined){
            alert("Please select game")
            return false
        }

        if(cat.gameid){
            
            cat.reviewid = reviewid;
            cat.createdBy = user.username;
            cat.flag = "1";
            console.log(cat)
            
            API.post("/review/postreview", cat).then( result => {
                alert(result.data.message);
                navigate("/reviews")
            })
                
        }else{
            alert("Please select page")
        }
    }

    return (
        <React.Fragment>
            <Navbar user={user} />
            <div class="container mt-4">
                <div className='row'>
                    <div className='col-md-8 offset-md-2'>
                        <div class="card">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Add Review</h4>
                            </div>
                            <div class="card-body">
                                <form name='addcontest' id='addcontest' method='post'>

                                    <div className="mb-3">
                                        <label className="form-label">Name:</label>
                                        <input className="form-control" name='name' id='name' onChange={ handlechange } />
                                        
                                    </div>
                                    
                                    <div className="mb-3">
                                        <label className="form-label">Game:</label>
                                        <select className="form-control" name='gameid' id='gameid' onChange={ handlechange }>
                                            <option value="">--Select Game--</option>
                                            { game.map((row,index) => (
                                            <option value={row.gameid}>{row.gtitle}</option>     
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                    <label className="form-label">Message:</label>
                                    <textarea className="form-control" cols={40} rows={10} name='message' id='message' onChange={ handlechange } >{cat.message}</textarea>
                                </div>
                                    
                                    <div className="btn btn-primary" onClick={ addreview} >Submit</div>                         
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </React.Fragment>
    )
}

export default Addreview