import React, { useState, useEffect } from "react";
import API from '../../API';
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";


const ManageFaqs = () => {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    if(user === "" || user === null){
        navigate("/login")
    } 

    const [faqsList, setFaqsList] = useState()
    
    console.log("faqsList", faqsList)

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        await API.get("/api/v3/getfaq")
        .then(response => {
            setFaqsList(response.data)
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }    




    const activeFaq = async (faqid) => {
        const res = await API.get(`/api/v3/activefaq/${faqid}`)
        alert(res.data.message);
        fetchData()
    }    
    const inactiveFaq = async(faqid) => {
        const res = await API.get(`/api/v3/inactivefaq/${faqid}`)
        alert(res.data.message);
        fetchData()
    }
    // const deleteFaq = async (faqid) => {
    //     const res = await API.get(`/api/v3/deletefaq/${faqid}`)
    //     alert(res.data.message);
    //     fetchData()
    // }


    return(
        <React.Fragment>
        <Navbar user={user} />
        <div className='container-fluid mt-4'>
            <div className="table-responsive">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">FAQs</h4>
                        <div className="flex-shrink-0">
                            <a href='/add-faq' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add Faqs</a>
                        </div>                    
                    </div>
                    <div className='card-body p-0'>
                        <div className="table-responsive">
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="10%">#</th>
                                        <th width="10%">Question</th>
                                        <th width="10%">Answer</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>      
                                <tbody>
                                {(faqsList !== undefined && faqsList && faqsList.code === 2000 && faqsList && faqsList.success === true) ? 
                                <>
                                {faqsList && faqsList.resData && faqsList.resData.faqData.map((data, index) => ( 
                                    <tr key={index+1}>
                                        <td width="10%">{index+1}</td>
                                        <td width="10%">{data.faq_title}</td>
                                        <td width="10%"dangerouslySetInnerHTML={ { __html: data.description}}></td>
                                        <td width="10%" className='text-right'>
                                            {data.status === 1 ? (
                                                <a className="delete btn btn-soft-danger btn-sm"  style={{marginRight:"5px"}} onClick={() => {if(window.confirm('Are you sure you want inactive this Faq?')){inactiveFaq(data._id, 'inactive')};}} title="Inactive" data-toggle="tooltip">Active</a>
                                            ):(
                                                <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this Faq?')){activeFaq(data._id,'active')};}} title="Active" data-toggle="tooltip">Inactive</a>  
                                            )}                                                

                                           

                                            <Link to={`/edit-faq/${data._id}`} class="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>

                                            {/* <a class="delete btn btn-soft-danger btn-sm" onClick={() => deleteFaq()} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>                                                 */}
                                        </td>
                                    </tr>
                                ))} </>
                            
                                : <tr><td colSpan={4}><div style={{textAlign: "center"}}><p>{faqsList && faqsList.message}</p></div></td></tr>
                                } 
                                        {/* <tr><td>&nbsp;</td></tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default ManageFaqs;