import React, { useState,useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API'
import {AppConfig} from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faCopy, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router'
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";

function Games(props) {
    const {vendorid, tagid} = useParams()
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    //const user = props.Users;  
    const [games, setGames] = useState([])

    useEffect(() => {
        //fetchGames()
        fetchGamesByVendor()
    },[])

    const fetchGamesByVendor = async () => {
        const res =  await API.get("/games/games-by-vendor/"+vendorid)
        console.log("res", res)
        // console.log(res.data.games)
        setGames(res.data.vendorGames)
    }

    // const fetchGames = async () => {
    //     const res =  await API.get("/games/gameslist")
    //     console.log(res.data.games)
    //     setGames(res.data.games)
    // }

    const deletegame = async (gid,status) => {

        const qry = {"gid": gid, "status":status} 
        const res = await API.post("/games/deletegame", qry).then((resp) => { 
            const msg = "Game has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchGamesByVendor()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }
    return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Games List</h4>
                    <div className="flex-shrink-0">
                        <a href='/addgame' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                    </div>
                </div>
                {/* <div className='card-body p-3 bg-light'>
                    <div className="row">
                        <div className="col-md-2">
                            <label className="form-label">Vendor Name</label>
                            <input className="form-control" type="text" />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">Category</label>
                            <select className="form-control" aria-label="Default select example">
                                <option selected>Select Category</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">Game Title</label>
                            <input className="form-control" type="text" />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">Start Date:</label>
                            <DatePicker className="form-control" dateFormat="yyyy-MM-dd" />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">End Date:</label>
                            <DatePicker className="form-control" dateFormat="yyyy-MM-dd" />
                        </div>
                        <div className="col-md-2">
                            <label className="form-label w-100">&nbsp;</label>
                            <button type="button" className="btn btn-primary btn-block">Search</button>
                        </div>
                    </div>
                </div> */}
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <div className="table-wrapper">                            
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="10%">Vendor Name</th>
                                        <th width="15%">Category</th>
                                        <th width="10%">Sub childcategory</th>
                                        {/*<th width="10%">Url</th>*/}
                                        <th width="20%">Game Title</th>
                                        <th width="10%">Game Image</th>
                                        {/* <th width="10%">Rating</th>
                                        <th width="5%">Like</th>
                                        <th width="5%">Dislike</th> */}
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {games.map((row,index) => (
                                        <tr>
                                            <td width="5%">{index+1}</td>
                                            <td width="10%">{row.vendorname}</td>
                                            <td width="15%">{row.category}</td>
                                            <td width="10%">{row.subcategory}</td>
                                            {/*<td width="15%">{row.subcaturl}</td>*/}
                                            <td width="20%">{row.gtitle}</td>
                                            <td width="10%"><img src={`${AppConfig.GAME_IMAGE_NEW}/games/${row.hpimage}`} width="60px;" height="40px;" /></td>
                                            {/* <td width="10%">{row.grating}</td>
                                            <td width="5%">{row.glike}</td>
                                            <td width="5%">{row.gdislike}</td> */}
                                            <td width="20%" className='text-right'>
                                            
                                                <Link to={`/contest/${row.vendorid}/${row.gameid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}>Contests</Link>
                                                
                                                <Link to={`/copy-game/${row.gameid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={faCopy} /></Link>
                                                <Link to={`/edit-game/${row.gameid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                                {row.gamestatus === 'active' ? (
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this game?')){deletegame(row.gameid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                ):(
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this game?')){deletegame(row.gameid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                )}
                                            </td>
                                        </tr>
                                    ))}  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </React.Fragment>
    )
}

export default Games