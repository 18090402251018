import React, {useState, useEffect} from 'react'
import "./Homepage.css"
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn, faFilePen, faGamepad, faIndianRupeeSign, faListCheck, faUsers, faInr } from '@fortawesome/free-solid-svg-icons'
import { Chart } from "react-google-charts";
import { useNavigate } from 'react-router-dom';
import { AppConfig } from '../../Utils/GamesConfig';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment/moment';
import { ADMIN_REPORT_API_URL, BASIC_TOKEN } from '../../constant'


export const dataRevenue = [
  ["Year", "Sales", "Expenses", "Profit"],
  ["2019", 1000, 400, 200],
  ["2020", 1170, 460, 250],
  ["2021", 660, 1120, 300],
  ["2022", 1530, 540, 350],
];

export const optionsRevenue = {
  chart: {
    title: "Total Revenue",
    subtitle: "Sales, Expenses, and Profit: 2014-2017",
  },
};

export const dataCountry = [
  ["Country", "Popularity"],
  ["India", 9500],
  ["United States", 200],
  ["Brazil", 100],
  ["Canada", 100],
  ["France", 50],
  ["RU", 50],
];

export const dataInstall = [
  ["Task", "Hours per Day"],
  ["User", 10000],
  ["Registration", 8000],
];

export const optionsInstall = {
  pieHole: 0.5,
  is3D: false,
  legend: 'none',
  chartArea: {
    left: "3%",
    top: "3%",
    height: "94%",
    width: "94%"
  }
};

function Homepage(props) {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
      window.location.href="/login"
        ///navigate("/login")
    } 
     
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [affiliateData, setSource] = useState()
  const [mediumsData, setMediumsData] = useState()
  const [campaignsData, setCampaignsData] = useState()
  const [selectedValue, setSelectedValue] = useState({
      dateRange: [new Date(), new Date()],
      source: "",
      medium: "",
      campaign: ""
    })
  const [getAnalytics, setGetAnalytics] = useState()

  useEffect(() => {
    fetchSource();
    handleFiltersSubmit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  //Fetch Source
  const fetchSource = async () => {
    var auth_code = {
      'Authorization': BASIC_TOKEN
    }
      await axios.get(`${ADMIN_REPORT_API_URL}/reports/get-source`, {
        headers: auth_code
    })
      .then(response => {
        if(response.data.success === true && response.data.code === 2000){
          setSource(response.data.data.affiliateData)
        }else{
          setSource(null)
        }
      })
      .catch(error => {
          console.error('There was an error!', error);
      });
  }

    //Source Change Handler
    const handleChangeSource = (e) => {
      let mediumArray = []
      let campaignArray = []
      const { name, value } = e.target;
      if(value === "organic"){
        mediumArray.push({medium: value})
        setMediumsData(mediumArray);
        campaignArray.push({campaign: value})
        setCampaignsData(campaignArray);  
      }
      else if(value !== undefined && value !== ''){
        fetchFromSource(value);
      }

    }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setSelectedValue((prevState) => ({...prevState, [name]:value}))
    }    

    //Fetch from source
    const fetchFromSource = async (source) => {
      var auth_code = {
        'Authorization': BASIC_TOKEN
      }
        await axios.get(`${ADMIN_REPORT_API_URL}/reports/get-campaign/${source}`, {
          headers: auth_code
      })
        .then(response => {
          console.log("campaign", response)
          if(response.data.success === true && response.data.code === 2000){
            setMediumsData(response.data.data.mediums)
            setCampaignsData(response.data.data.campaigns)
          }else{
            setMediumsData(null)
            setCampaignsData(null)
          }
        })
        .catch(error => {
            console.error('There was an error!', error);
        });      
    }

    // Handle Filter
    const handleFiltersSubmit = async () => {
      var auth_code = {
        'Authorization': BASIC_TOKEN
      }
      const startDate = moment(selectedValue.dateRange[0]).format("YYYY-MM-DD")
      const endDate = moment(selectedValue.dateRange[1]).format("YYYY-MM-DD")

        var url = ADMIN_REPORT_API_URL+'/reports/get-analytics?startDate='+startDate+'&endDate='+endDate+'&source='+selectedValue.source+'&medium='+selectedValue.medium+'&campaign='+selectedValue.campaign;

        await axios.get(url, {
          headers: auth_code,
      })
        .then(response => {
          if(response.data.success === true && response.data.code === 2000){
            setGetAnalytics(response.data.data)
          }else{
            setGetAnalytics(null)
          }
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className="container pt-4 cFormLabel">
            <div className='row' style={{marginBottom: 20}}>
              <div className='col-xl-4 col-md-4'>
                <label className="form-label">Date Range Picker:</label>
                <DatePicker
                  className='form-control'
                  dateFormat="yyyy-MM-dd"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                    setSelectedValue((prevState) => ({...prevState, dateRange: update}))
                    
                  }}
                  maxDate={new Date()}
                />
              </div>
              <div className='col-xl-2 col-md-2'>
                <label className="form-label">Source:</label>
                <select 
                  className='form-control'
                  name='source'
                  onChange={(e) => {
                    handleChangeSource(e);
                    handleChange(e);                   
                  }}
                >
                  <option selected disabled>Select Source</option>
                  <option value="organic">Organic</option>
                  {affiliateData && affiliateData.map((affiliate, index) => (
                    <option value={affiliate.source}>{affiliate.source}</option>
                  ))}                  
                </select>
              </div>
              <div className='col-xl-2 col-md-2'>
                <label className="form-label">Medium:</label>
                <select className='form-control' id='medium'
                  name='medium'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  //defaultValue="Select Medium"
                >
                <option selected disabled>Select Medium</option>
                  {mediumsData && mediumsData.map((mediumList, index) => (
                    <option value={mediumList.medium}>{mediumList.medium}</option>
                  ))}
                </select>              
              </div>
              <div className='col-xl-2 col-md-2'>
                <label className="form-label">Campaign:</label>
                <select className='form-control' id='campaign'
                  name='campaign'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  //defaultValue="Select Campaign"
                >
                  <option selected disabled>Select Campaign</option>
                  {campaignsData && campaignsData.map((campaignList, index) => (
                    <option value={campaignList.campaign}>{campaignList.campaign}</option>
                  ))}                  
                </select>              
              </div>
              <div className='col-xl-2 col-md-2'>
                <label className="form-label d-block"> &nbsp;</label>
                <button className='btn btn-primary btn-block' onClick={handleFiltersSubmit}>Filter</button>              
              </div>            
            </div>
          <div className='row'>
            <div className='col-xl-4 col-md-4'>
              <div className='card ard-animate'>
                <div className='card-body'>
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Registration Count</strong></p>
                  <div className="d-flex align-items-end justify-content-between mt-2">
                      <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.registrationCount}>{getAnalytics && getAnalytics.registrationCount}</span></h4>
                          {/* <button type="button" className="btn btn-soft-secondary btn-sm btn-card">View All User</button> */}
                      </div>
                      {/* <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-soft-success rounded fs-3">
                              <FontAwesomeIcon icon={ faUsers } className='text-primary' />
                          </span>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            
            <div className='col-xl-4 col-md-4'>
              <div className='card ard-animate'>
                <div className='card-body'>
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Transaction Count</strong></p>
                  <div className="d-flex align-items-end justify-content-between mt-2">
                      <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.transactionCount}>{getAnalytics && getAnalytics.transactionCount}</span></h4>
                          {/* <button type="button" className="btn btn-soft-secondary btn-sm btn-card">View All Registration</button> */}
                      </div>
                      {/* <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-soft-success rounded fs-3">
                              <FontAwesomeIcon icon={ faFilePen } className='text-primary' />
                          </span>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-4'>
              <div className='card ard-animate'>
                <div className='card-body'>
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Transaction Amount</strong></p>
                  <div className="d-flex align-items-end justify-content-between mt-2">
                      <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><FontAwesomeIcon style={{fontSize: 20}}  icon={ faInr } /> <span className="counter-value" data-target={getAnalytics && getAnalytics.transactionAmount}>{getAnalytics && getAnalytics.transactionAmount}</span></h4>
                          {/* <button type="button" className="btn btn-soft-secondary btn-sm btn-card">View All Deposit</button> */}
                      </div>
                      {/* <div className="avatar-sm flex-shrink-0">
                          <span className="avatar-title bg-soft-success rounded fs-3">
                              <FontAwesomeIcon icon={ faIndianRupeeSign } className='text-primary' />
                          </span>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6 col-md-6'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-12 col-lg-12 col-md-12'>
                      <div className='row'>
                        <div className='col-xl-12 col-md-12'>
                          <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Winning Count </strong> <span style={{fontSize: 11}}>(Source not applicable)</span></p>
                        </div>
                        <div className='col-xl-4 col-md-4'>
                          <div className="d-flex align-items-end justify-content-between mt-2">
                              <div>
                                  <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.winningCount.deposit}>{getAnalytics && getAnalytics.winningCount.deposit}</span></h4>
                                  <span className='btn btn-soft-secondary btn-sm btn-card'>Deposit</span>
                              </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-md-4'>
                          <div className="d-flex align-items-end justify-content-between mt-2">
                              <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.winningCount.winning}>{getAnalytics && getAnalytics.winningCount.winning}</span></h4>
                                  <span className='btn btn-soft-secondary btn-sm btn-card'>Winning</span>
                              </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-md-4'>
                          <div className="d-flex align-items-end justify-content-between mt-2">
                              <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.winningCount.bonus}>{getAnalytics && getAnalytics.winningCount.bonus}</span></h4>
                                  <span className='btn btn-soft-secondary btn-sm btn-card'>Bonus</span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>                                        
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6 col-md-6'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-12'>
                        <div className='col-xl-12 col-md-12'>
                          <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Winning Amount </strong> <span style={{fontSize: 11}}>(Source not applicable)</span></p>
                        </div>                      
                    </div>
                    <div className='col-xl-4 col-md-4'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><FontAwesomeIcon style={{fontSize: 20}}  icon={ faInr } /> <span className="counter-value" data-target={getAnalytics && getAnalytics.winningAmount.deposit}>{getAnalytics && getAnalytics.winningAmount.deposit}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Deposit</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><FontAwesomeIcon style={{fontSize: 20}}  icon={ faInr } /> <span className="counter-value" data-target={getAnalytics && getAnalytics.winningAmount.winning}>{getAnalytics && getAnalytics.winningAmount.winning}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Winning</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><FontAwesomeIcon style={{fontSize: 20}} icon={ faInr } /> <span className="counter-value" data-target={getAnalytics && getAnalytics.winningAmount.bonus}>{getAnalytics && getAnalytics.winningAmount.bonus}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Bonus</span>
                          </div>
                      </div>
                    </div>                                        
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6 col-md-6'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-12 col-md-12'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Game Play Count</strong></p>
                    </div>                    
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.gamePlayCount.all}>{getAnalytics && getAnalytics.gamePlayCount.all}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>All</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.gamePlayCount.free}>{getAnalytics && getAnalytics.gamePlayCount.free}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Free</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.gamePlayCount.earn}>{getAnalytics && getAnalytics.gamePlayCount.earn}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Earn</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.gamePlayCount.paid}>{getAnalytics && getAnalytics.gamePlayCount.paid}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Paid</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6 col-md-6'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-12 col-md-12'>
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Unique Player Count</strong></p>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.uniquePlayerCount.all}>{getAnalytics && getAnalytics.uniquePlayerCount.all}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>All</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.uniquePlayerCount.free}>{getAnalytics && getAnalytics.uniquePlayerCount.free}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Free</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.uniquePlayerCount.earn}>{getAnalytics && getAnalytics.uniquePlayerCount.earn}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Earn</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.uniquePlayerCount.paid}>{getAnalytics && getAnalytics.uniquePlayerCount.paid}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Paid</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            

            <div className='col-xl-12 col-md-12'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>

                    <div className='col-xl-4 col-md-4'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Average Game Play Per User</strong></p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.averageGamePlayPerUser.free}>{getAnalytics && getAnalytics.averageGamePlayPerUser.free}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Free</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">&nbsp;</p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.averageGamePlayPerUser.earn}>{getAnalytics && getAnalytics.averageGamePlayPerUser.earn}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Earn</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-md-4'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">&nbsp;</p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.averageGamePlayPerUser.paid}>{getAnalytics && getAnalytics.averageGamePlayPerUser.paid}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Paid</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

            <div className='col-xl-8 col-md-8'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-3 col-md-6'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>KYC Count</strong></p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.kycCount.semi}>{getAnalytics && getAnalytics.kycCount.semi}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Semi</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">&nbsp;</p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.kycCount.full}>{getAnalytics && getAnalytics.kycCount.full}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Full</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">&nbsp;</p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.kycCount.blocked}>{getAnalytics && getAnalytics.kycCount.blocked}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Blocked</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-6'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">&nbsp;</p>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.kycCount.rejected}>{getAnalytics && getAnalytics.kycCount.rejected}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Rejected</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-4 col-md-4'>     
              <div className='card ard-animate'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-xl-12 col-md-12'>
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0"><strong>Affiliate Push Back Count</strong></p>                      
                    </div>
                    <div className='col-xl-6 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.affiliatePushBackCount.registration}>{getAnalytics && getAnalytics.affiliatePushBackCount.registration}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Registration</span>
                          </div>
                      </div>
                    </div>
                    <div className='col-xl-6 col-md-6'>
                      <div className="d-flex align-items-end justify-content-between mt-2">
                          <div>
                          <h4 className="fs-22 fw-semibold ff-secondary mb-2"><span className="counter-value" data-target={getAnalytics && getAnalytics.affiliatePushBackCount.deposit}>{getAnalytics && getAnalytics.affiliatePushBackCount.deposit}</span></h4>
                              <span className='btn btn-soft-secondary btn-sm btn-card'>Deposit</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            

          </div>
          {/* <div className='row'>
            <div className='col-xl-12 col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={dataRevenue}
                    options={optionsRevenue}
                  />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-md-6'>
              <div className='card'>
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Play By Country</h4>
                </div>
                <div className='card-body'>
                  <Chart
                    chartEvents={[
                      {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                          const chart = chartWrapper.getChart();
                          const selection = chart.getSelection();
                          if (selection.length === 0) return;
                          const region = dataCountry[selection[0].row + 1];
                          console.log("Selected : " + region);
                        },
                      },
                    ]}
                    chartType="GeoChart"
                    width="100%"
                    height="400px"
                    data={dataCountry}
                  />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-md-6'>
              <div className='card'>
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Install &amp; Play</h4>
                </div>
                <div className='card-body'>
                  <Chart
                    chartType="PieChart"
                    width="100%"
                    height="400px"
                    data={dataInstall}
                    options={optionsInstall}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
    </React.Fragment>
  )
}

export default Homepage
