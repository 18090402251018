import React, { useState } from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';

function Json() {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const [msg, strMsg] = useState([])
    const [smsg, strsMsg] = useState([])

    if(user === "" || user === null){
        navigate("/login")
    }
    
    const jsonupdate = async () => {
        try{
            await API.get("/cron/homejsonupdate").then((resp) => { 
                const msg = "Json has been " + resp.data.message + " Successfully"
                strMsg("Success")
                alert(msg);
            }).catch((err) => { 
                console.log("Error Found") 
            });
        }catch(error){
            console.log("Error:"+ error)
        }
        
    }
    const uploadonbucket = async () => {
        try{
            await API.get("/cron/homejsonuploadbucket").then((resp) => { 
                const msg = resp.data.message + " Uploaded Successfully on Bucket"
                strsMsg("Success")
                alert(msg);
            }).catch((err) => { 
                console.log("Error Found") 
            });
        }catch(error){
            console.log("Error:"+ error)
        }
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
         <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Json Scheduler</h4>
                    
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <div class="card-body">
                            <div className="mb-3">
                                    <label className="form-label">Home JSON Update:</label>
                                    {msg === 'Success' ? (
                                        <a className="btn btn-primary" title="Updated" data-toggle="tooltip">Updated</a>
                                    ):(
                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {jsonupdate()}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={ faEdit } />Update</a>
                                    )}
                                    
                            </div>
                            <div className="mb-3">
                                    <label className="form-label">Json Upload on GCP Bucket:</label>
                                    {smsg === 'Success' ? (
                                        <a className="btn btn-primary" title="Uploaded" data-toggle="tooltip">Uploaded</a>
                                    ):(
                                        <a className="delete btn btn-soft-danger btn-sm" onClick={() => {uploadonbucket()}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={ faEdit } />Upload</a>
                                    )}
                                    
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Json