import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import { AppConfig } from '../../Utils/GamesConfig';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
  
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'light',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function Managecategory(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    //console.log(props)
    const [hgame, setHgames] = useState([])
    const [result, setCategory] = useState([])
    const [games, setGames] = useState([])
    const [cat, setCat] = useState({})
    
 
    useEffect(() => {
        fetchHomePageGames()
        fetchData()
    },[])

    const fetchHomePageGames = async() => {
        const res =  await API.get("/category/homepggames")
        setHgames(res.data.games)
    }

    const fetchData = async () => {
        const res =  await API.get("/category/categorylist")
        setCategory(res.data.categories)
    }

    const getGames = async(e) => {
        const {name, value} = e.target
        //alert(name);
        const qry = {"catid": value}
        const result = await API.post("/games/categorygames/", qry)
        console.log(result.data)

        setGames(result.data.games)
    }
   
    const onDragEnd = (result)  =>{
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const items = reorder(
         games,
          result.source.index,
          result.destination.index
        );
        console.log(items)
        setGames(items)
    }

    const handleChange = e => {
        const {name, value} = e.target
        setCat({
            ...cat,[name]:value
        })
    }

    const handleChk = async(e) => {
        const {name,value} = e.target
        const qry = {"position": value}
        const chk = await API.post("/category/chkposition", qry).then(result => {
            alert(result.data.message)
            return false;
        })
    }
  
    const handleSubmit = async(e) => {
        
        const {position,catname} = cat
       
        if(!position){
            alert("Please select position")
            return false;
        }
        if(!catname){
            alert("Please select categpry");
            return false;
        }
        
        const qry = {"position": position, "category":catname, "games": games,"createdBy":email} 
        const res = await API.post("/category/posthpagecatgames", qry).then(result => {
            alert(result.data.message)  
            window.location.reload(); 
            //navigate("/managecategories")
        })
    }

    const deletedata = async(catid) => {

        const res = await API.get(`/category/deletehomegame/${catid}`)
        alert(res.data.message);
        fetchHomePageGames()
    }

    return (
        <React.Fragment>
            <Navbar user={user} />
            <div class="container-fluid mt-4">
                <div class="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Category Page Management</h4>
                        <div className="flex-shrink-0">
                            <a class="btn btn-primary btn-sm" href="/managecategories" style={{marginRight:"5px"}}>Categories</a>
                            <a class="btn btn-soft-secondary btn-sm" href="/managebanners">Banners</a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div className='row mb-3'>
                            <div className="col">
                                <label className="form-label">Position:</label>
                                <select name='position' id='position' class="form-control" onChange={(e) =>{ handleChange(e); handleChk(e); }}>
                                    <option value="">--Select Position--</option>
                                    <option value="1">First</option>
                                    <option value="2">Second</option>
                                    <option value="3">Third</option>
                                    <option value="4">Fourth</option>
                                    <option value="5">Fifth</option>
                                    <option value="6">Six</option>
                                </select>
                            </div>
                            <div className="col">
                                <label className="form-label">Category Name:</label>
                                <select name="catname" id="catname" class="form-control" onChange={ (e) =>{ getGames(e); handleChange(e); }}>
                                    <option value="">--Select Category--</option>
                                    {result.map((row,index) => (
                                        <option value={row.catid}>{row.catname}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Sorting:</label>
                            <DragDropContext name="gameimg" id="gameimg" onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                    >
                                    {games.map((item, index) => (
                                        
                                        <Draggable key={item._id} draggableId={item._id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            >
                                            <img src={`${AppConfig.GAME_IMAGE_NAME}${item.hpimage}`} width="100px;" height="80px;" />
                                            <br /><label class="text-white bg-dark mt-md-2">{item.gtitle}</label>
                                            </div>
                                            
                                        )}
                                        
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="btn btn-primary" onClick={ (e) => handleSubmit(e) } >Submit</div>
                    </div>                    
                </div>
                <div className='card'>
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Sorting Details</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="5%">Position</th>
                                        <th width="15%">Category</th>
                                        <th>Game</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hgame.map((row,index) => (
                                        <tr>
                                            <td>{row.position}</td>
                                            <td>{row.catname}</td>
                                            <td className='d-flex align-items-center'>
                                                {row.games.map((rwd,index) => (
                                                    <div class="thumbnail text-center" style={{marginRight:"10px"}}>
                                                        <a href="/w3images/lights.jpg" target="_blank">
                                                            <img src={`${AppConfig.GAME_IMAGE_NAME}${rwd.hpimage}`} width="60px;" height="40px;" />
                                                            <div class="caption">
                                                                <span>{rwd.gtitle}</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                <a class="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Delete the item?')){deletedata(row.catid)};}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>
                                            </td>
                                        </tr>
                                    ))}
                                        
                            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Managecategory