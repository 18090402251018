import React, { useState } from 'react'
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Addvendor(props) {
    
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [vendor, setVendor] = useState({})
    const [date, setDate] = useState(new Date());
   
    const handlechange = e => {
        const {name, value} = e.target
        setVendor({
            ...vendor,[name]:value
        })
    }

    const addVendor = () => {
        var val = Math.floor(100000 + Math.random() * 900000);
        const {vendorid,vendorname,vendoremail,vendorphone,vendercompany,vendortype,vendorstatus,vendordate} = vendor

        if(vendorname === "" || vendorname === undefined){
            alert("Please enter vendor name")
            return false
        }
        if(vendoremail === "" || vendoremail === undefined){
            alert("Please enter vendor email")
            return false
        }
        if(vendorphone === "" || vendorphone === undefined){
            alert("Please enter vendor phone")
            return false
        }

        if(vendorname){
            var vndname = vendor.vendorname.toLowerCase();
            vendor.vendorid = vndname.replace(/ /g, '') + "-" + val;
            vendor.vendordate = date;
            vendor.createdBy = email;
            console.log(vendor)
            
            API.post("/vendor/postdata", vendor).then( result => {
                alert(result.data.message);
                navigate("/vendors")
            })
                
        }else{
            alert("Please enter vendor name")
        }
    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Vendor</h4>
                        </div>
                        <div className='card-body'>
                            <form name='addvendor' id='addvendor' method='post'>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Name:</label>
                                    <input className="form-control" type="text" name='vendorname' id='vendorname' value={vendor.vendorname} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Onboarding Date:</label>
                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date} onChange={date => setDate(date)} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Email:</label>
                                    <input className="form-control" type="text" name='vendoremail' id='vendoremail' value={vendor.vendoremail} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Phone:</label>
                                    <input className="form-control" type="text" name='vendorphone' id='vendorphone' maxLength={12} value={vendor.vendorphone} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Company:</label>
                                    <input className="form-control" type="text" name='vendercompany' id='vendercompany' value={vendor.vendercompany} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Type:</label>
                                    <input className="form-control" type="text" name='vendortype' id='vendortype' value={vendor.vendortype} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Monetisation Model:</label>
                                    <select className="form-control" name='monetisation' id='monetisation' onChange={ handlechange }>
                                        <option value="">--Select Monetisation Model--</option>
                                        <option value="ads">Ads</option>
                                        <option value="rmg">RMG</option>
                                        <option value="both">Both</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Status:</label>
                                    <select className="form-control" name='vendorstatus' id='vendorstatus' onChange={ handlechange }>
                                        <option value="">--Status--</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Sticky Adcode:</label>
                                    <input className="form-control" type="text" name='vendorsticky' id='vendorsticky' value={vendor.vendorsticky} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Interstitial Adcode:</label>
                                    <input className="form-control" type="text" name='vendorinterstitial' id='vendorinterstitial' value={vendor.vendorinterstitial} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Rewarded Ad Code:</label>
                                    <input className="form-control" type="text" name='vendorrewarded' id='vendorrewarded' value={vendor.vendorrewarded} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Vendor Banner Ad Code:</label>
                                    <input className="form-control" type="text" name='vendorbannerads' id='vendorbannerads' value={vendor.vendorbannerads} onChange={ handlechange } />
                                </div>
                                <div className="btn btn-primary" onClick={ addVendor} >Submit</div>                         
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </React.Fragment>
  )
}

export default Addvendor