import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Editsubcategory(props) {

    const {catid, subcatid} = useParams()
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [categories, setItems] = useState([]);
    const editorRef = useRef(null);
  
   const [cat, setCategory] = useState({})

   useEffect(() => {
    fetchData()
    fetchCategoryData()
   },[])

    const handlechange = e => {
        const {name, value} = e.target
        console.log(e.target.value)
        setCategory({
            ...cat,[name]:value
        })
        
    }

    const imghandlechange = e => {
        setCategory({
            ...cat,subcatimage:e.target.files[0]
        })
    }
   
    const fetchData = async () => {
      const res =  await API.get(`/category/parentcategory`)
      //console.log(res.data.caregories)
      setItems(res.data.categories)
      
   }

    const fetchCategoryData = async () => {
        const res =  await API.get(`/category/category/${catid}/${subcatid}`)
        console.log(res.data.category.subcategories[0])
        setCategory(res.data.category.subcategories[0])
    }

   const editcategory = () => {

        if(cat.subcatname == "" || cat.subcatname == undefined){
            alert("Please enter sub category name")
            return false
        }
        if(cat.submtitle == "" || cat.submtitle == undefined){
            alert("Please enter sub category meta title")
            return false
        }
        if(cat.submkeywords == "" || cat.submkeywords == undefined){
            alert("Please enter sub category meta keywords")
            return false
        }
        if(cat.submdescr == "" || cat.submdescr == undefined){
            alert("Please enter sub category meta description")
            return false
        }
        if(cat.subfdescr == "" || cat.subfdescr == undefined){
            alert("Please enter sub category description")
            return false
        }

        if (editorRef.current) {
            cat.subfdescr = editorRef.current.getContent();
        }
        
        const formdata = new FormData();

        if(cat.subcatimage){
            formdata.append('subcatimage', cat.subcatimage, cat.subcatimage.name)
        }

        formdata.append('subcatname', cat.subcatname)
        formdata.append('submtitle', cat.submtitle)
        formdata.append('submkeywords', cat.submkeywords)
        formdata.append('submdescr', cat.submdescr)
        formdata.append('subfdescr', cat.subfdescr)
        formdata.append('subcaturl', cat.subcaturl)
        formdata.append('patcategory', catid)
        formdata.append('subcatid', subcatid)
        formdata.append('updatedBy', email)

        if(cat.subcatname){
            //console.log(cat)
            API.post("/category/editsubcategory", formdata)
            .then(res => {
                alert(res.data.message);
               navigate("/categories")
            })
        }else{
            alert("Please enter category name")
        }
   }
   
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container mt-4">
            <div class="row">
            
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Sub Category</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Parent Category:</label>
                                <select className="form-control" name="patcategory" id="patcategory" onChange={ handlechange } value={catid} disabled>
                                    <option value="">--Select Parent Category--</option>
                                    {categories.map((cat,index) => (
                                        <option value={cat.catid}>{cat.catname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Category Icon:</label>
                                <input className="form-control" type='file' id='subcatimage' name='subcatimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.CATEGORY_IMAGE_NAME}${cat.subcategoryimage}`} target="_blank"><img src={`${AppConfig.CATEGORY_IMAGE_NAME}${cat.subcategoryimage}`} width="20px;" height="20px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Category Name:</label>
                                <input className="form-control" type="text" name='subcatname' id='subcatname' value={cat.subcatname} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Frontend Description:</label>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={cat.subfdescr}
                                    name="subfdescr"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                        'advlist autolink lists link image', 
                                        'charmap print preview anchor help hr',
                                        'searchreplace visualblocks wordcount code fullscreen',
                                        'insertdatetime media table paste wordcount template',
                                        'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                        'undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright | \
                                        bullist numlist outdent indent | help'
                                    }} 
                                    onChange={ handlechange }
                                 />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Sub Category Url:</label>
                                <input className="form-control" type="text" name='subcaturl' id='subcaturl' value={cat.subcaturl} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Title:</label>
                                <input className="form-control" type="text" name='submtitle' id='submtitle' value={cat.submtitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Keywords:</label> 
                                <textarea className="form-control" cols={40} rows={2} name='submkeywords' value={cat.submkeywords} id='submkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                            </div> 
                            <div className="mb-3">
                                <label className="form-label">Meta Description:</label> 
                                <textarea className="form-control" cols={40} rows={10} name='submdescr' id='submdescr' onChange={ handlechange } value={cat.submdescr} ></textarea>
                            </div>
                            <div className="btn btn-primary" onClick={ editcategory} >Submit</div>   
                        </div>                      
                    </div>
                </div>    
            </div>
        </div>
    </React.Fragment>
  )
}

export default Editsubcategory