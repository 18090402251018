import React, {useState} from "react"
import "./Login.css"
import API from "../../API";
import { useNavigate } from 'react-router-dom';

function Login({props}) {
   //let navigate = useNavigate();

    const [ user, setUser] = useState({
        email:"",
        password:""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const login = () => {
        //console.log(user);
        API.post("/user/login", user)
        .then(res => {
            localStorage.setItem('username', res.data.users.username);
            localStorage.setItem('email', res.data.users.email);
            window.location.href = '/dashboard';
        })
    }

    return (
        <div className="loginWrapper">
            <div>
                <div className="logo"><img src="logo.png" /></div>
                <div className="login">                
                    <h1>Login</h1>
                    <p>Access to our dashboard</p>

                    <div class="mb-3">
                        <label class="form-label">Email address</label>
                        <input type="text" class="form-control" name="email" value={user.email} onChange={handleChange} placeholder="Enter your Email"></input>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Password</label>
                        <input type="password" class="form-control" name="password" value={user.password} onChange={handleChange}  placeholder="Enter your Password" ></input>
                    </div> 
                    <div class="mb-3 d-grid">
                        <div className="btn btn-primary w-100" onClick={login}>Login</div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}

export default Login