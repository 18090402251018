import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Navbar from '../Navbar';
import axios from "axios";
import API from "../../API";

const AddWinner = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  
  if(user === "" || user === null){
      navigate("/login")
  }

  const [winners, setWinners] = useState({
      winner_name: "",
      winner_image: "",
      winning_amount: ""
  })

    const handleChange = (e) => {
        const {name, value} = e.target;
        setWinners((prevState) => ({...winners, [name]:value}))
    }
    const isUrlValid = (userInput) => {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(res == null)
            return (false );
        else
            return (true);
    };

    const addWinnerFunc = () => {

        if(winners.winner_name !== "" || winners.winner_name !== undefined || winners.winner_image !== "" || winners.winner_image !== undefined || winners.winning_amount !=="" || winners.winning_amount !== undefined){
          API.post('/api/addwinner', winners)
          .then(response => {            
            alert(response.data.message);
            setTimeout(() => {
              navigate("/winners");
            }, 1000)            
          } )
          .catch(error => {              
              console.error('There was an error!', error);
          });  
        }
        if (winners.winner_name === "" || winners.winner_name === undefined) {
            alert("Please enter winner name");
            return false;
        }
        if (winners.winner_image === "" || winners.winner_image === undefined) {
            alert("Please enter winner image");
            return false;
        } else if(!isUrlValid(winners.winner_image) ) {
            alert("Please enter a valid image url");
            return false;
        }
        if (winners.winning_amount === "" || winners.winning_amount === undefined) {
            alert("Please enter winning amount");
            return false;
        }
    };

    return(
        <React.Fragment>
        <Navbar user={user} />
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Add Winner</h4>
                </div>
                <div className="card-body">
                  <form name="addcontest" id="addcontest" method="post">
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Winner Name:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="winner_name"
                          id="winner_name"
                          placeholder="Winner Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Winner Image <span style={{fontSize: 8, color: "red"}}>(Paste user image url)</span>:</label>
                        <input
                          className="form-control"
                          type="url"
                          name="winner_image"
                          id="winner_image"
                          placeholder="Image Url"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Winning Amount:</label>
                        <input
                          className="form-control"
                          type="number"
                          name="winning_amount"
                          id="winning_amount"
                          placeholder="Winning Amount"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={addWinnerFunc}>Submit</div>
                    </div>                                           
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
}

export default AddWinner;