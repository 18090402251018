import React, { useState,useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API'
import {AppConfig} from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faEdit,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faTrash,
    faTimes,
    faClone
  } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom'

function Contest(props) {
    let navigate = useNavigate();
    const {vendorid, gameid} = useParams()
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [contest, setContest] = useState([])

    


    useEffect(() => {
        fetchContestByGameId()
        // fetchContest()
    },[])


    const fetchContestByGameId = async () => {
        const res =  await API.get("/contest/contest-by-game/"+gameid)
        console.log("contest Data",res.data.contestByGame)
        setContest(res.data.contestByGame)
    }

    // const fetchContest = async () => {
    //     const res =  await API.get("/contest/contestlist")
    //     console.log(res.data.contests)
    //     setContest(res.data.contests)
    // }

    const deletecontest = async (cid) => {
        const res = await API.get(`/contest/deletecontest/${cid}`)
        alert(res.data.message);
        fetchContestByGameId()
    }
    return (
        <React.Fragment>
            <Navbar user={user} />
            <div class="container-fluid mt-4">
                <div class="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Games List</h4>
                        <div className="flex-shrink-0">
                            <a href={`/addcontest/${vendorid}/${gameid}`} className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th className='text-center'>#</th>
                                        {/* <th>Category</th> */}
                                        <th>Type</th>
                                        <th>Game</th>
                                        <th>Message</th>
                                        <th>Offer Entry Fee</th>
                                        <th>Winning Amount</th>
                                        <th>Wallet</th>
                                        <th>Score</th>
                                        <th>Rake Fee</th>
                                        <th>No. Of Player's</th>
                                        <th>Re-Arrange</th>
                                        <th className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {contest.map((row,index) => (
                                        <tr>
                                            <td className='text-center'>{index+1}</td>
                                            {/* <td>{row.category}</td> */}
                                            <td>{row.gtype}</td>
                                            <td>{row.game}</td>
                                            <td>{row.message}</td>
                                            <td>{row.offer_entry_fee}</td>
                                            <td>{row.offer_wining_price}</td>
                                            <td>{row.wallet_type}</td>
                                            <td>{row.score}</td>
                                            <td>{row.rake_fee}</td>
                                            <td>{row.no_of_players}</td>
                                            <td>
                                                <button
                                                className="btn btn-soft-secondary btn-sm"
                                                title="UP"
                                                style={{ marginRight: 10 }}
                                                >
                                                    <FontAwesomeIcon icon={faLongArrowAltUp} />
                                                </button>
                                                <button
                                                className="btn btn-soft-secondary btn-sm"
                                                title="DOWN"
                                                >
                                                    <FontAwesomeIcon icon={faLongArrowAltDown} />
                                                </button>
                                            </td>
                                            <td className='text-right'>
                                                <Link to={``} class="edit btn btn-soft-secondary btn-sm" title="Clone" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faClone } /></Link>

                                                <Link to={`/edit-contest/${vendorid}/${gameid}/${row.contestid}`} class="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>

                                                <a class="delete btn btn-soft-danger btn-sm" onClick={() => deletecontest(row.contestid)} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>
                                            </td>
                                        </tr>
                                    ))}  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Contest