import React, {useState, useEffect, useRef} from "react";
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";
import Navbar from '../Navbar';
import axios from "axios";
import API from "../../API";
import {useParams} from "react-router-dom"


const EditPage = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem('username');
  const email = localStorage.getItem('email');
  
  if(user === "" || user === null){
      navigate("/login")
  }

  const [page, setPage] = useState({})
  
  const descriptionRef = useRef(null)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setPage({
            ...page,[name]:value
        })
    }

    useEffect(() => {
        fetchPageById()
    },[])


    const {pageid} = useParams()
    const fetchPageById = async () => {
        await API.get(`/api/v3/page/${pageid}`)
        .then(response => {
            //console.log("response", response)
            setPage(response.data.resData)
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }


    const editPageFunc = () => {

        if(descriptionRef.current){
            page.description = descriptionRef.current.getContent();
        }
        
        if(page.title !== "" || page.title !== undefined || page.title !== null || page.description !== undefined || page.description !=="" || page.description !== null){
          API.post('/api/v3/editpage', page)
          .then(response => {            
            alert(response.data.message);
            setTimeout(() => {
              navigate("/manage-pages");
            }, 1000)            
          } )
          .catch(error => {              
              console.error('There was an error!', error);
          });  
        }
        if (page.title === "" || page.title === undefined) {
            alert("Please enter page title");
            return false;
        }
        if (page.description === "" || page.description === undefined) {
            alert("Please enter page description");
            return false;
        }
    };

    return(
        <React.Fragment>
        <Navbar user={user} />
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Add New Page</h4>
                </div>
                <div className="card-body">
                  <form name="addPage" id="addPage" method="post">
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Page Title:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="title"
                          id="title"
                          value={page.title}
                          placeholder="Title"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3 col-md-12">
                            <label className="form-label">Description:</label>
                            <Editor
                                onInit={(evt, editor) => descriptionRef.current = editor}
                                initialValue={page.description}
                                name="description"
                                init={{
                                    height: 350,
                                    plugins:
                                    "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                    toolbar:
                                    "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                    hieght: 350,
                                    toolbar_sticky: true,
                                    icons: "thin",
                                    skin: "material-classic"
                                }} 
                                onChange={ handleChange }
                            />
                        </div>
                        <div className="mb-3 col-md-12">
                            <label className="form-label">Meta Title:</label>
                            <input
                            className="form-control"
                            type="text"
                            name="meta_title"
                            id="meta_title"
                            placeholder="Meta Title: Not more than 60 characters."
                            value={page.meta_title}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-md-12">
                            <label className="form-label">Meta Keywords:</label>
                            <input
                            className="form-control"
                            type="text"
                            name="meta_keywords"
                            id="meta_keywords"
                            placeholder="Meta Keywords Comma Separate: e.g. Jagran Play, Online Gaming..."
                            value={page.meta_keywords}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 col-md-12">
                            <label className="form-label">Meta Description:</label>
                            <textarea
                            className="form-control"
                            type="text"
                            name="meta_title"
                            id="meta_title"
                            placeholder="Meta Description: Not more then 160 characters."
                            value={page.meta_description}
                            onChange={handleChange}
                            />
                        </div>                                                                       
                      <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={editPageFunc}>Submit</div>
                    </div>                                           
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
}

export default EditPage;