import React,{useState, useEffect, useRef} from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { AppConfig } from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';

function Banner(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    
    const [result, setBanners] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/banner/bannerlist")
        //console.log(res.data.caregories)
        setBanners(res.data.banners)
    }

    const deletebanner = async (bid,status) => {

        const qry = {"bid": bid, "status":status} 
        const res = await API.post("/banner/deletebanner", qry).then((resp) => { 
            const msg = "Banner has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
         <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Banner Details</h4>
                    <div className="flex-shrink-0">
                        <a href='/addbanner' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                <tr>
                                    <th>#</th>
                                    <th>Page Name</th>
                                    <th>Banner Title</th>
                                    <th>Banner</th>
                                    <th>Status</th>
                                    <th className='text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {result.map((row,index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{row.pagename}</td>
                                    <td>{row.bannertitle}</td>
                                    <td><img src={`${AppConfig.BANNER_IMAGE_NAME}${row.bannerimage}`} width="60px;" height="40px;" /></td>
                                    <td>{row.bannerstatus}</td>
                                    <td className='text-right'>
                                        <Link to={`/edit-banner/${row.bannerid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                        {row.bannerstatus === 'active' ? (
                                            <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deletebanner(row.bannerid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                        ):(
                                            <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deletebanner(row.bannerid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                        )}
                                    </td>
                                </tr>
                            ))}   
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Banner