import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Navbar";
import API from "../../../API";
import { useParams } from "react-router-dom";

const EditTag = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [tag, setTag] = useState();

  useEffect(() => {
    fetchTagById();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTag({
      ...tag,
      [name]: value,
    });
  };

  const { id } = useParams();

  const fetchTagById = async () => {
    await API.get("/api/v3/tag/" + id)
      .then((response) => {
        setTag(response.data.resData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const editTag = () => {
    if (tag.tag_name !== "") {
      API.post("/api/v3/edittag", tag)
        .then((response) => {
          alert(response.data.message);
          setTimeout(() => {
            navigate("/manage-tags");
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    if (tag.tag_name === "" || tag.tag_name === undefined) {
      alert("Please enter tag name");
      return false;
    }
    if (tag.layout === "" || tag.layout === undefined) {
      alert("Please select layout type.");
      return false;
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Edit Tag</h4>
              </div>
              <div className="card-body">
                <form name="addcontest" id="addcontest" method="post">
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Tag Name:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="tag_name"
                        id="tag_name"
                        placeholder="Tag Name"
                        value={tag && tag.tag_name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Layout:</label>
                      <select
                        className="form-control"
                        name="layout"
                        value={tag && tag.layout}
                        id="layout"
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   handleMultiPlayer(e)
                        //   handlechange(e);
                        // //  handleflag(e);
                        // }}
                      >
                        <option value="">Select</option>
                        <option value="square_scrollable">
                          Square Scrollable
                        </option>
                        <option value="verticle_scrollable">
                          Verticle Scrollable
                        </option>
                        <option value="fixed">Fixed</option>
                        <option value="circle_fixed">Circle Fixed</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_title"
                        id="meta_title"
                        placeholder="Meta Title"
                        value={tag && tag.meta_title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Description:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="meta_description"
                        id="meta_description"
                        placeholder="Meta Description"
                        value={tag && tag.meta_description}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Meta Keywords:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="keywords"
                        id="keywords"
                        placeholder="Meta Keywords"
                        value={tag && tag.keywords}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={editTag}>
                        Submit
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditTag;
