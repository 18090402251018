import React, { useState } from 'react'
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Addblogcategory(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [cat, setCat] = useState({})
   
    const handlechange = e => {
        const {name, value} = e.target
        setCat({
            ...cat,[name]:value
        })
    }

    const addCat = () => {
        var val = Math.floor(100000 + Math.random() * 900000);
        const {blogcatid,catname} = cat

        if(catname == "" || catname == undefined){
            alert("Please enter category name")
            return false
        }
        

        if(catname){
            
            cat.blogcatid = val;
            var caturl = catname.replace(/\s+/g, '-').toLowerCase();
            cat.catname = cat.catname;
            cat.createdBy = email;
            cat.caturl = caturl;
            
            console.log(cat)
            
            API.post("/blog/postblogcat", cat).then( result => {
                alert(result.data.message);
                navigate("/blogcategory")
            })
                
        }else{
            alert("Please enter category name")
        }
    }
    return (
        <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Category</h4>
                        </div>
                        <div className='card-body'>
                            <form name='addblogcategory' id='addblogcategory' method='post'>
                                <div className="mb-3">
                                    <label className="form-label">Category Name:</label>
                                    <input className="form-control" type="text" name='catname' id='catname' value={cat.catname} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Title(Seo):</label>
                                    <input className="form-control" type="text" name='mtitle' id='mtitle' value={cat.mtitle} onChange={ handlechange } />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Keywords(Seo):</label>
                                    <textarea className="form-control" cols={40} rows={2} name='mkeywords' id='mkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Meta Description(Seo):</label>
                                    <textarea className="form-control" cols={40} rows={10} name='mdescr' id='mdescr' onChange={ handlechange } >{cat.mdescr}</textarea>
                                </div>
                                <div className="btn btn-primary" onClick={ addCat} >Submit</div>                         
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </React.Fragment>
    )
}

export default Addblogcategory