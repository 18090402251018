import React,{useState,useEffect,useRef} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

function Addblog(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }

    var timestamp = new Date().getUTCMilliseconds();
    //console.log(timestamp)
    const [startdate, setStartdate] = useState(new Date());
    const [cate, setCategory] = useState([]);
    const blogdescrRef = useRef(null);
    const blogshortdescrRef = useRef(null)
    const blogfaqRef = useRef(null)
    const [date, setDate] = useState(new Date());
    const [blog, setBlog] = useState({})

    useEffect(() => {
        fetchCategory()
    },[])

    const fetchCategory = async () => {
        const pcat = await API.get("/blog/blogcategorylist")
        setCategory(pcat.data.categories)
    }

    const handlechange = e => {
        const {name, value} = e.target
        setBlog({
            ...blog,[name]:value
        })
    }

    const imghandlechange = e => {
        setBlog({
            ...blog,blogimage:e.target.files[0]
        })
    }

    const imghandlechange1 = e => {
        setBlog({
            ...blog,blogbigimage:e.target.files[0]
        })
    }

    const addblog = async() => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var blogid = new Date().valueOf() + val;
       
        if(blogshortdescrRef.current){
            blog.short_description = blogshortdescrRef.current.getContent();
        }
        if(blogdescrRef.current){
            blog.description = blogdescrRef.current.getContent();
        }
        if(blogfaqRef.current){
            blog.faq = blogfaqRef.current.getContent();
        }

        if(blog.blogcatid === "" || blog.blogcatid === undefined){
            alert("Please select blog category")
            document.getElementById('blogcatid').focus()
            return false
        }
        if(blog.blogtitle === "" || blog.blogtitle === undefined){
            alert("Please enter blog title")
            document.getElementById('blogtitle').focus()
            return false
        }
        if(blog.blogimage === "" || blog.blogimage === undefined){
            alert("Please upload blog small image")
            document.getElementById('blogimage').focus()
            return false
        }
        if(blog.blogbigimage === "" || blog.blogbigimage === undefined){
            alert("Please upload blog big image")
            document.getElementById('blogbigimage').focus()
            return false
        }
        if(blog.description === "" || blog.description === undefined){
            alert("Please enter blog description")
            document.getElementById('description').focus()
            return false
        } 
        const blogurl = blog.blogtitle.trim().replace(/\s+/g, '-').replace(/[^\w-]+/g, "").replace(/_/g, "").toLowerCase();
        console.log("blogurl", blogurl)
        blog.blogid = blogid
        const formdata = new FormData();
        formdata.append('blogid', blogid)
        formdata.append('blogimage', blog.blogimage, blog.blogimage.name)
        formdata.append('blogbigimage', blog.blogbigimage, blog.blogbigimage.name)
        formdata.append('blogcatid', blog.blogcatid)
        formdata.append('blogtitle', blog.blogtitle)
        formdata.append('short_description', blog.short_description)
        formdata.append('description', blog.description)
        formdata.append('faq', blog.faq)
        formdata.append('createdAt',date)
        formdata.append('createdBy', user)
        formdata.append('blogurl', blogurl)
        formdata.append('mtitle', blog.mtitle)
        formdata.append('mkeywords', blog.mkeywords)
        formdata.append('mdescr', blog.mdescr)
        formdata.append('blogstatus', blog.blogstatus)
        formdata.append('publishAt', startdate)
        //console.log(formdata)
        if(blog.blogtitle){

            API.post("/blog/postblog", formdata)
            .then(res => {
                alert(res.data.message);
                navigate("/blogs")
            })
        }
    }

    return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-md-8 offset-md-2'>
                        <div className="card">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Add Blog</h4>
                            </div>
                            <div class="card-body">
                                <form name='addblog' id='addblog' method='post' encType="multipart/form-data">
                                    <div className="mb-3">
                                        <label className="form-label">Category:</label>
                                        <select className="form-control" name='blogcatid' id='blogcatid' onChange={(e) => { handlechange(e); }}>
                                            <option value="">--Select Category--</option>
                                            {cate.map((cat,index) => (
                                                <option value={cat.blogcatid}>{cat.catname}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Title:</label>
                                        <input className="form-control" type="text" name='blogtitle' id='blogtitle' value={blog.blogtitle} onChange={ handlechange } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Small Image:</label>
                                        <input className="form-control" type='file' id='blogimage' name='blogimage' onChange={imghandlechange } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Big Image:</label>
                                        <input className="form-control" type='file' id='blogbigimage' name='blogbigimage' onChange={imghandlechange1 } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Short Description:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogshortdescrRef.current = editor}
                                            initialValue={blog.short_description}
                                            name="short_description"
                                            init={{
                                                height: 500,
                                                plugins:
                                                "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                              toolbar:
                                                "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                              hieght: 700,
                                              toolbar_sticky: true,
                                              icons: "thin",
                                              skin: "material-classic"
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div>
                                   <div className="mb-3">
                                        <label className="form-label">About the Blog:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogdescrRef.current = editor}
                                            initialValue={blog.description}
                                            name="description"
                                            init={{
                                                height: 500,
                                                plugins:
                                                "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                              toolbar:
                                                "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                              hieght: 700,
                                              toolbar_sticky: true,
                                              icons: "thin",
                                              skin: "material-classic"
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Title(Seo):</label>
                                        <input className="form-control" type="text" name='mtitle' id='mtitle' value={blog.mtitle} onChange={ handlechange } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Keywords(Seo):</label>
                                        <textarea className="form-control" cols={40} rows={2} name='mkeywords' id='mkeywords' onChange={ handlechange } >{blog.mkeywords}</textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Description(Seo):</label>
                                        <textarea className="form-control" cols={40} rows={10} name='mdescr' id='mdescr' onChange={ handlechange } >{blog.mdescr}</textarea>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Add Faq:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogfaqRef.current = editor}
                                            initialValue={blog.faq}
                                            name="faq"
                                            init={{
                                                height: 500,
                                                plugins:
                                                "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                              toolbar:
                                                "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                              hieght: 700,
                                              toolbar_sticky: true,
                                              icons: "thin",
                                              skin: "material-classic"
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Publish Date:</label>
                                        <Flatpickr
                                            className="form-control"
                                            data-enable-time
                                            options={{ dateFormat: "Y-m-d H:i:s", enableTime: true, enableSeconds: true, time_24hr: true }}
                                            value={startdate}
                                            onChange={([startdate]) => {
                                                setStartdate(startdate);
                                            }}
                                        />
                                    </div>                                     
                                    <div className="mb-3">
                                        <label className="form-label">Status:</label>
                                        <select className="form-control" name='blogstatus' id='blogstatus' onChange={ handlechange }>
                                            <option value="">--Select--</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                                       
                                    <div className="btn btn-primary" onClick={ addblog} >Submit</div>                         
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </React.Fragment>
    )
}

export default Addblog