import React,{useState,useEffect,useRef} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Copyblog(props){

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    
    const {blogid} = useParams()
    var timestamp = new Date().getUTCMilliseconds();
    //console.log(timestamp)
    
    const [cate, setCategory] = useState([]);
    const blogdescrRef = useRef(null);
    const blogshortdescrRef = useRef(null)
    const blogfaqRef = useRef(null)
    const [blog, setBlog] = useState({})

    useEffect(() => {
        fetchCategory()
        fetchblog()
    },[])

    const fetchCategory = async () => {
        const pcat = await API.get("/blog/blogcategorylist")
        setCategory(pcat.data.categories)
    }

    const fetchblog = async () => {
        const res =  await API.get(`/blog/blog/${blogid}`)
        setBlog(res.data.blog)
    }

    const handlechange = e => {
        const {name, value} = e.target
        setBlog({
            ...blog,[name]:value
        })
    }

    const imghandlechange = e => {
        setBlog({
            ...blog,blogimage:e.target.files[0]
        })
    }

    const imghandlechange1 = e => {
        setBlog({
            ...blog,blogbigimage:e.target.files[0]
        })
    }

    const editblog = async() => {

        if(blogshortdescrRef.current){
            blog.short_description = blogshortdescrRef.current.getContent();
        }

        if(blogdescrRef.current){
            blog.description = blogdescrRef.current.getContent();
        }
        if(blogfaqRef.current){
            blog.faq = blogfaqRef.current.getContent();
        }
        if(blog.blogcatid === "" || blog.blogcatid === undefined){
            alert("Please select blog category")
            return false
        }
        if(blog.blogtitle === "" || blog.blogtitle === undefined){
            alert("Please enter blog title")
            return false
        }
        if(blog.blogimage === "" || blog.blogimage === undefined){
            alert("Please upload blog small image")
            return false
        }
        if(blog.blogbigimage === "" || blog.blogbigimage === undefined){
            alert("Please upload blog big image")
            return false
        }
        if(blog.description === "" || blog.description === undefined){
            alert("Please enter blog description")
            return false
        }
        
        const formdata = new FormData();
        
        if(blog.blogimage.name != undefined){
            console.log(blog.blogimage)
            formdata.append('blogimage', blog.blogimage, blog.blogimage.name)
        }
        if(blog.blogbigimage.name !=undefined){
            formdata.append('blogbigimage', blog.blogbigimage, blog.blogbigimage.name)
        }
        var val = Math.floor(1000000 + Math.random() * 9000000);
        var npblogid = new Date().valueOf() + val;

        const blogurl = blog.blogtitle.replace(/[\s;',]+/g, '-').toLowerCase();
        formdata.append('cpblogid', blogid)
        formdata.append('blogid', npblogid)
        formdata.append('blogcatid', blog.blogcatid)
        formdata.append('blogtitle', blog.blogtitle)
        formdata.append('short_description', blog.short_description)
        formdata.append('description', blog.description)
        formdata.append('faq', blog.faq)
        formdata.append('updatedBy', user)
        formdata.append('blogurl', blogurl)
        formdata.append('mtitle', blog.mtitle)
        formdata.append('mkeywords', blog.mkeywords)
        formdata.append('mdescr', blog.mdescr)

        if(blog.blogtitle){
            console.log(formdata)
            API.post("/blog/copypostblog", formdata)
            .then(res => {
                alert(res.data.message);
                navigate("/blogs")
            })
        }
    }

    return(
        <React.Fragment>
            <Navbar user={user} />
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-md-8 offset-md-2'>
                        <div className="card">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Copy Blog</h4>
                            </div>
                            <div class="card-body">
                                <form name='editblog' id='editblog' method='post' encType="multipart/form-data">
                                    <div className="mb-3">
                                        <label className="form-label">Category:</label>
                                        <select className="form-control" name='blogcatid' id='blogcatid' value={blog.blogcatid} onChange={(e) => { handlechange(e); }}>
                                            <option value="">--Select Category--</option>
                                            {cate.map((cat,index) => (
                                                <option value={cat.blogcatid}>{cat.catname}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Title:</label>
                                        <input className="form-control" type="text" name='blogtitle' id='blogtitle' value={blog.blogtitle} onChange={ handlechange } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Small Image:</label>
                                        <input className="form-control" type='file' id='blogimage' name='blogimage' onChange={imghandlechange } />

                                        <div className="mb-3">
                                            <a href={`${AppConfig.BLOG_IMAGE}${blog.blogimage}`} target="_blank"><img src={`${AppConfig.BLOG_IMAGE}${blog.blogimage}`} width="20px;" height="20px;" /></a>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Blog Big Image:</label>
                                        <input className="form-control" type='file' id='blogbigimage' name='blogbigimage' onChange={imghandlechange1 } />

                                        <div className="mb-3">
                                            <a href={`${AppConfig.BLOG_IMAGE}${blog.blogbigimage}`} target="_blank"><img src={`${AppConfig.BLOG_IMAGE}${blog.blogbigimage}`} width="20px;" height="20px;" /></a>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Short Description:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogshortdescrRef.current = editor}
                                            initialValue={blog.short_description}
                                            name="short_description"
                                            init={{
                                                height: 500,
                                                plugins:
                                                "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                              toolbar:
                                                "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                              hieght: 700,
                                              toolbar_sticky: true,
                                              icons: "thin",
                                              skin: "material-classic"
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div> 
                                   <div className="mb-3">
                                        <label className="form-label">About the Blog:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogdescrRef.current = editor}
                                            initialValue={blog.description}
                                            name="description"
                                            init={{
                                                height: 500,
                                                plugins:
                                                "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                                              toolbar:
                                                "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                              hieght: 700,
                                              toolbar_sticky: true,
                                              icons: "thin",
                                              skin: "material-classic"
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Title(Seo):</label>
                                        <input className="form-control" type="text" name='mtitle' id='mtitle' value={blog.mtitle} onChange={ handlechange } />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Keywords(Seo):</label>
                                        <textarea className="form-control" cols={40} rows={2} name='mkeywords' id='mkeywords' value={blog.mkeywords} onChange={ handlechange } >{blog.mkeywords}</textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Meta Description(Seo):</label>
                                        <textarea className="form-control" cols={40} rows={10} name='mdescr' id='mdescr' value={blog.mdescr} onChange={ handlechange } >{blog.mdescr}</textarea>
                                    </div> 
                                    <div className="mb-3">
                                        <label className="form-label">Add Faq:</label>
                                        <Editor
                                            onInit={(evt, editor) => blogfaqRef.current = editor}
                                            initialValue={blog.faq}
                                            name="faq"
                                            init={{
                                                height: 500,
                                                menubar: true,
                                                plugins: [
                                                'advlist autolink lists link image', 
                                                'charmap print preview anchor help',
                                                'searchreplace visualblocks code',
                                                'insertdatetime media table paste wordcount',
                                                'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                                ],
                                                toolbar:
                                                'undo redo | formatselect | bold italic | \
                                                alignleft aligncenter alignright | \
                                                bullist numlist outdent indent | help'
                                            }} 
                                            onChange={ handlechange }
                                        />
                                    </div>                   
                                    <div className="btn btn-primary" onClick={ editblog} >Submit</div>                         
                                </form>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </React.Fragment>
    )    
}

export default Copyblog