import "./App.css";
import Homepage from "./Components/Homepage/Homepage";
import Register from "./Components/Register/Register";
import Login from "./Components/Login/Login";
import Categories from "./Components/Category/Categories";
import Addcategory from "./Components/Category/Addcategory";
import Vendors from "./Components/Vendor/Vendors";
import Addvendor from "./Components/Vendor/Addvendor";
import Games from "./Components/Vendor/Games";
import TagGames from "./Components/Vendor/TagGames";
import AddGameInTag from "./Components/Vendor/AddGameInTag";
import Addgame from "./Components/Vendor/Addgame";
import Banner from "./Components/Banner/Banner";
import Addbanner from "./Components/Banner/Addbanner";
import Managelistcategories from "./Components/Pagemanage/Managelistcategories";
import Managelistbanner from "./Components/Pagemanage/Managelistbanner";
import Managecategory from "./Components/Pagemanage/Managecategory";
import Managebanner from "./Components/Pagemanage/Managebanner";
import Editcategory from "./Components/Category/Editcategory";
import Editsubcategory from "./Components/Category/Editsubcategory";
import Editvendor from "./Components/Vendor/Editvendor";
import Editbanner from "./Components/Banner/Editbanner";
import Editgame from "./Components/Vendor/Editgame";
import Contest from "./Components/Vendor/Contest";
import Addcontest from "./Components/Vendor/Addcontest";
import Editcontest from "./Components/Vendor/Editcontest";
import Blog from "./Components/Blog/Blog";
import Addblog from "./Components/Blog/Addblog";
import Editblog from "./Components/Blog/Editblog";
import Addblogcategory from "./Components/Blog/Addblogcategory";
import Editblogcategory from "./Components/Blog/Editblogcategory";
import Blogcategory from "./Components/Blog/Blogcategory";
import Content from "./Components/Content/Content";
import Addcontent from "./Components/Content/Addcontent";
import Editcontent from "./Components/Content/Editcontent";
import Reviews from "./Components/Reviews/Reviews";
import Addreview from "./Components/Reviews/Addreview";
import Helpdesk from "./Components/Helpdesk/Helpdesk";
import Hepldeskreply from "./Components/Helpdesk/Hepldeskreply";
import Blogimage from "./Components/Blogimage/Blogimage";
import Addimage from "./Components/Blogimage/Addimage";
import Editimage from "./Components/Blogimage/Editimage";
import Reels from "./Components/Reels/Reels";
import Addreels from "./Components/Reels/Addreels";
import Editreels from "./Components/Reels/Editreels";
import Copyblog from "./Components/Blog/Copyblog";
import Copygame from "./Components/Vendor/Copygame";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Winners from "./Components/Winners/Winners";
import AddWinner from "./Components/Winners/AddWinners";
import Json from "./Components/Cron/json";
import {
  Users,
  Registrationpostback,
  Gameplay,
  Transactions,
  Transactionspostback,
  UserDetail,
} from "./Components/FullAnalytics";
import {
  AddFaq,
  AddPage,
  EditFaq,
  EditPage,
  ManageFaqs,
  ManagePages,
} from "./Components/Pages";
import {
  Tags,
  AddTag,
  EditTag,
  CategoriesNew,
  AddCategory,
  EditNewCategory,
} from "./Components/GameManager";

function App() {
  const user = localStorage.getItem("username");
  if (!user) {
    return <Login />;
  }
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/addcategory" element={<Addcategory />} />
          {/* <Route path="/vendors" element={<Vendors />} /> */}
          <Route path="/vendors" element={<Vendors />} />
          <Route path="/addvendor" element={<Addvendor />} />
          {/* <Route path="/games" element={<Games />} /> */}
          <Route path="/games/:vendorid" element={<Games />} />
          <Route path="/games-tag/:tagid" element={<TagGames />} />
          <Route path="/add-new-game-in-tag/:tagid" element={<AddGameInTag />} />
          <Route path="/addgame" element={<Addgame />} />
          <Route path="/banners" element={<Banner />} />
          <Route path="/addbanner" element={<Addbanner />} />
          <Route
            path="/managwlistcategories"
            element={<Managelistcategories />}
          />
          <Route path="/managwlistbanners" element={<Managelistbanner />} />
          <Route path="/managebanners" element={<Managebanner />} />
          <Route path="/managecategories" element={<Managecategory />} />
          <Route path="/edit-category/:catid" element={<Editcategory />} />
          <Route
            path="/edit-sub-category/:catid/:subcatid"
            element={<Editsubcategory />}
          />
          <Route path="/edit-vendor/:vid" element={<Editvendor />} />
          <Route path="/edit-banner/:bid" element={<Editbanner />} />
          <Route path="/edit-game/:gid" element={<Editgame />} />
          {/* <Route path="/contest" element={<Contest />} /> */}
          <Route path="/contest/:vendorid/:gameid" element={<Contest />} />
          <Route path="/addcontest/:vendorid/:gameid" element={<Addcontest />} />
          <Route path="/edit-contest/:vendorid/:gameid/:conid" element={<Editcontest />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/addblog" element={<Addblog />} />
          <Route path="/edit-blog/:blogid" element={<Editblog />} />
          <Route path="/blogcategory" element={<Blogcategory />} />
          <Route path="/addblogcategory" element={<Addblogcategory />} />
          <Route
            path="/edit-blogcategory/:blogcatid"
            element={<Editblogcategory />}
          />
          <Route path="/content" element={<Content />} />
          <Route path="/addcontent" element={<Addcontent />} />
          <Route path="/edit-content/:contentid" element={<Editcontent />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/addreview" element={<Addreview />} />
          <Route path="/helpdesk" element={<Helpdesk />} />
          <Route path="/manage-faqs" element={<ManageFaqs />} />
          <Route path="/manage-pages" element={<ManagePages />} />
          <Route path="/add-faq" element={<AddFaq />} />
          <Route path="/edit-faq/:faqid" element={<EditFaq />} />
          <Route path="/add-page" element={<AddPage />} />
          <Route path="/edit-page/:pageid" element={<EditPage />} />
          <Route
            path="/helpdesk/reply/:helpdeskid"
            element={<Hepldeskreply />}
          />
          <Route path="/users" element={<Users />} />
          <Route path="/user-detail" element={<UserDetail />} />
          <Route path="/user-detail/:userid" element={<UserDetail />} />
          <Route
            path="/registration-postback"
            element={<Registrationpostback />}
          />
          <Route path="/game-play" element={<Gameplay />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route
            path="/transactions-postback"
            element={<Transactionspostback />}
          />
          <Route path="/blogimage" element={<Blogimage />} />
          <Route path="/addimage" element={<Addimage />} />
          <Route path="/edit-image/:bid" element={<Editimage />} />
          <Route path="/reels" element={<Reels />} />
          <Route path="/addreel" element={<Addreels />} />
          <Route path="/edit-reel/:reelsid" element={<Editreels />} />
          <Route path="/copy-blog/:blogid" element={<Copyblog />} />
          <Route path="/winners" element={<Winners />} />
          <Route path="/addwinner" element={<AddWinner />} />
          <Route path="/manage-tags" element={<Tags />} />
          <Route path="/addtag" element={<AddTag />} />
          <Route path="/edittag/:id" element={<EditTag />} />
          <Route path="/manage-categories" element={<CategoriesNew />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/edit-newcategory/:id" element={<EditNewCategory />} />
          <Route path="/copy-game/:gid" element={<Copygame />} />
          <Route path="/json" element={<Json />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
