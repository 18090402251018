import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Editcategory(props) {

    const {catid} = useParams()
    
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [categories, setItems] = useState([]);
    const [caturl, setCaturl] = useState([])
    const editorRef = useRef(null);
  
   const [cat, setCategory] = useState({})

   useEffect(() => {
    fetchData()
    fetchCategoryData()
   },[])

    const handlechange = e => {
        const {name, value} = e.target
        setCategory({
            ...cat,[name]:value
        })
        if(cat.catname !="" && cat.catname !=undefined){
            const expcat = cat.catname.replace(/\s+/g, '-');
            setCaturl(expcat)
        }
    }

    const imghandlechange = e => {
        setCategory({
            ...cat,catimage:e.target.files[0]
        })
    }
   
    const fetchData = async () => {
      const res =  await API.get(`/category/parentcategory`)
      //console.log(res.data.caregories)
      setItems(res.data.categories)
      
   }

    const fetchCategoryData = async () => {
        const res =  await API.get(`/category/category/${catid}`)
        console.log(res.data.category)
        setCategory(res.data.category)
        setCaturl(res.data.category.caturl)
        console.log(res.data.category.caturl)
    }

   const editcategory = () => {

        if(cat.catname == "" || cat.catname == undefined){
            alert("Please enter category name")
            return false
        }
        if(cat.mtitle == "" || cat.mtitle == undefined){
            alert("Please enter category meta title")
            return false
        }
        if(cat.mkeywords == "" || cat.mkeywords == undefined){
            alert("Please enter category meta keywords")
            return false
        }
        if(cat.mdescr == "" || cat.mdescr == undefined){
            alert("Please enter category meta description")
            return false
        }
        if(editorRef.current == "" || editorRef.current == undefined){
            alert("Please enter category description")
            return false
        }
        if (editorRef.current) {
            cat.fdescr = editorRef.current.getContent();
        }
        
        if(cat.patcategory == undefined){

            var parentcat ="parent";
        }else{
            var parentcat = cat.patcategory;
        }
    
        const formdata = new FormData();
        
        if(cat.catimage){
            formdata.append('catimage', cat.catimage, cat.catimage.name)
        }

        formdata.append('catname', cat.catname)
        formdata.append('patcategory', parentcat)
        formdata.append('mtitle', cat.mtitle)
        formdata.append('mkeywords', cat.mkeywords)
        formdata.append('mdescr', cat.mdescr)
        formdata.append('fdescr', cat.fdescr)
        formdata.append('caturl', caturl)
        formdata.append('catid', catid)
        formdata.append('updatedBy', email)

        if(cat.catname){
            //console.log(cat)
            API.post("/category/editcategory", formdata)
            .then(res => {
                alert(res.data.message);
               navigate("/categories")
            })
        }else{
            alert("Please enter category name")
        }
   }
   
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Category</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Parent Category:</label>
                                <select className="form-control" name="patcategory" id="patcategory" onChange={ handlechange } value={cat.catid}>
                                    <option value="">--Select Parent Category--</option>
                                    {categories.map((cat,index) => (
                                        <option value={cat.catid}>{cat.catname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Category Icon:</label>
                                <input className="form-control" type='file' id='catimage' name='catimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.CATEGORY_IMAGE_NAME}${cat.categoryimage}`} target="_blank"><img src={`${AppConfig.CATEGORY_IMAGE_NAME}${cat.categoryimage}`} width="50px;" height="50px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Category Name:</label>
                                <input className="form-control" type="text" name='catname' id='catname' value={cat.catname} onChange={ handlechange } />
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Frontend Description:</label>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={cat.fdescr}
                                    name="practice"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help hr',
                                            'searchreplace visualblocks wordcount code fullscreen',
                                            'insertdatetime media table paste wordcount template',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                        
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Category Url:</label>
                                <input className="form-control" type="text" name='caturl' id='caturl' value={caturl} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Title(Seo):</label>
                                <input className="form-control" type="text" name='mtitle' id='mtitle' value={cat.mtitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Keywords(Seo):</label>
                                <textarea className="form-control" cols={40} rows={2} name='mkeywords' value={cat.mkeywords} id='mkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Description(Seo):</label>
                                <textarea className="form-control" cols={40} rows={10} name='mdescr' id='mdescr' onChange={ handlechange } value={cat.mdescr} ></textarea>
                            </div>
                            <div className="btn btn-primary" onClick={ editcategory} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Editcategory