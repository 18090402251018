import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
  
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'light',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function Copygame(props) {
    
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const {gid} = useParams()

    //const user = props.Users;
    const [vendor, setVendor] = useState([]);
    const [cate, setPCategory] = useState([]);
    const [subcat, setSubcategory] = useState([]);
    const [games, setSimilarGames] = useState([])
    const [date, setDate] = useState(new Date());
    const [expirydate, setExpirydate] = useState(new Date());
    const [gameurl, setGameurl] = useState([])
    const [gametypes, setGametype] = useState([])
    const [caturl, setCaturl] = useState([])
    const [catsid, setCatid] = useState([])
    const [subcaturl, setSubcaturl] = useState([])
 
    const editorRef = useRef(null);
    const gamedescrRef = useRef(null);
    const gameruleRef = useRef(null);
    const howtoplayRef = useRef(null);
    const gamectrlRef = useRef(null);
    const [catGameurl, setCatGameUrl] = useState();
    const [catsubGameurl, setSubCatGameUrl] = useState();
    const [cat, setGames] = useState({});

   useEffect(() => {
     fetchvendor()
     fetchParentcat()
     fetchgamedata()
     fetchgametype()
   },[])

    const fetchvendor = async () => {
        const res = await API.get("/vendor/vendorlist")
        setVendor(res.data.vendors)
    }

    const fetchParentcat = async () => {
        const pcat = await API.get("/category/parentcategory")
        setPCategory(pcat.data.categories)
        
    }

    const fetchgametype = async() => {
        const gtype = await API.get("/games/gamestypes")
        setGametype(gtype.data.gametypes)
    }
    
    const getsubcat = async (e) => {
        
        const {name, value} = e.target
        var qry = {"catid":value}
        const subcatdata = await API.post("/category/childcategory", qry)
       // console.log(subcatdata.data.subcategories.subcategories)
        setSubcategory(subcatdata.data.subcategories.subcategories)
        setCatid(value)
        setCaturl(subcatdata.data.subcategories.caturl)
    }

    const getsubcatdetails = async(e) => {
        const {value} = e.target
        const subcatdetails = await API.get("/category/category/" + catsid + "/" + value)
        //console.log(subcatdetails.data.category.subcategories[0].subcaturl)
        setSubcaturl(subcatdetails.data.category.subcategories[0].subcaturl)
    }

    const getGames = async(e) => {
        const {name, value} = e.target
        const qry = {"catid": value}
        const result = await API.post("/games/categorygames/", qry)
        //console.log(result.data.games.gamedate)
        setSimilarGames(result.data.games)
        
    }

    const onDragEnd = (result)  =>{
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const items = reorder(
         games,
          result.source.index,
          result.destination.index
        );
        //console.log(items)
        setSimilarGames(items)
    }


    const fetchgamedata = async () => {

        const res =  await API.get(`/games/game/${gid}`)
        var qry = {"catid":res.data.game.categoryid}
        const subcatdata = await API.post("/category/childcategory", qry)
        //console.log(subcatdata)

        if(subcatdata.data.subcategories){
            setSubcategory(subcatdata.data.subcategories.subcategories)
        }
        console.log(res.data.game)
        setGames(res.data.game)
        setSimilarGames(res.data.game.similargames)
        //setDate(res.data.game.gamedate)
        //setExpirydate(res.data.game.expirydate)
        setCatGameUrl(res.data.game.caturl)
        setSubCatGameUrl(res.data.game.subcaturl)
        setGameurl(res.data.game.gurl)
    }
   
    const handlechange = e => {
        const {name, value} = e.target
        //console.log(e.target.value)
        setGames({
            ...cat,[name]:value
        })
        if(cat.gtitle !="" && cat.gtitle !=undefined){
            const expcat = cat.gtitle.replace(/\s+/g, '-');
            const finalurl = expcat.toLowerCase()
            setGameurl(finalurl)
        }
    }

    const imghandlechange = e => {
        setGames({
            ...cat,homepimage:e.target.files[0]
        })
    }

    const imghandlechange1 = e => {
        setGames({
            ...cat,listpimage:e.target.files[0]
        })
    }

    const imghandlechange2 = e => {
        setGames({
            ...cat,detailpimage:e.target.files[0]
        })
    }

    const editgame = () => {

        if (editorRef.current) {
            cat.practice = editorRef.current.getContent();
        }
        if(gamedescrRef.current){
            cat.gdescr = gamedescrRef.current.getContent();
        }
        if(gameruleRef.current){
            cat.grule = gameruleRef.current.getContent();
        }
        if(howtoplayRef.current){
            cat.ghowtoplay = howtoplayRef.current.getContent();
        }
        if(gamectrlRef.current){
            cat.gcontrols = gamectrlRef.current.getContent();
        }

        //form validation
        if(cat.vendorid ==""){
            alert("Please select vendor")
            return false;
        }
        if(cat.gametype === "" || cat.gametype === undefined){
            alert("Please select game type")
            return false;
        }
        if(cat.categoryid === ""){
            alert("Please select category")
            return false;
        }
        if(cat.subcategoryid === ""){
            alert("Please select sub category")
            document.getElementById('subcategoryid').focus()
            return false;
        }
        if(cat.gtitle === ""){
            alert("Please enter game title")
            return false;
        }
        if(cat.homepimage === ""){
            alert("Please upload home page image")
            return false;
        }
        if(cat.listpimage === ""){
            alert("Please upload listing page image")
            return false;
        }
        if(cat.detailpimage === ""){
            alert("Please upload detail page image")
            return false;
        }
        if(cat.grating === ""){
            alert("Please enter game rating")
            return false;
        }
        if(cat.glike === ""){
            alert("Please enter game like")
            return false;
        }
        if(cat.gdislike === ""){
            alert("Please enter game dislike")
            return false;
        }
        if(cat.playurl === ""  || cat.playurl === undefined){
            alert("Please enter game url")
            return false;
        }
        if(cat.gdescr === ""){
            alert("Please enter game description")
            return false;
        }
        if(cat.grule === ""){
            alert("Please enter game rule")
            return false;
        }
        if(cat.ghowtoplay === ""){
            alert("Please enter game how to play")
            return false;
        }
        if(cat.gcontrols === ""){
            alert("Please enter game control")
            return false;
        }
        if(cat.mtitle === ""){
            alert("Please enter game meta title")
            return false;
        }
        if(cat.mkeywords === ""){
            alert("Please enter game meta keywords")
            return false;
        }
        if(cat.mdescr === ""){
            alert("Please enter game meta description")
            return false;
        }
        
        var gameid = gid
        const gamedate = date;
        const gameexpirydate = expirydate
        
        const formdata = new FormData();
        
        if(cat.homepimage){
            formdata.append('homepimage', cat.homepimage, cat.hpimage.name)
        }
        if(cat.listpimage){
            formdata.append('listpimage', cat.listpimage, cat.lpimage.name)
        }
        if(cat.detailpimage){
            formdata.append('detailpimage', cat.detailpimage, cat.dpimage.name)
        }
        if(caturl != "" && caturl != undefined){
            var gcaturl = caturl.toLowerCase()
            
        }else{
            var gcaturl = catGameurl.toLowerCase()
        }
        
        formdata.append('gametype', cat.gametype)
        formdata.append('gameid', gameid)
        formdata.append('vendorid', cat.vendorid)
        formdata.append('categoryid', cat.categoryid)
        formdata.append('subcategoryid', cat.subcategoryid)
        formdata.append('mtitle', cat.mtitle)
        formdata.append('mkeywords', cat.mkeywords)
        formdata.append('mdescr', cat.mdescr)
        formdata.append('gtitle', cat.gtitle)
        formdata.append('grating', cat.grating)
        formdata.append('glike', cat.glike)
        formdata.append('gdislike', cat.gdislike)
        formdata.append('gdescr', cat.gdescr)
        formdata.append('grule', cat.grule)
        formdata.append('ghowtoplay', cat.ghowtoplay)
        formdata.append('gcontrols', cat.gcontrols)
        formdata.append('gamedate', gamedate)
        formdata.append('expirydate', gameexpirydate)
        formdata.append('caturl', gcaturl)
        formdata.append('gurl', gameurl.toLowerCase())
        formdata.append('playurl', cat.playurl)
        formdata.append('similarcatid', cat.similarcatid)
        //formdata.append('similargames', JSON.stringify(games))
        formdata.append('updatedBy', email)
        formdata.append('display_app', cat.display_app)
        formdata.append('utm_param', cat.utm_param)
        formdata.append('rss_display', cat.rss_display)

        if(cat.vendorid){
            
            API.post("/games/copypostgames", formdata)
            .then(res => {
                alert(res.data.message);
                navigate("/games")
            })
        }else{
            alert("Please enter category name")
        }
    }
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Game</h4>
                        </div>
                        <div class="card-body">
                            <div className="mb-3">
                                <label className="form-label">Vendor Name:</label>
                                <select className="form-control" name='vendorid' id='vendorid' value={cat.vendorid} onChange={ handlechange }>
                                    <option value="">--Select Vendor--</option>
                                    { vendor.map((rows,index) => (
                                        <option value={rows.vendorid}>{rows.vendorname}</option>     
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                    <label className="form-label">Game Type:</label>
                                    <select className="form-control" name='gametype' id='gametype' value={cat.gametype} onChange={ handlechange }>
                                        <option value="">--Select Type--</option>
                                        {gametypes.map((arrtype,index) => (
                                            <option value={arrtype.typeid}>{arrtype.typename}</option>
                                        ))}
                                    </select>
                                </div>
                            <div className="mb-3">
                                <label className="form-label">Category:</label>
                                <select className="form-control" name='categoryid' id='categoryid' value={cat.categoryid} onChange={(e) => { getsubcat(e); handlechange(e); }}>
                                    <option value="">--Select Category--</option>
                                    {cate.map((cat,index) => (
                                        <option value={cat.catid}>{cat.catname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Sub Category:</label>
                                <select className="form-control" name='subcategoryid' id='subcategoryid' value={cat.subcategoryid} onChange={(e) => {getsubcatdetails(e);handlechange(e) }}>
                                    <option value="">--Select Sub Category--</option>
                                    { subcat.map((subcat,index) => (
                                        <option value={subcat.subcatid}>{subcat.subcatname}</option>     
                                    ))}
                                </select>
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Game Title:</label>
                                <input className="form-control" type="text" name='gtitle' id='gtitle' value={cat.gtitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Onboarding Date:</label>
                                <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date} onChange={date => setDate(date)} />
                            </div>
                            <div className="mb-3">
                                    <label className="form-label">Expiry Date:</label>
                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={expirydate} onChange={expirydate => setExpirydate(expirydate)} />
                                </div>
                            <div className="mb-3">
                                <label className="form-label">Home Page Image:</label>
                                <input className="form-control" type='file' id='homepimage' name='homepimage' onChange={imghandlechange } />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.GAME_IMAGE_NAME}${cat.hpimage}`} target="_blank"><img src={`${AppConfig.GAME_IMAGE_NAME}${cat.hpimage}`} width="50px;" height="50px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Listing Page Image:</label>
                                <input className="form-control" type='file' id='listpimage' name='listpimage' onChange={imghandlechange1 } />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.GAME_IMAGE_NAME}${cat.lpimage}`} target="_blank"><img src={`${AppConfig.GAME_IMAGE_NAME}${cat.lpimage}`} width="50px;" height="50px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Details Page Image:</label>
                                <input className="form-control" type='file' id='detailpimage' name='detailpimage' onChange={imghandlechange2 } />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.GAME_IMAGE_NAME}${cat.dpimage}`} target="_blank"><img src={`${AppConfig.GAME_IMAGE_NAME}${cat.dpimage}`} width="20px;" height="20px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Rating:</label>
                                <input className="form-control" type="text" name='grating' id='grating' value={cat.grating} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Like:</label>
                                <input className="form-control" type="text" name='glike' id='glike' value={cat.glike} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Dislike:</label>
                                <input className="form-control" type="text" name='gdislike' id='gdislike' value={cat.gdislike} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Detail URL:</label>
                                <input className="form-control" type="text" name='gurl' id='gurl' value={gameurl} onChange={ handlechange } />
                            </div>
                            <div className="mb-3"> 
                                    <label className="form-label">Game Url:</label>
                                    <span><input className="form-control" type="text" name='playurl' id='playurl' value={cat.playurl} onChange={ handlechange } /></span>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">About the Game:</label>
                                <Editor
                                    onInit={(evt, editor) => gamedescrRef.current = editor}
                                    initialValue={cat.gdescr}
                                    name="gdescr"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Rule:</label>
                                <Editor
                                    onInit={(evt, editor) => gameruleRef.current = editor}
                                    initialValue={cat.grule}
                                    name="grule"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">How To Play:</label>
                                <Editor
                                    onInit={(evt, editor) => howtoplayRef.current = editor}
                                    initialValue={cat.ghowtoplay}
                                    name="ghowtoplay"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Game Controls:</label>
                                <Editor
                                    onInit={(evt, editor) => gamectrlRef.current = editor}
                                    initialValue={cat.gcontrols}
                                    name="gcontrols"
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image', 
                                            'charmap print preview anchor help',
                                            'searchreplace visualblocks code',
                                            'insertdatetime media table paste wordcount',
                                            'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic | \
                                            alignleft aligncenter alignright | \
                                            bullist numlist outdent indent | help'
                                        }} 
                                        onChange={ handlechange }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Title(Seo):</label>
                                <input className="form-control" type="text" name='mtitle' id='mtitle' value={cat.mtitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Keywords(Seo):</label>
                                <textarea className="form-control" cols={40} rows={2} value={cat.mkeywords} name='mkeywords' id='mkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Meta Description(Seo):</label>
                                <textarea className="form-control" cols={40} rows={10} value={cat.mdescr} name='mdescr' id='mdescr' onChange={ handlechange } >{cat.mdescr}</textarea>
                            </div>
                            <h5 className='mt-5'>Similar Games</h5>
                            <div className="mb-3">
                                <select className="form-control" name="similarcatid" id="similarcatid" value={cat.similarcatid} class="form-control" onChange={ (e) =>{ getGames(e); handlechange(e); }}>
                                    <option value="">--Select Category--</option>
                                    {cate.map((row,index) => (
                                        <option value={row.catid}>{row.catname}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Display in App:</label>
                                <select className="form-control" name='display_app' id='display_app' value={cat.display_app} onChange={ handlechange }>
                                    <option value="">--Select--</option>
                                    <option value="y">Yes</option>
                                    <option value="n">No</option>
                                </select>
                                
                            </div>
                            <div className="mb-3">
                                <label className="form-label">UTM:</label>
                                <input className="form-control" type='text' id='utm_param' name='utm_param' value={cat.utm_param } onChange={handlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Rss Display:</label>
                                <select className="form-control" name='rss_display' value={cat.rss_display} id='rss_display' onChange={ handlechange }>
                                    <option value="">--Select--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                            {/*<div className="mb-3">
                                <label className="form-label">Sorting:</label>
                                <DragDropContext name="gameimg" id="gameimg" onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                    {(provided, snapshot) => (
                                        <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                        >
                                        {games.map((item, index) => (
                                            
                                            <Draggable key={item._id} draggableId={item._id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                >
                                                <img src={`${AppConfig.GAME_IMAGE_NAME}${item.lpimage}`} width="100px;" height="80px;" />
                                                <br /><label class="text-white bg-dark mt-md-2">{item.gtitle}</label>
                                                </div>
                                                
                                            )}
                                            
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                            </div>*/}
                            <div className="btn btn-primary" onClick={ editgame} >Submit</div> 
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    </React.Fragment>
  )
}

export default Copygame