import React, {useState,useEffect} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Editimage(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
  const {bid} = useParams()

  useEffect(() => {
    fetchBannerData()
   },[])

    const [cat, setBanner] = useState({})

    const handlechange = e => {
        const {name, value} = e.target
        setBanner({
            ...cat,[name]:value
        })
        
    }

    const imghandlechange = e => {
        setBanner({
            ...cat,bimage:e.target.files[0]
        })
    }
   
    const fetchBannerData = async () => {
      const res =  await API.get(`/blogimage/blogimage/${bid}`)
      console.log(res.data.banner)
      setBanner(res.data.banner)
    }

    const editbanner = () => {

      var imageid = bid;

      const formdata = new FormData();
      if(cat.bimage){
        formdata.append('bimage', cat.bimage, cat.bimage.name)
      }
      
      formdata.append('imageid', imageid)
      formdata.append('title', cat.title)
      formdata.append('blogimageurl', cat.blogimageurl)
      formdata.append('status', cat.status)
      formdata.append('updatedBy', email)
      
      if(cat.title){
          //console.log(cat)
          API.post("/blogimage/editblogimage", formdata)
          .then(res => {
              alert(res.data.message);
              navigate("/blogimage")
          })
      }else{
          alert("Please upload banner")
      }
   }
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Blog Image</h4>
                        </div>
                        <div class="card-body">
                            <div className="mb-3">
                                <label className="form-label">Title:</label>
                                <input className="form-control" type="text" name='title' id='title' value={cat.title} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner Image:</label>
                                <input className="form-control" type='file' id='bimage' name='bimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.BLOG_IMAGE}${cat.blogimage}`} target="_blank"><img src={`${AppConfig.BLOG_IMAGE}${cat.blogimage}`} width="50px;" height="50px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='status' value={cat.status} id='status' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="btn btn-primary" onClick={ editbanner} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Editimage