import React, {useState, useEffect} from 'react';
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Loader from '../Helper/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment/moment';
import { ADMIN_REPORT_API_URL, BASIC_TOKEN } from '../../constant'


const Users = () => {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
      window.location.href="/login"
        ///navigate("/login")
    } 

  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false)
  const [downloadReport, setDownloadReport] = useState()       
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [affiliateData, setSource] = useState()
  const [mediumsData, setMediumsData] = useState()
  const [campaignsData, setCampaignsData] = useState()
  const [selectedValue, setSelectedValue] = useState({
      dateRange: [new Date(), new Date()],
      source: "",
      medium: "",
      campaign: ""
    })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)    
  const [getUsers, setGetUsers] = useState()
  const [filterUser, setFilterUser] = useState()

  useEffect(() => {
    fetchSource();
    handleFiltersSubmit()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  //Fetch Source
  const fetchSource = async () => {
    var auth_code = {
      'Authorization': BASIC_TOKEN
    }
      await axios.get(`${ADMIN_REPORT_API_URL}/reports/get-source`, {
        headers: auth_code
    })
      .then(response => {
        //console.log("affiliateData response", response)
        if(response.data.success === true && response.data.code === 2000){
          setSource(response.data.data.affiliateData)
        }
      })
      .catch(error => {
          console.error('There was an error!', error);
      });
  }

    //Source Change Handler
    const handleChangeSource = (e) => {
      let mediumArray = []
      let campaignArray = []
      const { name, value } = e.target;
      if(value === "organic"){
        mediumArray.push({medium: value})
        setMediumsData(mediumArray);
        campaignArray.push({campaign: value})
        setCampaignsData(campaignArray);  
      }
      else if(value !== undefined && value !== ''){
        fetchFromSource(value);
      }      
    }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setSelectedValue((prevState) => ({...prevState, [name]:value}))
    }  
    const handleChangeUserId = (e) => {
      const { name, value } = e.target;
      setFilterUser((prevState) => ({...prevState, [name]:value}))
    }       
    const handlePageChange = (e) => {
      setCurrentPage(e.target.value)
    }

    //Fetch from source
    const fetchFromSource = async (source) => {
      var auth_code = {
        'Authorization': BASIC_TOKEN
      }
        await axios.get(`${ADMIN_REPORT_API_URL}/reports/get-campaign/${source}`, {
          headers: auth_code
      })
        .then(response => {
          //console.log("campaign", response)
          if(response.data.success === true && response.data.code === 2000){
            setMediumsData(response.data.data.mediums)
            setCampaignsData(response.data.data.campaigns)
          }
        })
        .catch(error => {
            console.error('There was an error!', error);
        });      
    }

    console.log("userIdentity", filterUser);

    // Handle Filter
    const handleFiltersSubmit = async () => {
      setLoader(true)      
      var url;

      var auth_code = {
        'Authorization': BASIC_TOKEN
      }
      const startDate = moment(selectedValue.dateRange[0]).format("YYYY-MM-DD")
      const endDate = moment(selectedValue.dateRange[1]).format("YYYY-MM-DD")

      if(filterUser && filterUser.userIdentity !== undefined && filterUser.userIdentity !== null && filterUser.userIdentity !== ''){
        url = ADMIN_REPORT_API_URL+'/reports/get-user?userIdentity='+filterUser.userIdentity.trim();
      }else {
        url = ADMIN_REPORT_API_URL+'/reports/get-user?startDate='+startDate+'&endDate='+endDate+'&source='+selectedValue.source+'&medium='+selectedValue.medium+'&campaign='+selectedValue.campaign+'&page='+currentPage;
      }

        var downloadUrl = ADMIN_REPORT_API_URL+'/exports/user?startDate='+startDate+'&endDate='+endDate+'&source='+selectedValue.source+'&medium='+selectedValue.medium+'&campaign='+selectedValue.campaign;

        // console.log("filterUser", url);
        // console.log("url", url);
        //console.log("downloadUrl", downloadUrl);
        
        await axios.get(url, {
          headers: auth_code,
      })
        .then(response => {
            //console.log("user data response", response)
          if(response.data.success === true && response.data.code === 2000){
            //console.log("user datadatadata response", response.data.data)
            setGetUsers(response.data.data)
            setTotalPages(response.data.data.totalPages)
            setDownloadReport(downloadUrl)
            setFilterUser();
          }else{
            setGetUsers(null)
            setTotalPages(0)
          }
          setTimeout(()=> {
            setLoader(false) 
          },2000)

        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    }  
    
    const totalPagesFunc = () => {
      const pages = []
      for (let i = 1; i <= totalPages && totalPages.length; i++) {
        pages.push(i)
      } 
      return pages;
    }

    return(
        <React.Fragment>
            <Navbar user={user} />
            <div className="container-fluid pt-4 cFormLabel">
                <div className='row' style={{marginBottom: 20}}>
                  <div className='col-xl-4 col-md-4'>
                      <label className="form-label">Date Range Picker:</label>
                      <DatePicker
                      className='form-control'
                      dateFormat="yyyy-MM-dd"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                          setDateRange(update);
                          setSelectedValue((prevState) => ({...prevState, dateRange: update}))
                          setCurrentPage(1)
                      }}
                      maxDate={new Date()}
                      />
                  </div>
                  <div className='col-xl-2 col-md-2'>
                      <label className="form-label">Source:</label>
                      <select 
                      className='form-control'
                      name='source'
                      onChange={(e) => {
                          handleChangeSource(e);
                          handleChange(e);                   
                      }}
                      >
                      <option selected disabled>Select Source</option>
                      <option value="organic">Organic</option>
                      {affiliateData && affiliateData.map((affiliate, index) => (
                          <option value={affiliate.source}>{affiliate.source}</option>
                      ))}                  
                      </select>
                  </div>
                  <div className='col-xl-2 col-md-2'>
                      <label className="form-label">Medium:</label>
                      <select className='form-control'
                      name='medium'
                      onChange={(e) => {
                          handleChange(e);
                      }}
                      >
                      <option selected disabled>Select Medium</option>
                      {mediumsData && mediumsData.map((mediumList, index) => (
                          <option value={mediumList.medium}>{mediumList.medium}</option>
                      ))}
                      </select>              
                  </div>
                  <div className='col-xl-2 col-md-2'>
                      <label className="form-label">Campaign:</label>
                      <select className='form-control'
                      name='campaign'
                      onChange={(e) => {
                          handleChange(e);
                      }}
                      >
                      <option selected disabled>Select Campaign</option>
                      {campaignsData && campaignsData.map((campaignList, index) => (
                          <option value={campaignList.campaign}>{campaignList.campaign}</option>
                      ))}                  
                      </select>              
                  </div>
                  <div className='col-xl-2 col-md-2'>
                      <label className="form-label d-block"> &nbsp;</label>
                      <button className='btn btn-primary btn-block' onClick={(e) => {
                          handleFiltersSubmit(e);
                          setShow(true)
                      }}>Filter</button> 
                      {show && <a href={downloadReport} target="_blank" rel="noopener noreferrer"s className='btn btn-primary btn-block'>Download Report</a>}                                 
                  </div>            
                </div>
                <div className='row' style={{marginBottom: 20}}>
                  <div className='col-xl-4 col-md-4'>
                      <label className="form-label">Find User By Id:</label>
                      <input 
                        type="text" 
                        name='userIdentity'
                        className='form-control'
                        onChange={(e) => {
                          handleChangeUserId(e);
                          setCurrentPage(1)
                        }} 
                      />                    
                  </div>
                  <div className='col-xl-2 col-md-2'>
                      <label className="form-label d-block"> &nbsp;</label>
                      <button className='btn btn-primary btn-block' onClick={(e) => {
                          handleFiltersSubmit(e);
                          setShow(true)
                      }}>Search</button>                     
                  </div>
                </div>
                <div className="row">
                    <div className="card col-md-12 col-lg-12">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Users Detail</h4>
                            {/* <div className="flex-shrink-0">
                                <a href='/addreel' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                            </div> */}
                        </div>
                        <div className='card-body p-0'>
                                 
                                <div className="table-responsive">
                                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                    <thead className='text-muted table-light' style={{width: "100%"}}>
                                        <tr>
                                            <th>userId</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Reg Date</th>
                                            <th></th>
                                            {/* <th className='text-right'>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {loader ? (<tr><td colSpan={12}><Loader /></td></tr>) : <></>}
                                    {getUsers ? <>
                                      {!loader && getUsers.userData.map((row,index) => (
                                        <tr key={index}>        
                                            <td>{row.userId ? row.userId : ""}</td>
                                            <td>{row.name ? row.name : " "}</td>
                                            <td>{row.mobile}</td>
                                            <td>{row.email ? row.email : ""}</td>
                                            <td>{row.registrationDate}</td>
                                            <td>
                                            
                                              <Link to={`/user-detail/${row.userId ? row.userId : ""}`} class="edit btn btn-soft-secondary btn-sm" title="View Details" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEye } /></Link>
                                            </td>            
                                        </tr>
                                    ))}</>
                                    : <>{!loader ? <tr>
                                      <td colSpan={12}><div className='text-center'>No Record Found</div></td>
                                      </tr> : <></>}</>
                                    }
                                    </tbody>
                                </table>
                            </div>                            
                        </div>
                    </div> 
                    <div className='col-md-12 col-lg-12 text-right'>
                      {totalPagesFunc().length > 1 && <>Pages: <select 
                          name='currentPage'
                          value={currentPage}
                          onChange={(e) => (handlePageChange(e))}
                        >                          
                          {totalPagesFunc().map((row,index) => (<option value={row}>{row}</option>))}
                        </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                        <button className='btn btn-primary btn-sm' onClick={(e) => {handleFiltersSubmit(e)}}>Filter By Page</button>
                        <div style={{marginBottom: 30}}></div></>
                      }
                    </div>                                       
                </div>
            </div>
        </React.Fragment>
    )
}

export default Users;