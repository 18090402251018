import React, { useState, useEffect } from 'react'
import API from '../../API';
import Navbar from '../Navbar';
import {useNavigate} from "react-router-dom"
import {AppConfig} from "../../Utils/GamesConfig"


function Managelistbanner(props) {

    let navigate = useNavigate();

    const user = props.Users;
      
      const [result, setBanners] = useState([]);
  
      useEffect(() => {
          fetchData()
      },[])
      
      const fetchData = async () => {
          const res =  await API.get("/banner/bannerlist")
          //console.log(res.data.caregories)
          setBanners(res.data.banners)
      }
  
      const handlechange = async (val, bannerid, ind) => {
        //alert(bannerid);
        //alert(val)
        const qry = {"listingsorting": val} 
        const res = await API.put("/banner/postlistposition/" + bannerid, qry).then(result => {
            
          alert("Updated.....")  
          navigate("/managwlistbanners")
        })
    }
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container-fluid mt-4">
            <div class="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Banner Page Management</h4>
                    <div className="flex-shrink-0">
                        <a class="btn btn-soft-secondary btn-sm" href="/managwlistcategories" style={{marginRight:"5px"}}>Categories</a>
                        <a class="btn btn-primary btn-sm" href="/managwlistbanners">Banners</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div class="table-responsive">
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="10%" className='text-center'>#</th>
                                        <th width="60%">Banner ID</th>
                                        <th width="10%">Banner Image</th>
                                        <th width="20%">Sorting</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {result.map((row,index) => (
                                    <tr>
                                        <td className='text-center'>{index+1}</td>
                                        <td>{row.bannerid}</td>
                                        <td><img src={`${AppConfig.BANNER_IMAGE_NAME}${row.bannerimage}`} width="80px;" height="60px;" /></td>
                                        <td><input class="form-control" type="text" name={`listingsorting{index+1}`} id={`listingsorting{index+1}`} defaultValue={row.listingsorting} onBlur={ (e) => handlechange(e.target.value,row.bannerid,index+1) } /></td>
                                    </tr>
                                    ))}                                    
                                </tbody>                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
      
    </React.Fragment>
  )
}

export default Managelistbanner