import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API';
import { useNavigate } from 'react-router-dom';

function Managelistcategories(props) {

    const user = props.users;  
    let navigate = useNavigate();
    console.log(props)
    const [result, setCategory] = useState([]);
    const [cat, setCat] = useState({
        listingsorting:""
    })
 
    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        const res =  await API.get("/category/categorylist")
        setCategory(res.data.categories)
    }
    const handlechange = async (val, catid, ind) => {
        //alert(catid);
        //alert(val)
        const qry = {"listingsorting": val} 
        const res = await API.put("/category/postlistposition/" + catid, qry).then(result => {
            
          alert("Updated.....")  
          navigate("/managwlistcategories")
        })
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
        <div class="container-fluid mt-4">
            <div class="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Banner Page Management</h4>
                    <div className="flex-shrink-0">
                        <a class="btn btn-primary btn-sm" href="/managwlistcategories" style={{marginRight:"5px"}}>Categories</a>
                        <a class="btn btn-soft-secondary btn-sm" href="/managwlistbanners">Banners</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div class="table-responsive">
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                <tr>
                                    <th width="10%" className='text-center'>#</th>
                                    <th width="60%">Banner ID</th>
                                    <th width="10%">Banner Image</th>
                                    <th width="20%">Sorting</th>
                                </tr>
                            </thead>
                            <tbody>
                            {result.map((row,index) => (
                                <tr>
                                    <td className='text-center'>{index+1}</td>
                                    <td>{row.catid}</td>
                                    <td>{row.catname}</td>
                                    <td><input class="form-control" type="text" name={`listingsorting{index+1}`} id={`listingsorting{index+1}`} defaultValue={row.listingsorting} onBlur={ (e) => handlechange(e.target.value,row.catid,index+1) } /></td>
                                </tr>
                            ))}                            
                            </tbody>                    
                        </table>
                    </div>
                </div>
            </div>            
        </div>        
    </React.Fragment>
  )
}

export default Managelistcategories