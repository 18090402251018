import React,{useState,useEffect,useRef} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Navbar from '../Navbar';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from 'react-native-uuid'
import { AppConfig } from '../../Utils/GamesConfig';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
  
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',
  
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'light',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function Addgame(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }

    var timestamp = new Date().getUTCMilliseconds();
    //console.log(timestamp)
    //const user = props.Users;
    const [vendor, setVendor] = useState([]);
    const [cate, setPCategory] = useState([]);
    const [subcat, setSubcategory] = useState([]);
    const [date, setDate] = useState(new Date());
    const [expirydate, setExpirydate] = useState(new Date());
    const [games, setSimilarGames] = useState([])
    const [caturl, setCaturl] = useState([])
    const [catsid, setCatid] = useState([])
    const [subcaturl, setSubcaturl] = useState([])
    const [gameurl, setGameurl] = useState([])
    const [gametypes, setGametype] = useState([])
    const [cats, setCats] = useState()
    const [tags, setTags] = useState()
    const [tagsId, setTagsId] = useState([])
    //console.log("tags",tags)
    //console.log("cats",cats)
    const gamedescrRef = useRef(null);
    const gameruleRef = useRef(null);
    const howtoplayRef = useRef(null);
    const gamectrlRef = useRef(null);

    const [cat, setGames] = useState({
        vendorid:"",
        category_id:"",
        categoryid:"",
        subcategoryid:"",
        mtitle:"",
        mkeywords:"",
        mdescr:"",
        gtitle:"",
        homepimage:"",
        listpimage:"",
        detailpimage:"",
        homepimage_fb:"",
        listpimage_fb:"",
        detailpimage_fb:"",        
        grating:"",
        glike:"",
        gdislike:"",
        practice:"",
        gdescr:"",
        grule:"",
        ghowtoplay:"",
        gcontrols:"",
        //tags: [],
   });

   useEffect(() => {
     fetchvendor()
     fetchParentcat()
     fetchgametype()
     fetchNewCategories()
     fetchTags()
   },[])

    const fetchvendor = async () => {
        const res = await API.get("/vendor/vendorlist")
        setVendor(res.data.vendors)
    }

    console.log("cats", cats)
    console.log("Games", cat)

    const fetchNewCategories = async () => {
        await API.get("/api/v3/listcategory")
        .then((response) => {
          setCats(response);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    const fetchTags = async () => {
        await API.get("/api/v3/alltags")
        .then((response) => {
          setTags(response);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });        
    }        

    const fetchParentcat = async () => {
        const pcat = await API.get("/category/parentcategory")
        setPCategory(pcat.data.categories)
    }

    const fetchgametype = async() => {
        const gtype = await API.get("/games/gamestypes")
        setGametype(gtype.data.gametypes)
    }
    
    const getsubcat = async (e) => {
        const {name, value} = e.target
        var qry = {"catid":value}
        const subcatdata = await API.post("/category/childcategory", qry)
        console.log("subcatdata", subcatdata)
        setSubcategory(subcatdata.data.subcategories.subcategories)
        setCatid(value)
        setCaturl(subcatdata.data.subcategories.caturl)
    }
    
    const getsubcatdetails = async(e) => {
        const {value} = e.target
        const subcatdetails = await API.get("/category/category/" + catsid + "/" + value)
        //console.log(subcatdetails.data.category.subcategories[0].subcaturl)
        setSubcaturl(subcatdetails.data.category.subcategories[0].subcaturl)
    }

    const getGames = async(e) => {
        const {name, value} = e.target
        const qry = {"catid": value}
        const result = await API.post("/games/categorygames/", qry)
        //console.log(result.data)
        setSimilarGames(result.data.games)
    }

    const onDragEnd = (result)  =>{
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const items = reorder(
         games,
          result.source.index,
          result.destination.index
        );
        console.log(items)
        setSimilarGames(items)
    }
   
    const handlechange = e => {
        const {name, value} = e.target
        setGames({...cat,[name]:value})
        if(cat.gtitle !=="" && cat.gtitle !==undefined){
            const expcat = cat.gtitle.trim().replace(/\s+/g, '-').replace(/[^\w-]+/g, "").replace(/_/g, "");
            const finalurl = expcat.toLowerCase()
            setGameurl(finalurl)
        }
    }
    console.log("gameurl", gameurl)
    
    const handleTags = (e) => {
        var arr = []
        if (e.target.checked === true) {
            setTagsId([...tagsId, e.target.value]);
            arr.push([...tagsId, e.target.value])
          } else {
            const selectedAcc = tagsId.filter(a => {
              if (a === e.target.value) return false;
              return true;
            });
            setTagsId([...selectedAcc]);
            arr.push([...selectedAcc])            
          }
          console.log("arrarrarr", arr) 
          setGames(prevState => ({ ...prevState, tags: arr}))
      };  
       

    //Image Handler
    const imghandlechange = e => {
        setGames({...cat,homepimage:e.target.files[0]})
    }
    const imghandlechange1 = e => {
        setGames({...cat,listpimage:e.target.files[0]})
    }
    const imghandlechange2 = e => {
        setGames({...cat,detailpimage:e.target.files[0]})
    }
    const imghandlechange3 = e => {
        setGames({...cat,homepimage_fb:e.target.files[0]})
    }
    const imghandlechange4 = e => {
        setGames({...cat,listpimage_fb:e.target.files[0]})
    }
    const imghandlechange5 = e => {
        setGames({...cat,detailpimage_fb:e.target.files[0] })
    }            

    const addgames = () => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var gameid = new Date().valueOf() + val;
       
        // if(gamedescrRef.current){
        //     cat.gdescr = gamedescrRef.current.getContent();
        // }
        // if(gameruleRef.current){
        //     cat.grule = gameruleRef.current.getContent();
        // }
        // if(howtoplayRef.current){
        //     cat.ghowtoplay = howtoplayRef.current.getContent();
        // }
        // if(gamectrlRef.current){
        //     cat.gcontrols = gamectrlRef.current.getContent();
        // }
        
        //form validation
        if(cat.vendorid === ""){
            alert("Please select vendor")
            document.getElementById('vendorid').focus()
            return false;
        }
        if(cat.category_id === ""){
            alert("Please select category")
            document.getElementById('category_id').focus()
            return false;
        }        
        if(cat.gametype === "" || cat.gametype === undefined){
            alert("Please select game type")
            document.getElementById('gametype').focus()
            return false;
        }
        if(cat.categoryid === ""){
            alert("Please select category")
            document.getElementById('categoryid').focus()
            return false;
        }
        if(cat.subcategoryid === ""){
            alert("Please select sub category")
            document.getElementById('subcategoryid').focus()
            return false;
        }
        if(cat.gtitle === ""){
            alert("Please enter game title")
            document.getElementById('gtitle').focus()
            return false;
        }
        if(cat.homepimage === ""){
            alert("Please upload home page image")
            document.getElementById('homepimage').focus()
            return false;
        }
        if(cat.listpimage === ""){
            alert("Please upload listing page image")
            document.getElementById('listpimage').focus()
            return false;
        }
        if(cat.detailpimage === ""){
            alert("Please upload detail page image")
            document.getElementById('detailpimage').focus()
            return false;
        }
        if(cat.homepimage_fb === ""){
            alert("Please upload home page image")
            document.getElementById('homepimage_fb').focus()
            return false;
        }
        if(cat.listpimage_fb === ""){
            alert("Please upload listing page image")
            document.getElementById('listpimage_fb').focus()
            return false;
        }
        if(cat.detailpimage_fb === ""){
            alert("Please upload detail page image")
            document.getElementById('detailpimage_fb').focus()
            return false;
        }        
        if(cat.grating === ""){
            alert("Please enter game rating")
            document.getElementById('grating').focus()
            return false;
        }
        if(cat.glike === ""){
            alert("Please enter game like")
            document.getElementById('glike').focus()
            return false;
        }
        if(cat.gdislike === ""){
            alert("Please enter game dislike")
            document.getElementById('gdislike').focus()
            return false;
        }
        if(cat.playurl === ""  || cat.playurl === undefined){
            alert("Please enter game url")
            document.getElementById('playurl').focus()
            return false;
        }
        // if(cat.gdescr === ""){
        //     alert("Please enter game description")
        //     document.getElementById('gdescr').focus()
        //     return false;
        // }
        // if(cat.grule === ""){
        //     alert("Please enter game rule")
        //     document.getElementById('grule').focus()
        //     return false;
        // }
        // if(cat.ghowtoplay === ""){
        //     alert("Please enter game how to play")
        //     document.getElementById('ghowtoplay').focus()
        //     return false;
        // }
        // if(cat.gcontrols === ""){
        //     alert("Please enter game control")
        //     document.getElementById('gcontrols').focus()
        //     return false;
        // }
        if(cat.mtitle === ""){
            alert("Please enter game meta title")
            document.getElementById('mtitle').focus()
            return false;
        }
        if(cat.mkeywords === ""){
            alert("Please enter game meta keywords")
            document.getElementById('mkeywords').focus()
            return false;
        }
        if(cat.mdescr === ""){
            alert("Please enter game meta description")
            document.getElementById('mdescr').focus()
            return false;
        }

        cat.similargames = games
        cat.gameid = gameid
        
        const gamedate = date
        const gameexpirydate = expirydate
        
        const formdata = new FormData();
        formdata.append('homepimage', cat.homepimage, cat.homepimage.name)
        formdata.append('listpimage', cat.listpimage, cat.listpimage.name)
        formdata.append('detailpimage', cat.detailpimage, cat.detailpimage.name)
        formdata.append('homepimage_fb', cat.homepimage_fb, cat.homepimage_fb.name)
        formdata.append('listpimage_fb', cat.listpimage_fb, cat.listpimage_fb.name)
        formdata.append('detailpimage_fb', cat.detailpimage_fb, cat.detailpimage_fb.name)
        formdata.append('category_id', cat.category_id)
        formdata.append('display_in_free_app', cat.display_in_free_app)
        formdata.append('tags', cat.tags)
        formdata.append('gametype', cat.gametype)
        formdata.append('gameid', cat.gameid)
        formdata.append('vendorid', cat.vendorid)
        formdata.append('categoryid', cat.categoryid)
        formdata.append('subcategoryid', cat.subcategoryid)
        formdata.append('mtitle', cat.mtitle)
        formdata.append('mkeywords', cat.mkeywords)
        formdata.append('mdescr', cat.mdescr)
        formdata.append('gtitle', cat.gtitle)
        //formdata.append('gentry_fee', cat.gentry_fee)
        //formdata.append('gwinmsg', cat.gwinmsg)
        //formdata.append('gwining_price', cat.gwining_price)
        formdata.append('grating', cat.grating)
        formdata.append('glike', cat.glike)
        formdata.append('gdislike', cat.gdislike)
        //formdata.append('practice', cat.practice)
        // formdata.append('gdescr', cat.gdescr)
        // formdata.append('grule', cat.grule)
        formdata.append('playurl', cat.playurl)
        // formdata.append('ghowtoplay', cat.ghowtoplay)
        // formdata.append('gcontrols', cat.gcontrols)
        formdata.append('gamedate', gamedate)
        formdata.append('expirydate', gameexpirydate)
        formdata.append('caturl', caturl.toLowerCase())
        if(subcaturl !==""){
            formdata.append('subcaturl', subcaturl && subcaturl.toLowerCase())    
        }
        
        formdata.append('gurl', gameurl.toLowerCase())
        formdata.append('similarcatid', cat.similarcatname)
        //formdata.append('similargames', JSON.stringify(cat.similargames))
        formdata.append('createdBy', email)
        formdata.append('display_app', cat.display_app)
        formdata.append('utm_param', cat.utm_param)
        formdata.append('rss_display', cat.rss_display)
        console.log("formdata", formdata)
        if(cat.vendorid){
            //console.log(formdata)
            API.post("/games/postgames", formdata)
            .then(res => {
                console.log("API RES", res)
                alert(res.data.message);
                navigate("/games/"+cat.vendorid)
            })
        }else{
            alert("Please enter category name")
        }
    }
    console.log("kb cat", cat)

  return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-header align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Add Game</h4>
                            </div>
                            <div class="card-body">
                                {/* <form name='addcategory' id='addcategory' method='post' encType="multipart/form-data"> */}

                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-3">
                                                <label className="form-label">Vendor Name:</label>
                                                <select className="form-control" name='vendorid' id='vendorid' onChange={ handlechange }>
                                                    <option value="">--Select Vendor--</option>
                                                    { vendor.map((rows,index) => (
                                                    <option value={rows.vendorid}>{rows.vendorname}</option>     
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" style={{color: "red"}}>New Category:</label>
                                                <select className="form-control" name='category_id' id='category_id' onChange={(e) => {handlechange(e); }}>
                                                    <option value="">Select</option>
                                                    {cats && cats.data && cats.data.cates.map((cat, i) => (
                                                        <option value={cat._id}>{cat.category_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='row'>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Game Type:</label>
                                                    <select className="form-control" name='gametype' id='gametype' onChange={ handlechange }>
                                                        <option value="">Select Type</option>
                                                        {gametypes.map((arrtype,index) => (
                                                            <option value={arrtype.typeid}>{arrtype.typename}</option>
                                                        ))}
                                                    </select>
                                                </div>                                            
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Category:</label>
                                                    <select className="form-control" name='categoryid' id='categoryid' onChange={(e) => { getsubcat(e); handlechange(e); }}>
                                                        <option value="">Select Category</option>
                                                        {cate.map((cat,index) => (
                                                            <option value={cat.catid}>{cat.catname}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Sub Category:</label>
                                                    <select className="form-control"  name='subcategoryid' id='subcategoryid' onChange={(e) => {getsubcatdetails(e);handlechange(e) }}>
                                                        <option value="">Select Sub Category</option>
                                                        { subcat.map((subcat,index) => (
                                                        <option value={subcat.subcatid}>{subcat.subcatname}</option>     
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Game Title:</label>
                                                <input className="form-control" type="text" name='gtitle' id='gtitle' value={cat.gtitle} onChange={ handlechange } />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Slug:</label>
                                                <input className="form-control" type="text" name='gurl' id='gurl' value={gameurl} onChange={ handlechange } />
                                            </div>
                                            <div className="mb-3"> 
                                                <label className="form-label">Game Url:</label>
                                                <span><input className="form-control" type="text" name='playurl' id='playurl' value={cat.playurl} onChange={ handlechange } /></span>
                                            </div>   
                                            <div className='row'>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Onboarding Date:</label>
                                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={date} onChange={date => setDate(date)} />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Expiry Date:</label>
                                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd" selected={expirydate} onChange={expirydate => setExpirydate(expirydate)} />
                                                </div>                                            
                                            </div>                                         
                                            <div className='row'>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Game Rating:</label>
                                                    <input className="form-control" type="text" name='grating' id='grating' value={cat.grating} onChange={ handlechange } />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Game Like:</label>
                                                    <input className="form-control" type="text" name='glike' id='glike' value={cat.glike} onChange={ handlechange } />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label className="form-label">Game Dislike:</label>
                                                    <input className="form-control" type="text" name='gdislike' id='gdislike' value={cat.gdislike} onChange={ handlechange } />
                                                </div>
                                            </div>
                                            <h6>Paid Game Banner</h6>
                                            <div className='row'>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Home Page Image:<span style={{color: "red"}}>Pro</span></label>
                                                    <input className="form-control" type='file' id='homepimage' name='homepimage' onChange={imghandlechange } />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Listing Page Image:<span style={{color: "red"}}>Pro</span></label>
                                                    <input className="form-control" type='file' id='listpimage' name='listpimage' onChange={imghandlechange1 } />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Details Page Image:<span style={{color: "red"}}>Pro</span></label>
                                                    <input className="form-control" type='file' id='detailpimage' name='detailpimage' onChange={imghandlechange2 } />
                                                </div> 
                                            </div>
                                            <h6>Free Game Banner</h6>
                                            <div className='row'>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Home Page Image:</label>
                                                    <input className="form-control" type='file' id='homepimage_fb' name='homepimage_fb' onChange={imghandlechange3 } />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Listing Page Image:</label>
                                                    <input className="form-control" type='file' id='listpimage_fb' name='listpimage_fb' onChange={imghandlechange4 } />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Details Page Image:</label>
                                                    <input className="form-control" type='file' id='detailpimage_fb' name='detailpimage_fb' onChange={imghandlechange5 } />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <h6>Similar Games</h6>
                                                <select className="form-control" name="similarcatname" id="similarcatname" class="form-control" onChange={ (e) =>{ getGames(e); handlechange(e); }}>
                                                    <option value="">--Select Category--</option>
                                                    {cate.map((row,index) => (
                                                        <option value={row.catid}>{row.catname}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='row'>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Display in Thirt Party App:</label>
                                                    <select className="form-control" name='display_app' id='display_app' onChange={ handlechange }>
                                                        <option value="">--Select--</option>
                                                        <option value="y">Yes</option>
                                                        <option value="n">No</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Display in Free App:</label>
                                                    <select className="form-control" name='display_in_free_app' id='display_in_free_app' onChange={ handlechange }>
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>  
                                            </div>
                                                <div className="mb-3">
                                                    <label className="form-label">UTM:</label>
                                                    <input className="form-control" type='text' id='utm_param' name='utm_param' value={cat.utm_param } onChange={handlechange} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Rss Display:</label>
                                                    <select className="form-control" name='rss_display' id='rss_display' onChange={ handlechange }>
                                                        <option value="">--Select--</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                </div>             
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="mb-3">
                                                <label className="form-label">About the Game:</label>
                                                <Editor
                                                    onInit={(evt, editor) => gamedescrRef.current = editor}
                                                    initialValue={cat.gdescr}
                                                    name="gdescr"
                                                    init={{
                                                        height: 200,
                                                        menubar: true,
                                                        plugins: [
                                                        'advlist autolink lists link image', 
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount',
                                                        'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                                        ],
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic | \
                                                        alignleft aligncenter alignright | \
                                                        bullist numlist outdent indent | help'
                                                    }} 
                                                    onChange={ handlechange }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Game Rule:</label>
                                                <Editor
                                                    onInit={(evt, editor) => gameruleRef.current = editor}
                                                    initialValue={cat.grule}
                                                    name="grule"
                                                    init={{
                                                        height: 200,
                                                        menubar: true,
                                                        plugins: [
                                                        'advlist autolink lists link image', 
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount',
                                                        'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                                        ],
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic | \
                                                        alignleft aligncenter alignright | \
                                                        bullist numlist outdent indent | help'
                                                    }} 
                                                    onChange={ handlechange }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">How To Play:</label>
                                                <Editor
                                                    onInit={(evt, editor) => howtoplayRef.current = editor}
                                                    initialValue={cat.ghowtoplay}
                                                    name="ghowtoplay"
                                                    init={{
                                                        height: 200,
                                                        menubar: true,
                                                        plugins: [
                                                        'advlist autolink lists link image', 
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount',
                                                        'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                                        ],
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic | \
                                                        alignleft aligncenter alignright | \
                                                        bullist numlist outdent indent | help'
                                                    }} 
                                                    onChange={ handlechange }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Game Controls:</label>
                                                <Editor
                                                    onInit={(evt, editor) => gamectrlRef.current = editor}
                                                    initialValue={cat.gcontrols}
                                                    name="gcontrols"
                                                    init={{
                                                        height: 200,
                                                        menubar: true,
                                                        plugins: [
                                                        'advlist autolink lists link image', 
                                                        'charmap print preview anchor help',
                                                        'searchreplace visualblocks code',
                                                        'insertdatetime media table paste wordcount',
                                                        'a11ychecker advcode casechange export formatpainter linkchecker checklist mediaembed pageembed permanentpen powerpaste advtable tinymcespellchecker'
                                                        ],
                                                        toolbar:
                                                        'undo redo | formatselect | bold italic | \
                                                        alignleft aligncenter alignright | \
                                                        bullist numlist outdent indent | help'
                                                    }} 
                                                    onChange={ handlechange }
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Meta Title(Seo):</label>
                                                <input className="form-control" type="text" name='mtitle' id='mtitle' value={cat.mtitle} onChange={ handlechange } />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Meta Keywords(Seo):</label>
                                                <textarea className="form-control" cols={40} rows={2} name='mkeywords' id='mkeywords' onChange={ handlechange } >{cat.mkeywords}</textarea>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Meta Description(Seo):</label>
                                                <textarea className="form-control" cols={40} rows={5} name='mdescr' id='mdescr' onChange={ handlechange } >{cat.mdescr}</textarea>
                                            </div>                       
                                        </div>
                                        <div className='col-md-2'>
                                            <div className="mb-3">
                                                <label className="form-label" style={{color: "red"}}>Select Tags:</label>
                                                <div className='tagList'>
                                                    <ul style={{padding: 0, margin: 0}}>
                                                        {
                                                            tags && tags.data && tags.data.tags.map((data, i) => (
                                                                <li style={{listStyle: "none", display: "flex"}}>
                                                                    <input 
                                                                        type="checkbox" name="tags_id" 
                                                                        value={data._id} 
                                                                        style={{ width: 20, height: 20}}
                                                                        onChange={handleTags}
                                                                    />
                                                                    <label style={{paddingLeft: 5}} for=" "> {data.tag_name}</label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>                                            
                                            <div className="btn btn-primary btn-block" onClick={ addgames} >Submit</div>
                                        </div>
                                    </div>
                                    
                                    {/*<div className="mb-3">
                                        <label className="form-label">Sorting:</label>
                                        <DragDropContext name="gameimg" id="gameimg" onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable" direction="horizontal">
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                                {...provided.droppableProps}
                                                >
                                                {games.map((item, index) => (                                            
                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        >
                                                        <img src={`${AppConfig.GAME_IMAGE_NAME}${item.lpimage}`} width="100px;" height="80px;" />
                                                        <br /><label class="text-white bg-dark mt-md-2">{item.gtitle}</label>
                                                        </div>                                                
                                                    )}                                            
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>*/}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </React.Fragment>
  )
}

export default Addgame