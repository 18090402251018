import React, { useState, useEffect, useRef } from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Hepldeskreply(props) {
    const {helpdeskid} = useParams()
    let navigate = useNavigate();
    const user = props.Users;
    const [rows, setItems] = useState([]);
    const [cat, setCategory] = useState({})

    useEffect(() => {
        fetchData()
       
    },[])
    
    const handleChange = e => {
        const {name, value} = e.target
        setCategory({
            ...cat,[name]:value
        })
        
    }

    const fetchData = async () => {
        const res =  await API.get(`/helpdesk/listdata/${helpdeskid}`)
        console.log(res.data.result)
        setItems(res.data.result)
    }

    const getReply = async() => {

    }

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Category</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Email:</label>
                                <input className="form-control" type='text' id='email' name='email' value={rows.email} onChange={handleChange} />
                            </div>
                            
                            <div className="mb-3">
                                <label className="form-label">Reply:</label>
                                <textarea className="form-control" cols={40} rows={10} name='descr' id='descr' onChange={ handleChange } value={cat.mdescr} ></textarea>
                            </div>
                            <div className="btn btn-primary" onClick={ getReply} >Reply</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Hepldeskreply