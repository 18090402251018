import React,{useState,useEffect} from 'react'
import API from '../../API'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Helpdesk(props) {

  let navigate = useNavigate()
    const user = props.Users;
    console.log(props)
  const [result, setResult] = useState([])
  
  useEffect(() => {
    fetchData()
},[])

  const fetchData = async() => {
    const res= await API.get("/helpdesk/listdata")
    setResult(res.data.result)
  }

  const deletehelpdesk = async() => {

  }
  console.log(result)
  return (
    <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
                <div className="table-responsive">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Helpdesk List</h4>
                            
                        </div>
                        <div className='card-body p-0'>
                            <div className="table-responsive">
                                <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                    <thead className='text-muted table-light'>
                                        <tr>
                                            <th width="10%">#</th>
                                            <th width="10%">Name</th>
                                            <th width="10%">Email</th>
                                            <th width="10%">Mobile No.</th>
                                            <th width="10%">Subject</th>
                                            <th width="15%">Message</th>
                                            <th width="10%" className='text-right'>Action </th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {result.map((row,index) => (
                                            <tr>
                                                <td width="10%">{index+1}</td>
                                                <td width="10%">{row.name}</td>
                                                <td width="10%">{row.email}</td>
                                                <td width="10%">{row.mobile}</td>
                                                <td width="10%">{row.subject}</td>
                                                <td width="15%">{row.message}</td>
                                                <td width="10%" className='text-right'>
                                                    <a href={`/helpdesk/reply/${row.helpdeskid}`} className="delete btn btn-soft-danger btn-sm" title="Delete" data-toggle="tooltip">Reply</a>
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Delete the item?')){deletehelpdesk(row.helpdeskid)};}} title="Delete" data-toggle="tooltip"><FontAwesomeIcon icon={faTrash} /></a>
                                                </td>
                                            </tr>
                                            
                                        ))}
                                        <tr><td>&nbsp;</td></tr>    
                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
  )
}

export default Helpdesk