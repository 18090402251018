import React, {useState,useEffect} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"
import { AppConfig } from '../../Utils/GamesConfig';

function Editbanner(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    const [cat, setBanner] = useState({})
    const [flag, setflag] = useState([])
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const {bid} = useParams()

    useEffect(() => {
        fetchBannerData()
    },[])

    const handlechange = e => {
        const {name, value} = e.target
        setBanner({
            ...cat,[name]:value
        })
        
    }

    const imghandlechange = e => {
        setBanner({
            ...cat,bimage:e.target.files[0]
        })
    }

    const showtxt = (e) => {

        if(e.target.value === 'active'){
            setflag('yes')
        }else{
            setflag('no')
        }
        console.log(e.target.value)
    }
   
    const fetchBannerData = async () => {
      const res =  await API.get(`/banner/banner/${bid}`)
      console.log(res.data.banner.appbannerurl)
      setBanner(res.data.banner)
      if(res.data.banner.appbannerurl){
        setflag('yes')  
      }
      
    }

    const editbanner = () => {

      var bannerid = bid;
      
      if(cat.bimage == undefined){
        var banerimg = cat.bannerimage
      }else{
        var banerimg = cat.bimage
      }
      
      const formdata = new FormData();
      if(cat.bimage){
        formdata.append('bimage', cat.bimage, cat.bimage.name)
      }
      
      if(cat.display_app === 'active'){
        var appurl = cat.appbannerurl
      }else{
        var appurl = ''
      }
      
      formdata.append('bannerid', bannerid)
      formdata.append('bannertitle', cat.bannertitle)
      formdata.append('pagename', cat.pagename)
      formdata.append('bannerurl', cat.bannerurl)
      formdata.append('position', cat.position)
      formdata.append('bannerstatus', cat.bannerstatus)
      formdata.append('display_app', cat.display_app)
      formdata.append('appbannerurl',appurl)
      formdata.append('updatedBy', email)
      
      if(banerimg){
          console.log(formdata)
          API.post("/banner/editbanner", formdata)
          .then(res => {
              alert(res.data.message);
              navigate("/banners")
          })
      }else{
          alert("Please upload banner")
      }
   }
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Banner</h4>
                        </div>
                        <div class="card-body">
                            <div className="mb-3">
                                <label className="form-label">Banner Title:</label>
                                <input className="form-control" type="text" name='bannertitle' id='bannertitle' value={cat.bannertitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Page Name:</label>
                                <select className="form-control" name='pagename' id='pagename' value={cat.pagename} onChange={ handlechange }>
                                    <option value="">--Select Page--</option>
                                    <option value="home">Home</option>
                                    <option value="listing">Listing</option>
                                    <option value="details">Details</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner Image:</label>
                                <input className="form-control" type='file' id='bimage' name='bimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner URL:</label>
                                <input className="form-control" type="text" name='bannerurl' id='bannerurl' value={cat.bannerurl} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <a href={`${AppConfig.BANNER_IMAGE_NAME}${cat.bannerimage}`} target="_blank"><img src={`${AppConfig.BANNER_IMAGE_NAME}${cat.bannerimage}`} width="50px;" height="50px;" /></a>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Position:</label>
                                <select className="form-control" name='position' id='position' value={cat.position} onChange={ handlechange }>
                                    <option value="">--Select Position--</option>
                                    <option value="top">Top</option>
                                    <option value="top">Bottom</option>
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='bannerstatus' value={cat.bannerstatus} id='bannerstatus' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Display App:</label>
                                <select className="form-control" name='display_app' value={cat.display_app} id='display_app' onChange={ (e)=> {handlechange(e);showtxt(e)} }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            {flag === 'yes' &&
                                <div className="mb-3">
                                    <label className="form-label">App Banner URL:</label>
                                    <input className="form-control" type="text" name='appbannerurl' id='appbannerurl' value={cat.appbannerurl} onChange={ handlechange } />
                                </div>
                            }
                            <div className="btn btn-primary" onClick={ editbanner} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Editbanner