import React,{useEffect, useState} from 'react'
import Navbar from '../Navbar';
import API from '../../API'
import { Link } from "react-router-dom" 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Categories(props) {
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [result, setCategory] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/category/categorylist")
        //console.log(res.data.caregories)
        setCategory(res.data.categories)
    }

    const deletecategory= async (catid,status) => {

        const qry = {"catid": catid, "status":status} 
        const res = await API.post("/category/deletecategory", qry).then((resp) => { 
            const msg = "Category has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }

    const deletesubcategory= async (catid,subcatid,status) => {

        const qry = {"catid": catid, "subcatid":subcatid, "status":status} 
        const res = await API.post("/category/deletesubcategory", qry).then((resp) => { 
            const msg = "Subcategory has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }

  return (
    <React.Fragment>
         <Navbar user={user} />
         <div className='container-fluid mt-4'>
            <div className="table-responsive">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Category Details</h4>
                        <div className="flex-shrink-0">
                            <a href='/addcategory' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className="table-responsive">
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="10%">#</th>
                                        <th width="10%">Category</th>
                                        <th width="15%">Meta Title</th>
                                        <th width="15%">Meta Keywords</th>
                                        <th width="20%">Meta Description</th>
                                        <th width="20%">Description</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {result.map((row,index) => (
                                        <>
                                        <tr>
                                            <td width="10%">{index+1}</td>
                                            <td width="10%">{row.catname}</td>
                                            <td width="15%">{row.mtitle}</td>
                                            <td width="15%">{row.mkeywords}</td>
                                            <td width="20%">{row.mdescr}</td>
                                            <td width="20%" dangerouslySetInnerHTML={ { __html: row.fdescr}}></td>
                                            <td width="10%" className='text-right'>
                                                <Link to={`/edit-category/${row.catid}`} className="edit btn btn-soft-secondary btn-sm" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                                
                                                {row.status === 'active' ? (
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deletecategory(row.catid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                ):(
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deletecategory(row.catid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                )}
                                            </td>
                                        </tr>
                                        <tr><td>Subcategories</td></tr>
                                        {row.subcategories.map((rowd,index) => (
                                        <tr>
                                            <td colSpan="7" className='p-0 border-0'>
                                                <table className="table table-borderless table-light mb-0">
                                                    <tr>
                                                        <td width="10%">{index+1}</td>
                                                        <td width="10%">{rowd.subcatname}</td>
                                                        <td width="15%">{rowd.submtitle}</td>
                                                        <td width="15%">{rowd.submkeywords}</td>
                                                        <td width="20%">{rowd.submdescr}</td>
                                                        <td width="20%" dangerouslySetInnerHTML={ { __html: rowd.subfdescr}}></td>
                                                        <td width="10%" className='text-right'>
                                                            <Link to={`/edit-sub-category/${row.catid}/${rowd.subcatid}`} className="edit btn btn-soft-secondary btn-sm" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                                            {rowd.substatus === 'active' ? (
                                                                <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deletesubcategory(row.catid,rowd.subcatid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                            ):(
                                                                <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deletesubcategory(row.catid,rowd.subcatid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                            )}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        ))}    
                                        </>
                                    ))}
                                    <tr><td>&nbsp;</td></tr>    
                            
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Categories