import React, { useState } from "react"
import "./Register.css"
import axios from "axios"
import API from "../../API"
import { useNavigate } from "react-router-dom"


function Register() {
    const navigate = useNavigate()

    const [ user, setUser] = useState({
        username: "",
        email:"",
        password:"",
        reEnterPassword: "",
        role: ""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const register = () => {
        const { username, email, password, reEnterPassword, role } = user
        if( username && email && password && role && (password === reEnterPassword)){
            
            API.post("/user/signup", user)
            .then( res => {
                alert(res.data.message);
                navigate("/login")
            })
        } else {
            alert("invlid input")
        }
        
    }

    return (
        <div className="register">
            {console.log("User", user)}
            <h1>Register</h1>
            <input type="text" name="username" value={user.username} placeholder="Your Name" onChange={ handleChange }></input>
            <input type="text" name="email" value={user.email} placeholder="Your Email" onChange={ handleChange }></input>
            <input type="password" name="password" value={user.password} placeholder="Your Password" onChange={ handleChange }></input>
            <input type="password" name="reEnterPassword" value={user.reEnterPassword} placeholder="Re-enter Password" onChange={ handleChange }></input>
            <input type="text" name="role" id="role" placeholder="Role" onChange={ handleChange }></input>
            <div className="button" onClick={register} >Register</div>
            <div>or</div>
            <div className="button" onClick={() => navigate("/login")}>Login</div>
        </div>
    )
}

export default Register