import React,{useState,useEffect} from 'react'
import Navbar from '../Navbar';
import API from '../../API'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Vendors(props) {
    
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [result, setVendors] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/vendor/vendorlist")
        //console.log(res.data.vendors)
        setVendors(res.data.vendors)
    }

    const deletevendor = async (vid,status) => {

        const qry = {"vid": vid, "status":status} 
        const res = await API.post("/vendor/deletevendor", qry).then((resp) => { 
            const msg = "Vendor has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
    }
    

  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container-fluid mt-4'>
            <div className="table-responsive">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Vendor Details</h4>
                        <div className="flex-shrink-0">
                            <a href='/addvendor' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add New</a>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                            <thead className='text-muted table-light'>
                                <tr>
                                    <th>#</th>
                                    <th>Vendor Id</th>
                                    <th>Vendor Name</th>
                                    <th>Vendor Email</th>
                                    <th>Vendor Phone</th>
                                    <th>Vendor Company</th>
                                    <th>Vendor Status</th>
                                    <th className='text-right'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {result.map((row,index) => (
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{row.vendorid}</td>
                                        <td>{row.vendorname}</td>
                                        <td>{row.vendoremail}</td>
                                        <td>{row.vendorphone}</td>
                                        <td>{row.vendercompany}</td>
                                        <td>{row.vendorstatus}</td>
                                        <td className='text-right'>
                                            <Link to={`/games/${row.vendorid}`} className="edit btn btn-soft-secondary btn-sm" title="Games" data-toggle="tooltip" style={{marginRight:"5px"}}>Games</Link>
                                            <Link to={`/edit-vendor/${row.vendorid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                            {row.vendorstatus === 'active' ? (
                                                <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this blog?')){deletevendor(row.vendorid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                            ):(
                                                <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this blog?')){deletevendor(row.vendorid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                            )}
                                        </td>
                                    </tr>
                                ))}  
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Vendors