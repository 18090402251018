import React, { useState,useEffect } from 'react'
import Navbar from '../Navbar'
import API from '../../API'
import {AppConfig} from "../../Utils/GamesConfig"
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faEdit,
    faLongArrowAltUp,
    faLongArrowAltDown,
    faTrash,
    faTimes,
    faIdBadge,
    faCopy,
    faL
  } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router'
import DatePicker from "react-datepicker";
import {useParams} from "react-router-dom";

function TagGames(props) {
    const {vendorid, tagid} = useParams()
    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    //const user = props.Users;  
    const [games, setGames] = useState([])

    useEffect(() => {
        //fetchGames()
        fetchGamesByVendor()
    },[])

    const fetchGamesByVendor = async () => {
        const res =  await API.get("/games/games-by-tags/"+tagid)
        // console.log(res.data.games)
        setGames(res.data.gamesByTag)
    }

    // const fetchGames = async () => {
    //     const res =  await API.get("/games/gameslist")
    //     console.log(res.data.games)
    //     setGames(res.data.games)
    // }

    // const deletegame = async (tagid, gameid) => {
    //     const res = await API.get("/games/delete-game-from-tag/"+tagid+"/"+gameid).then((resp) => { 
    //         const msg = "Game has been " + resp.data.message + " Successfully"
    //         alert(msg);
    //         fetchGamesByVendor()
    //     }).catch((err) => { 
    //         console.log("Error Found") 
    //     });
    // }
    const activateGameTag = async (tagid, gameid) => {
        console.log("tagid: ", tagid,   " gameid: ", gameid)
        const res = await API.get(`/games/active-inactive-game-from-tag/${tagid}/${gameid}`);
        alert(res.data.message);
        window.location.reload();
      };

    return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Games List by Tag</h4>
                    <div className="flex-shrink-0">
                        <a href={`/add-new-game-in-tag/${tagid}`} className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add  New Game in Tag</a>
                    </div>
                </div>
                
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <div className="table-wrapper">                            
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="10%">Vendor Name</th>
                                        {/* <th width="15%">Category</th>
                                        <th width="10%">Sub childcategory</th> */}
                                        {/*<th width="10%">Url</th>*/}
                                        <th width="20%">Game Title</th>
                                        <th width="10%">Game Image</th>
                                        {/* <th width="10%">Rating</th>
                                        <th width="5%">Like</th> */}
                                        <th width="5%">Re-Arrange</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {games.map((row,index) => (
                                        <tr key={index}>
                                            <td width="5%">{index+1}</td>
                                            <td width="10%">{row.vendorname}</td>
                                            {/* <td width="15%">{row.category}</td>
                                            <td width="10%">{row.subcategory}</td> */}
                                            {/*<td width="15%">{row.subcaturl}</td>*/}
                                            <td width="20%">{row.gtitle}</td>
                                            <td width="10%"><img src={`${AppConfig.GAME_IMAGE_NAME}${row.hpimage}`} width="60px;" height="40px;" /></td>
                                            {/* <td width="10%">{row.grating}</td>
                                            <td width="5%">{row.glike}</td>
                                            <td width="5%">{row.gdislike}</td> */}
                                            <td width="10%">
                                                <button
                                                className="btn btn-soft-secondary btn-sm"
                                                title="UP"
                                                style={{ marginRight: 10 }}
                                                >
                                                <FontAwesomeIcon icon={faLongArrowAltUp} />
                                                </button>
                                                <button
                                                className="btn btn-soft-secondary btn-sm"
                                                title="DOWN"
                                                >
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                                </button>
                                            </td>                                            
                                            <td width="20%" className='text-right'>
                                            
                                                {/* <Link to={`/contest/${row.vendorid}/${row.gameid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}>Contests</Link> */}
                                                {/* <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want Remove this game?')){deletegame(tagid, row.gameid)};}} title="Delete" data-toggle="tooltip">Remove</a> */}

                                                {row.isActive === 1 ? (
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want inactive this game?')){activateGameTag(tagid, row.gameid)};}} title="Delete" data-toggle="tooltip">Active</a>
                                                ):(
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you sure you want active this game?')){activateGameTag(tagid, row.gameid)};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                )}

                                            </td>
                                        </tr>
                                    ))}  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </React.Fragment>
    )
}

export default TagGames