import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import axios from "axios";
import API from "../../API";
import { useParams } from "react-router-dom";

const EditFaq = (props) => {
  let navigate = useNavigate();
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [faq, setFaq] = useState({});
  const [updateFaq, setUpdateFaq] = useState();

  useEffect(() => {
    fetchFaqById();
  }, []);

  const descriptionRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFaq({
      ...faq,
      [name]: value,
    });
  };

  const { faqid } = useParams();
  const fetchFaqById = async () => {
    await API.get(`/api/v3/faq/${faqid}`)
      .then((response) => {
        //console.log("response", response)
        setFaq(response.data.resData);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const editFaqFunc = () => {
    if (descriptionRef.current) {
      faq.description = descriptionRef.current.getContent();
    }
    if (
      faq.faq_title !== "" ||
      faq.faq_title !== undefined ||
      faq.faq_title !== "" ||
      faq.description !== undefined ||
      faq.description !== "" ||
      faq.description !== undefined
    ) {
      API.post("/api/v3/editfaq", faq)
        .then((response) => {
          alert(response.data.message);
          setTimeout(() => {
            navigate("/manage-faqs");
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    if (faq.faq_title === "" || faq.faq_title === undefined) {
      alert("Please enter title");
      return false;
    }
    if (faq.description === "" || faq.description === undefined) {
      alert("Please enter description");
      return false;
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Edit Faq</h4>
              </div>
              <div className="card-body">
                <form name="editFaq" id="editFaq" method="post">
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="faq_title"
                        id="faq_title"
                        placeholder="Title"
                        value={faq.faq_title}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Description:</label>
                      <Editor
                        onInit={(evt, editor) =>
                          (descriptionRef.current = editor)
                        }
                        initialValue={faq.description}
                        name="description"
                        init={{
                          height: 350,
                          plugins:
                            "powerpaste casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount tinymcespellchecker help formatpainter permanentpen charmap linkchecker emoticons advtable export print autosave",
                          toolbar:
                            "undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                          hieght: 350,
                          toolbar_sticky: true,
                          icons: "thin",
                          skin: "material-classic",
                        }}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={editFaqFunc}>
                        Submit
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditFaq;
