import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import API from "../../API";
import {useParams} from "react-router-dom";

const AddGameInTag = (props) => {
  let navigate = useNavigate();
  const {vendorid, tagid} = useParams()
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }

  const [tagGame, setTagGame] = useState([])
  const [gameId, setGameId] = useState([]);
  const [games, setGames] = useState([])

  useEffect(() => {
    fetchGames()
},[])

console.log("tagGames", tagGame)


const handleChange = (event) => {
  var tagGame_array = [...tagGame];
  if (event.target.checked) {
    tagGame_array = [...tagGame, event.target.value];
  } else {
    tagGame_array.splice(tagGame.indexOf(event.target.value), 1);
  }
  setTagGame(tagGame_array);

};



  // const isUrlValid = (userInput) => {
  //   var res = userInput.match(
  //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  //   );
  //   if (res == null) return false;
  //   else return true;
  // };

    const fetchGames = async () => {
        const res =  await API.get("/games/game-list-in-tag/"+tagid)
        //console.log(res.data.games)
        setGames(res.data.games)
    }

  const addGameTag = () => {
    let reqData = {
        gameId: tagGame
    }

    API.post("/games/add-game-in-tag/"+tagid, reqData)
        .then((response) => {
        alert(response.data.message);
        setTimeout(() => {
            navigate("/games-tag/"+tagid);
        }, 1000);
        })
        .catch((error) => {
        console.error("There was an error!", error);
        });    
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Add New Game in Tag</h4>
              </div>
              <div className="card-body">
                {/* <form name="addcontest" id="addcontest" method="post">
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label className="form-label">Game List:</label>
                      <select
                        className="form-control"
                        name="gamelist"
                        id="gamelist"
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   handleMultiPlayer(e)
                        //   handlechange(e);
                        // //  handleflag(e);
                        // }}
                      >
                        <option value="">Select</option>
                        {games.map((game, i) => (
                            <option key={i} value={game.gameid}>
                                {game.gtitle}
                            </option>
                            ))
                        }
                      </select>
                    </div>                    

                    <div className="mb-3 col-md-12 text-center">
                      <div className="btn btn-primary" onClick={addGameinTag}>
                        Add Game
                      </div>
                    </div>
                  </div>
                </form> */}
                <form>
                    <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0" style={{width: "100%"}}>
                    <thead className='text-muted table-light'>
                        <tr>
                            <td><b>Game Name</b></td>
                            <td style={{float: "right"}}><b>Add/Remove</b></td>
                        </tr>                    
                    </thead>
                    <tbody>
                        {games.map((game, i) => (
                        <tr key={i}>
                            <td>{game.gtitle}</td>
                            <td style={{float: "right"}}>
                            <input 
                                type="checkbox" name="game_id" 
                                value={game.gameid} 
                                style={{ width: 20, height: 20}}
                                onChange={handleChange}
                            />                               
                            </td>
                        </tr>
                        ))}                        
                    </tbody>

                    </table>
                    <div className="mb-3 col-md-12 text-center" style={{marginTop: 20}}>
                      <div className="btn btn-primary" onClick={addGameTag}>
                          Add Games to Tag
                      </div>
                    </div>                    
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddGameInTag;
