import React, {useState,useEffect} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import {useParams} from "react-router-dom"

function Editreels(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
  const {reelsid} = useParams()
  
  useEffect(() => {
    fetchreelData()
   },[])

    const [cat, setReels] = useState({})

    const handlechange = e => {
        const {name, value} = e.target
        setReels({
            ...cat,[name]:value
        })
    }
   
    const fetchreelData = async () => {
      const res =  await API.get(`/reels/reels/${reelsid}`)
      setReels(res.data.reels)
    }

    const editreel = () => {

        var rld = reelsid;
        const qry = {'reelsid': rld,'embed_code': cat.embed_code,'reelsid': reelsid,'title': cat.title,'status': cat.status,'updatedBy': email}
        if(cat.embed_code){
            //console.log(cat)
            API.post("/reels/editreels", qry)
            .then(res => {
                alert(res.data.message);
                navigate("/reels")
            })
        }else{
            alert("Please enter embed code")
        }
   }
  return (
    <React.Fragment>
        <Navbar user={user} />
        <div class="container mt-4">
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div class="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Edit Reel</h4>
                        </div>
                        <div class="card-body">
                            <div className="mb-3">
                                <label className="form-label">Title:</label>
                                <input className="form-control" type="text" name='title' id='title' value={cat.title} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Embed_code:</label>
                                <input className="form-control" type='text' id='embed_code' name='embed_code' value={cat.embed_code} onChange={handlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='status' value={cat.status} id='status' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="btn btn-primary" onClick={ editreel} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Editreels