import React, {useState, useRef} from 'react'
import API from '../../API'
import Navbar from '../Navbar'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Addbanner(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');

    const [categories, setItems] = useState([]);
    const [cat, setBanner] = useState({})
    const [flag, setflag] = useState([])
    
    if(user === "" || user === null){
        navigate("/login")
    } 
    
    const handlechange = e => {
        const {name, value} = e.target
        setBanner({
            ...cat,[name]:value
        })
        
    }

    const imghandlechange = e => {
        setBanner({
            ...cat,bannerimage:e.target.files[0]
        })
    }

    const showtxt = (e) => {

        if(e.target.value === 'active'){
            setflag('yes')
        }else{
            setflag('no')
        }
        console.log(e.target.value)
    }
   
    const addbanner = () => {

        var val = Math.floor(1000000 + Math.random() * 9000000);
        var ctname = cat.bannertitle.toLowerCase();
        var bannerid = ctname.replace(/ /g, '-') + "-" + val;

        const formdata = new FormData();
        formdata.append('bannerimage', cat.bannerimage, cat.bannerimage.name)
        formdata.append('bannerid', bannerid)
        formdata.append('bannertitle', cat.bannertitle)
        formdata.append('pagename', cat.pagename)
        formdata.append('bannerurl', cat.bannerurl)
        formdata.append('position', cat.position)
        formdata.append('bannerstatus', cat.bannerstatus)
        formdata.append('display_app', cat.display_app)
        formdata.append('appbannerurl',cat.appbannerurl)
        formdata.append('createdBy', email)
        console.log(formdata)
        if(cat.bannerimage){
            console.log(cat)
            API.post("/banner/postdata", formdata)
            .then(res => {
                alert(res.data.message);
                navigate("/banners")
            })
        }else{
            alert("Please upload banner")
        }
   }


  return (
    <React.Fragment>
        <Navbar user={user} />
        <div className='container mt-4'>
            <div className='row'>
                <div className='col-md-8 offset-md-2'>
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Add Banner</h4>
                        </div>
                        <div className='card-body'>
                            <div className="mb-3">
                                <label className="form-label">Banner Title:</label>
                                <input className="form-control" type="text" name='bannertitle' id='bannertitle' value={cat.bannertitle} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Page Name:</label>
                                <select className="form-control" name='pagename' id='pagename' onChange={ handlechange }>
                                    <option value="">--Select Page--</option>
                                    <option value="home">Home</option>
                                    <option value="listing">Listing</option>
                                    <option value="details">Details</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner Image:</label>
                                <input className="form-control" type='file' id='bannerimage' name='bannerimage' onChange={imghandlechange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Banner URL:</label>
                                <input className="form-control" type="text" name='bannerurl' id='bannerurl' value={cat.bannerurl} onChange={ handlechange } />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Position:</label>
                                <select className="form-control" name='position' id='position' onChange={ handlechange }>
                                    <option value="">--Select Position--</option>
                                    <option value="top">Top</option>
                                    <option value="top">Bottom</option>
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Status:</label>
                                <select className="form-control" name='bannerstatus' id='bannerstatus' onChange={ handlechange }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Display App:</label>
                                <select className="form-control" name='display_app' id='display_app' onChange={ (e)=> {handlechange(e);showtxt(e)} }>
                                    <option value="">--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                            </div>
                            {flag === 'yes' &&
                                <div className="mb-3">
                                    <label className="form-label">App Banner URL:</label>
                                    <input className="form-control" type="text" name='appbannerurl' id='appbannerurl' value={cat.appbannerurl} onChange={ handlechange } />
                                </div>
                            }
                            

                            <div className="btn btn-primary" onClick={ addbanner} >Submit</div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </React.Fragment>
  )
}

export default Addbanner