import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import API from "../../API";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from "react-native-uuid";
import { AppConfig } from "../../Utils/GamesConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr"; 

function Addcontest(props) {
  let navigate = useNavigate();
  const {vendorid, gameid} = useParams()
  const user = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  if (user === "" || user === null) {
    navigate("/login");
  }
  //console.log(user.email)
  const [startdate, setStartdate] = useState(new Date());
  const [expirydate, setExpirydate] = useState(new Date());
  const [tournstartdate, setTournStartDate] = useState(new Date());
  const [cate, setPCategory] = useState([]);
  const [date, setDate] = useState(new Date());
  const [game, setGames] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [cat, setContest] = useState({isPublished: 0, rake_value: 0, frequency_value: 1});
  const [winningTiers, setWinningTiers] = useState([{tier_wallet_type: "", rank_from: "", rank_to: "", distribution: ""}]);
  const [flag, setFlag] = useState(false);
  const [flagTournament, setFlagTournament] = useState(false);
  const [singlePlayer, setSinglePlayer] = useState(true);
  const [multiPlayer, setMultiPlayer] = useState(false);
  const [accuracy, setAccuracy] = useState([{accuracy: ""}]);



  useEffect(() => {
    fetchParentcat();
    fetchWallettype();
  }, []);

  const fetchParentcat = async () => {
    const pcat = await API.get("/category/parentcategory");
    setPCategory(pcat.data.categories);
  };

  const fetchWallettype = async () => {
    const row = await API.get("/contest/walletlist");
    setWallet(row.data.wallettypes);
  };

  const getcatgames = async (e) => {
    const { name, value } = e.target;
    var qry = { catid: value };
    const rwd = await API.post("/games/categorygames", qry);
    setGames(rwd.data.games);
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setContest({
      ...cat,
      [name]: value,
    });
  };



  //Dynamic Add Remove Handler KB
  const handleWinningTiersInput = (e, index) => {
    const { name, value } = e.target;
    const list = [...winningTiers];
    list[index][name] = value;
    setWinningTiers(list);
    setContest(prevState => ({ ...prevState, winning_tiers: winningTiers}))
  };
  const handleChangeAccuracy = (e, index) => {
    const { name, value } = e.target;
    const list = [...accuracy];
    list[index][name] = value;
    setAccuracy(list);
    setContest(prevState => ({ ...prevState, accuracyScorePattern: accuracy}))
  };  
  // handle click event of the Remove button KB
  const handleRemoveClick = (e, index) => {
    e.preventDefault();
    const list = [...winningTiers];
    list.splice(index, 1);
    setWinningTiers(list);
  };
  const removeAccuracy = (e, index) => {
    e.preventDefault();
    const list = [...accuracy];
    list.splice(index, 1);
    setAccuracy(list);
  };

  // handle click event of the Add button KB
  const handleAddClick = (e) => {
    e.preventDefault();
    setWinningTiers([...winningTiers, {tier_wallet_type: "", rank_from: "", rank_to: "", distribution: ""}]);
  };
  const addAccuracy = (e) => {
    e.preventDefault();
    setAccuracy([...accuracy, {accuracy: ""}]);
  };
  const handleMultiPlayer = async (e) => {
    if ((e.target.value === "multiplayer")) {
      setMultiPlayer(true);
    } else {
      setMultiPlayer(false);
    }
  };
  const handleSinglePlayer = async (e) => {
    if ((e.target.value === "singleplayer")) {
      setSinglePlayer(false);
    } else {
      setSinglePlayer(true);
    }
  };
  const handleFlagTournament = async (e) => {
    if ((e.target.value === "tournament")) {
      setFlagTournament(true);
    } else {
      setFlagTournament(false);
    }
  };
  const handleflag = async (e) => {
    if ((e.target.value === "paid")) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleFixedPercentagePrize = async (e) => {
    if((e.target.value === "in_percent")){
      console.log("In Percent")
    }
  }

  const addcontest = () => {
    var val = Math.floor(1000000 + Math.random() * 9000000);
    var contestid = new Date().valueOf() + val;
    cat.contestid = contestid;
    cat.start_date = startdate;
    cat.expiry_date = expirydate;
    cat.tournament_startdate = tournstartdate;
    cat.createdBy = email;
    cat.vendorId = vendorid;
    cat.game_Id = gameid;

    if (cat.contest_type === "" || cat.contest_type === undefined) {
      alert("Please select contest type");
      return false;
    }
    /*if(cat.contest_title == "" || cat.contest_title == undefined){
            alert("Please enter contest title")
            return false
        }*/
    if (cat.categoryid === "" || cat.categoryid === undefined) {
      alert("Please select category");
      return false;
    }
    if (cat.gameid === "" || cat.gameid === undefined) {
      alert("Please select game");
      return false;
    }

    /*if(cat.message == "" || cat.message == undefined){
            alert("Please eneter contest description")
            return false
        }*/
    if (cat.contest_type === "paid") {
      if (cat.offer_entry_fee === "" || cat.offer_entry_fee === undefined) {
        alert("Please eneter entery fee");
        return false;
      }
      if (
        cat.offer_wining_price === "" ||
        cat.offer_wining_price === undefined
      ) {
        alert("Please enter winning prize");
        return false;
      }
    }
    if (cat.play_url === "" || cat.play_url === undefined) {
      alert("Please enter play url");
      return false;
    }

    if (cat.gameid) {
      API.post("/contest/postcontest", cat).then((res) => {
        alert(res.data.message);
        navigate("/contest");
      });
    } else {
      alert("Please select game");
    }
  };

  return (
    <React.Fragment>
      <Navbar user={user} />
      <div className="container-fluid mt-4">
        <form name="addcontest" id="addcontest" method="post">
          <div className="row">          
            <div className="col-md-8">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Add Contest</h4>
                </div>
                <div className="card-body">                
                    <div className="row">
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Game Type (New):</label>
                        <select
                          className="form-control"
                          name="game_type"
                          id="game_type"
                          onChange={(e) => {
                            handleMultiPlayer(e)
                            handlechange(e);
                          //  handleflag(e);
                          }}
                        >
                          <option value="">Select</option>
                          <option value="singleplayer">Single Player</option>
                          <option value="multiplayer">Multiplayer</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Contest Type (New):</label>
                        <select
                          className="form-control"
                          name="game_contest_type"
                          id="game_contest_type"
                          onChange={(e) => {
                            handlechange(e);
                            handleFlagTournament(e);
                          }}
                        >
                          <option value="">Select</option>
                          <option value="target">Target</option>
                          <option value="tournament">Tournament</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Play For (New):</label>
                        <select
                          className="form-control"
                          name="play_for"
                          id="play_for"
                          onChange={(e) => {
                            handlechange(e);
                            handleflag(e);
                          }}
                          
                        >
                          <option value="">Select</option>
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Play For(Old):</label>
                        <select
                          className="form-control"
                          name="contest_type"
                          id="contest_type"
                          onChange={(e) => {
                            handlechange(e);
                            handleflag(e);
                          }}
                          
                        >
                          <option value="">Select</option>
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Game Type (Old):</label>
                        <select
                          className="form-control"
                          name="gtype"
                          id="gtype"
                          onChange={handlechange}
                        >
                          <option value="">Select</option>
                          <option value="target">Target</option>
                          <option value="tournament">Tournament</option>
                          <option value="multiplayer">Multiplayer</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Daily Play Limit:</label>
                        <input
                          className="form-control"
                          type="number"
                          name="contest_threshold"
                          id="contest_threshold"
                          onChange={handlechange}
                          min="0"
                        />
                      </div>
                      <div className="mb-3 col-md-10">
                        <label className="form-label">Contest Title:</label>
                        <input
                          className="form-control"
                          name="contest_title"
                          id="contest_title"
                          onChange={handlechange}
                        />
                      </div>

                      <div className="mb-3 col-md-6">
                        <label className="form-label">Category:</label>
                        <select
                          className="form-control"
                          name="categoryid"
                          id="categoryid"
                          onChange={(e) => {
                            getcatgames(e);
                            handlechange(e);
                          }}
                        >
                          <option value="">Select Category</option>
                          {cate.map((cat, index) => (
                            <option value={cat.catid}>{cat.catname}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Game:</label>
                        <select
                          className="form-control"
                          name="gameid"
                          id="gameid"
                          onChange={handlechange}
                        >
                          <option value="">Select Game</option>
                          {game.map((row, index) => (
                            <option value={row.gameid}>{row.gtitle}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Contest Description:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="message"
                          id="message"
                          value={cat.message}
                          onChange={handlechange}
                        />
                      </div>
                      {flag == true && (
                        <>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Entry Fee:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="offer_entry_fee"
                              id="offer_entry_fee"
                              value={cat.offer_entry_fee}
                              onChange={handlechange}
                            />
                          </div>

                          <div className="mb-3 col-md-4">
                            <label className="form-label">Winning Prize:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="offer_wining_price"
                              id="offer_wining_price"
                              value={cat.offer_wining_price}
                              onChange={handlechange}
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Play With:</label>
                            <select
                              className="form-control"
                              name="play_with"
                              id="play_with"
                              onChange={handlechange}
                            >
                              <option value="">--Play With--</option>
                              <option value="cash">Cash</option>
                              <option value="bonus">Bonus</option>
                              <option value="deposit">Deposit</option>
                            </select>
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Consume Bonus:</label>
                            <select
                              className="form-control"
                              name="consume_bonus"
                              id="consume_bonus"
                              onChange={handlechange}
                            >
                              <option value="">--Consume Bonus--</option>
                              <option value="1">True</option>
                              <option value="0">False</option>
                            </select>
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Bonus Percent:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="bonus_percent"
                              id="bonus_percent"
                              value={cat.bonus_percent}
                              onChange={handlechange}
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label className="form-label">Max Bonus:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="max_bonus"
                              id="max_bonus"
                              value={cat.max_bonus}
                              onChange={handlechange}
                            />
                          </div>
                        </>
                      )}
                      <div className="mb-3 col-md-12">
                        <label className="form-label">Play Url:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="play_url"
                          id="play_url"
                          value={cat.play_url}
                          onChange={handlechange}
                        />
                      </div>

                      <div className="mb-3 col-md-3">
                        <label className="form-label">Start Date:</label>
                        <Flatpickr
                          className="form-control"
                          data-enable-time
                          options={{ dateFormat: "Y-m-d H:i:s", enableTime: true, enableSeconds: true, time_24hr: true }}
                          value={startdate}
                          onChange={([startdate]) => {
                            setStartdate(startdate);
                          }}
                        />                      
                        {/* <DatePicker
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          selected={startdate}
                          onChange={(startdate) => setStartdate(startdate)}
                        /> */}
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Expiry Date:</label>
                        <Flatpickr
                          className="form-control"
                          data-enable-time
                          options={{ dateFormat: "Y-m-d H:i:s", enableTime: true, enableSeconds: true, time_24hr: true }}
                          value={expirydate}
                          onChange={([expirydate]) => {
                            setExpirydate(expirydate);
                          }}
                        />                      
                        {/* <DatePicker
                          className="form-control"
                          dateFormat="yyyy-MM-dd"
                          selected={expirydate}
                          onChange={(expirydate) => setExpirydate(expirydate)}
                        /> */}
                      </div>
                      {(cat && cat.gtype === "target" && cat.game_contest_type === "target") ?
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Guranteed Amount:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="guranteed_amount"
                          id="guranteed_amount"
                          value={cat.guranteed_amount}
                          onChange={handlechange}
                        />
                      </div> : null} 
                      {(cat && cat.gtype === "tournament" && cat.game_contest_type === "tournament") ?
                      <>
                        <div className="mb-3 col-md-3">
                        <label className="form-label">Winner Publish Frequency:</label>
                        <select
                              className="form-control"
                              name="winner_publish_frequency"
                              id="winner_publish_frequency"
                              onChange={handlechange}
                            >
                              <option selected value="per_hours">Per Hours</option>
                              <option value="per_day">Per Day</option>
                              <option value="weekly">Weekly</option>
                              <option value="fortnightly">Fortnightly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                      </div> 
                        <div className="mb-3 col-md-3">
                        <label className="form-label">Frequency Value:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="frequency_value"
                          id="frequency_value"
                          value={(cat.winner_publish_frequency === 'per_hours') ? cat.frequency_value : (cat.winner_publish_frequency === 'per_day') ? cat.frequency_value = 1 : (cat.winner_publish_frequency === 'weekly') ? cat.frequency_value = 7 : (cat.winner_publish_frequency === 'fortnightly') ? cat.frequency_value = 15 : (cat.winner_publish_frequency === 'monthly') ? cat.frequency_value = 30 : ""}
                          onChange={handlechange}
                          disabled={(cat.winner_publish_frequency === 'per_hours') ? false : true}
                        />
                      </div>
                    </>                   
                    : null}                    
                                    
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Display in App:</label>
                        <select
                          className="form-control"
                          name="display_app"
                          id="display_app"
                          onChange={handlechange}
                        >
                          <option value="">--Select--</option>
                          <option value="y">Yes</option>
                          <option value="n">No</option>
                        </select>
                      </div>                    
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Wallet Type:</label>
                        <select
                          className="form-control"
                          name="wallet_id"
                          id="wallet_id"
                          onChange={handlechange}
                        >
                          <option value="">Select Wallet Type</option>
                          {wallet.map((rwd, index) => (
                            <option value={rwd.walletid}>
                              {rwd.wallet_type}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Score:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="score"
                          id="score"
                          value={cat.score}
                          onChange={handlechange}
                        />
                      </div>
                      {multiPlayer === true && (
                        <>
                          <div className="mb-3 col-md-3">
                            <label className="form-label">Rake Fee:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="rake_fee"
                              id="rake_fee"
                              value={cat.rake_fee}
                              onChange={handlechange}
                            />
                          </div>
                          <div className="mb-3 col-md-3">
                            <label className="form-label">No. of Player's:</label>
                            <input
                              className="form-control"
                              type="text"
                              name="no_of_players"
                              id="no_of_players"
                              value={cat.no_of_players}
                              onChange={handlechange}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-md-12 clearfix">&nbsp;</div>
                      {flagTournament == true && (
                        <>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">
                          Tournament Join Date:
                        </label>
                        <Flatpickr
                          className="form-control"
                          data-enable-time
                          options={{ dateFormat: "Y-m-d H:i:s", enableTime: true, enableSeconds: true, time_24hr: true }}
                          value={tournstartdate}
                          onChange={([tournstartdate]) => {
                            setTournStartDate(tournstartdate);
                          }}
                        />                      
                        {/* <DatePicker
                          className="form-control"
                          name="tournament_start_date"
                          id="tournament_start_date"
                          dateFormat="yyyy-MM-dd"
                          selected={tournstartdate}
                          onChange={(tournstartdate) =>
                            setTournStartDate(tournstartdate)
                          }
                        /> */}
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Min. Entries:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="min_entery"
                          id="min_entery"
                          value={cat.min_entery}
                          onChange={handlechange}
                        />
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Max. Entries:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="max_entery"
                          id="max_entery"
                          value={cat.max_entery}
                          onChange={handlechange}
                        />
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label">Pool Prize:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="pool_prize"
                          id="pool_prize"
                          value={cat.pool_prize}
                          onChange={handlechange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Winning Criteria:</label>
                        <select
                          className="form-control"
                          name="winning_criteria"
                          id="winning_criteria"
                          onChange={handlechange}
                        >
                          <option value="">Select</option>
                          <option value="best_score">Best Score</option>
                          <option value="latest_score">Latest Score</option>
                          <option value="cumulative_score">
                            Cumulative Score
                          </option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label">Distribution Type:</label>
                        <select
                          className="form-control"
                          name="distribution_type"
                          id="distribution_type"
                          onChange={(e) => {
                            handlechange(e);
                            handleFixedPercentagePrize(e);
                          }}
                        >
                          <option value="">Select</option>
                          <option value="fixed_value">Fixed Value</option>
                          <option value="in_percent">In Percentage</option>
                        </select>
                      </div>
  
                      <div className="mb-3 col-md-12">
                        <h6>Winning Prize</h6>
                      </div>
                      {winningTiers.map((x, i) => {
                        return(
                          <>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Wallet Type</label>
                              <select
                                className="form-control"
                                name="tier_wallet_type"
                                id="tier_wallet_type"
                                onChange={e => handleWinningTiersInput(e, i)}
                              >
                                <option value="">Select Wallet Type</option>
                                {wallet.map((rwd, index) => (
                                  <option 
                                    key={index} 
                                    value={rwd.wallet_type}
                                    selected={rwd.wallet_type}
                                  >
                                    {rwd.wallet_type}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Rank</label>
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-control"
                                  type="text"
                                  name="rank_from"
                                  id="rank_from"
                                  value={x.rank_from}
                                  onChange={e => handleWinningTiersInput(e, i)}
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="rank_to"
                                  id="rank_to"
                                  value={x.rank_to}
                                  onChange={e => handleWinningTiersInput(e, i)}
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-md-2">
                              <label className="form-label">Distribution</label>
                              <input
                                className="form-control"
                                type="text"
                                name="distribution"
                                id="distribution"
                                value={x.distribution}
                                onChange={e => handleWinningTiersInput(e, i)}
                              />
                            </div>
                            {/* <div className="mb-3 col-md-2">
                                  <label className="form-label d-block">Amount</label>
                                  <input
                                    disabled
                                    className="form-control"
                                    type="text"
                                    name="total_distribution"
                                    id="total_distribution"
                                    value={x.total_distribution}
                                    onChange={e => handleWinningTiersInput(e, i)}
                                  />                                                                
                            </div>                           */}
                            <div className="mb-3 col-md-2">
                                  <label className="form-label d-block">&nbsp;</label>
                                  {winningTiers.length !== 1 && <button
                                    className="btn btn-primary"
                                    onClick={(e) => handleRemoveClick(e, i)}><FontAwesomeIcon icon={faTimes} /></button>} &nbsp;&nbsp;
                                  {winningTiers.length - 1 === i && <button className="btn btn-primary" onClick={handleAddClick}><FontAwesomeIcon icon={faPlus} /></button>}                                
                            </div>
                          </>
                        );
                      })} 
                      </>
                      )}  
                      {flagTournament === true && (
                        <> 
                      <div className="mb-3 col-md-12">
                        <h6>Complementary Prize</h6>
                      </div>   
                      <div className="mb-3 col-md-4">
                              <label className="form-label">Wallet Type</label>
                              <select
                                className="form-control"
                                name="complementary_wallet_type"
                                id="complementary_wallet_type"
                                onChange={handlechange}
                                //onChange={e => handleWinningTiersInput(e, i)}
                              >
                                <option value="">Select Wallet Type</option>
                                {wallet.map((rwd, index) => (
                                  <option 
                                    key={index} 
                                    value={rwd.wallet_type}
                                    selected={rwd.wallet_type}
                                  >
                                    {rwd.wallet_type}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Rank</label>
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-control"
                                  type="text"
                                  name="complementary_rank_from"
                                  id="complementary_rank_from"
                                  onChange={handlechange}
                                  //onChange={e => handleWinningTiersInput(e, i)}
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="complementary_rank_to"
                                  id="complementary_rank_to"
                                  onChange={handlechange}
                                  //onChange={e => handleWinningTiersInput(e, i)}
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-md-4">
                              <label className="form-label">Distribution</label>
                              <input
                                className="form-control"
                                type="text"
                                name="complementary_distribution"
                                id="complementary_distribution"
                                onChange={handlechange}
                                //onChange={e => handleWinningTiersInput(e, i)}
                              />
                            </div>
                              </>
                      )}
                    </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h6 style={{color: "red"}}>Disqualification Predefined Rules</h6>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Minimum Time Spent:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="minimum_time_spent"
                        id="minimum_time_spent"
                        onChange={handlechange}
                      />                      
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">Target Score Threshold:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="target_score_threshold"
                        id="target_score_threshold"
                        onChange={handlechange}
                      />                      
                    </div>
                    {accuracy.map((x, i) => {
                      return(
                    <>
                      <div className="mb-3 col-md-9">
                        <label className="form-label">Accuracy <span style={{fontSize: 12, fontWeight: 300}}>Consecutive Same Score Pattern</span>:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="accuracy"
                          id="accuracy"
                          onChange={e => handleChangeAccuracy(e, i)}
                        />                     
                      </div>
                      <div className="mb-3 col-md-3">
                        <label className="form-label" style={{display: "block"}}>&nbsp; &nbsp;</label>
                        {accuracy.length !== 1 && <button
                          className="btn btn-primary"
                          onClick={(e) => removeAccuracy(e, i)}><FontAwesomeIcon icon={faTimes} /></button>} &nbsp;&nbsp;
                        {accuracy.length - 1 === i && <button className="btn btn-primary" onClick={addAccuracy}><FontAwesomeIcon icon={faPlus} /></button>}                     
                      </div>                     
                    </>
                    );
                    })} 
                  </div>
                  <div className="mb-3 mt-3 col-md-12 text-center">
                        <div className="btn btn-primary btn-block" onClick={addcontest}>
                          Submit
                        </div>
                      </div>                               
                </div>
              </div>
            </div>          
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Addcontest;
