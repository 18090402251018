import React,{useState,useEffect} from 'react'
import Navbar from '../Navbar';
import API from '../../API'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function Blogcategory(props) {

    let navigate = useNavigate();
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    
    if(user === "" || user === null){
        navigate("/login")
    }
    const [result, setCategory] = useState([]);

    useEffect(() => {
        fetchData()
    },[])
    
    const fetchData = async () => {
        const res =  await API.get("/blog/blogcategorylist")
        //console.log(res.data.categories)
        setCategory(res.data.categories)
    }

    const deletecategory = async (cid,status) => {

        const qry = {"cid": cid, "status":status} 
        const res = await API.post("/blog/deleteblogcategory", qry).then((resp) => { 
            const msg = "Blog category has been " + resp.data.message + " Successfully"
            alert(msg);
            fetchData()
        }).catch((err) => { 
            console.log("Error Found") 
        });
        
    }
    return (
        <React.Fragment>
            <Navbar user={user} />
            <div className='container-fluid mt-4'>
            <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Blog List</h4>
                    <div className="flex-shrink-0">
                        <a href='/addblogcategory' className="btn btn-soft-secondary btn-sm"><FontAwesomeIcon icon={ faPlus } /> Add Category</a>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className="table-responsive">
                        <div className="table-wrapper">                            
                            <table className="table table-borderless table-centered table-custom align-middle table-nowrap mb-0">
                                <thead className='text-muted table-light'>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="15%">Category</th>
                                        <th width="15%">Category Url</th>
                                        <th width="10%" className='text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {result.map((row,index) => (
                                        <tr>
                                            <td width="5%">{index+1}</td>
                                            <td width="10%">{row.catname}</td>
                                            <td width="10%">{row.caturl}</td>
                                            <td width="10%" className='text-right'>
                                                <Link to={`/edit-blogcategory/${row.blogcatid}`} className="edit btn btn-soft-secondary btn-sm" title="Edit" data-toggle="tooltip" style={{marginRight:"5px"}}><FontAwesomeIcon icon={ faEdit } /></Link>
                                                {row.status === 'active' ? (
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you inactive this blog?')){deletecategory(row.blogcatid,'inactive')};}} title="Delete" data-toggle="tooltip">Active</a>
                                                ):(
                                                    <a className="delete btn btn-soft-danger btn-sm" onClick={() => {if(window.confirm('Are you active this blog?')){deletecategory(row.blogcatid,'active')};}} title="Delete" data-toggle="tooltip">Inactive</a>  
                                                )}
                                            </td>
                                        </tr>
                                    ))}  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </React.Fragment>
    )
}

export default Blogcategory